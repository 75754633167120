import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { reportWebVitals } from './Utils';
import { HashRouter as Router } from 'react-router-dom';
import { ToastProvider } from './contexts/ToastContext';
import { WalletContextProvider } from './contexts/WalletContext';
import { ApiContextProvider } from './contexts/ApiContext';
import { CookiesProvider } from 'react-cookie';
import { LearnContextProvider } from './contexts/LearnContext';


ReactDOM.render(
  <Router>
    <LearnContextProvider>
    <CookiesProvider>
    <ToastProvider>
      <ApiContextProvider>
      <WalletContextProvider>    
      <App />
      </WalletContextProvider>
      </ApiContextProvider>
    </ToastProvider>  
    </CookiesProvider>
    </LearnContextProvider>
  </Router>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
