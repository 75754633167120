import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, ButtonGroup,Accordion, Badge, Card, Modal, Button } from 'react-bootstrap';

import etherscan from "../assets/etherscan.png";

import { Loading } from "./UI";
import React from "react";
import { marketplaceContractAddress, web3 } from "../eth/eth";
import placeholder from "../assets/placeholder-square-dark.jpeg";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GetIPFSURL } from '../Utils';
import { useWalletContext } from '../contexts/WalletContext';
import { useApiContext } from '../contexts/ApiContext';
import Image from '../components/Image'
import Text from '../components/Text'
import { FormatAddress } from "../Utils";
import Moment from 'react-moment';
import * as Icon from 'react-bootstrap-icons';
import Buy from "../components/Buy"

import NFTDetails from "./NFTDetails";

const NFTCard = (props) => {

  const NETWORK_SYMBOL = {
    '5': "GoerliETH",
    '80001': "MATIC"
  }

  let wallet = useWalletContext();
  let api = useApiContext()
  const [show, setShow] = useState(false);
  const [expired, setExpired] = useState(false);
  const [txns, setTxns] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { nft, media, showTitle, owners } = props;
  const [listed, setListed] = useState(null)
  // const [image, setImage] = useState(placeholder)
  const [imageUrl, setImageUrl] = useState(null)
  const [id, setId] = useState(null);
  const [networkSymbol, setNetworkSymbol] = useState("ETH")

  useEffect(() => {
    if (nft.tokenId) {
      setId(web3.utils.hexToNumberString(Number(nft.tokenId)))
    } else {
      setId(web3.utils.hexToNumberString(Number(nft.id.tokenId)))
    }
  }, [nft]);

  useEffect(() => {
    async function processImage() {
      setImageUrl(media)
      // if (nft.media.length > 0) {
      //   if (nft.media[0].gateway != "") {
      //     setImageUrl(nft.media[0].gateway)
      //   } else if (nft.media[0].raw != "") {
      //     setImageUrl(nft.media[0].raw)
      //   }
      // }
    }

    processImage()

  }, [nft]);


  useEffect(() => {
    setNetworkSymbol(NETWORK_SYMBOL[localStorage.getItem("chain")])
    if (nft !== null) {
      if (nft.tokenId != undefined) {
        const _listed = (nft !== null && api.api !== null && api.api.listed_nfts !== undefined) ? api.api.listed_nfts.find(e => {
          return web3.utils.hexToNumberString(Number(e.tokenId)) === web3.utils.hexToNumberString(Number(nft.tokenId)) && web3.utils.toChecksumAddress(e.nftContract) === web3.utils.toChecksumAddress(nft.contract.address);
        }) : {};

        setListed(_listed);

        if (_listed !== undefined && _listed !== null && Date.now() > _listed.startTime * 1000 + _listed.expiryDays * 1000) {
          setExpired(true)
        } else {
          setExpired(false)
        }
      } else {
        const _listed = (nft !== null && api.api !== null && api.api.listed_nfts !== undefined) ? api.api.listed_nfts.find(e => {
          return web3.utils.hexToNumberString(Number(e.tokenId)) === web3.utils.hexToNumberString(Number(nft.id.tokenId)) && web3.utils.toChecksumAddress(e.nftContract) === web3.utils.toChecksumAddress(nft.contract.address);
        }) : {};

        if (_listed !== undefined && _listed !== null && Date.now() > _listed.startTime * 1000 + _listed.expiryDays * 1000) {
          setExpired(true)
        } else {
          setExpired(false)
        }

        setListed(_listed);
      }
    }
  }, [wallet.wallet, api.api.listed_nfts, nft]);

  return (
    <>
      {nft !== null ?
        <Modal size="xl" fullscreen="lg-down" className="bg-dark text-dark" backdrop="static" keyboard={false} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {/* {nft.title || "No Title"}
              <h6 className="m-0 p-0">#{id}</h6> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="" >
            
          <NFTDetails showTitle={true} nft={nft} media={imageUrl} owners={ owners } />

          </Modal.Body>
        </Modal>
        : ""}

      {nft !== null ?
        <Card className="mb-3 border-0 nft-card bg-dark shadow-0">
          {/* <Link className="image" to={"/contract/" + nft.contract.address + "/" + web3.utils.hexToNumberString(Number(id)) }> */}
          <Link to={"/contract/" + nft.contract.address + "/" + id}>
            <div>
              {/* {nft.metadataError ? <b>nft.metadataError</b> : ""} */}
              <Image url={imageUrl} title={nft.title || "No Title"} />
            </div>
          </Link>
          {!showTitle ? "" :
            <div>
              <Card.Title as="div">
                <Row>
                  <Col className="d-inline-block text-truncate">
                    <a className="float-end ms-1 pointer" onClick={() => handleShow()}><Icon.ArrowsAngleExpand /></a>
                    <div className="mb-1">
                      {listed ?
                        <>

{/* <pre>{ JSON.stringify(listed, null, 4)}</pre> */}
                          <div className="price " style={{ lineHeight: "18px"}}>
                            <span className="">
                            <span>
                            {listed.listingType == "0" ? <span className="me-1"><Icon.Cart /></span> : ""}
                            {listed.listingType == "1" ? <span className="me-1"><Icon.Clock /></span> : ""}
                            {listed.listingType == "2" ? <span className="me-1"><Icon.Lock /></span> : ""}

                              {listed.price !== undefined ? web3.utils.fromWei(String(listed.lastOfferPrice), "ether") : ""} {networkSymbol}
                            </span>
                            </span>
                          </div>
                          {/* {listed.listingType == "0" ? <span className=""><Icon.Cart /> Fixed Price Listing</span> : ""}
                          {listed.listingType == "1" ? <span className=""><Icon.Clock /> Timed Auction {expired ? <span>(Expired)</span> : ""}</span> : ""}
                          {listed.listingType == "2" ? <span className=""><Icon.Lock /> Reserved Listing</span> : ""} */}
                        </>
                        : ""
                        // <span className=""><Icon.File /> Not Listed</span>
                        }
                    </div>
                    {nft.title || "No Title"}
                    <h6 className="m-0 p-0">#{id}</h6>
                  </Col>


                </Row>
              </Card.Title>

            </div>
          }
        </Card>
        : ""}
    </>

  )

}

export default NFTCard;
