import { Container, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigtion'
import Home from './Home'
import BrowseContract from './BrowseContract'
import BrowseNFT from './BrowseNFT'
import Mint from './Mint'
import BrowseAddress from './BrowseAddress'
import CreateCollection from './CreateCollection'
import * as Icon from 'react-bootstrap-icons';
import { useCookies } from 'react-cookie';

import ScrollToTop from "./Utils";

import { useWalletContext } from './contexts/WalletContext';
import './assets/styles/App.css';
import Learn from './Learn';

import { sections } from './Learn'
import Theme from "./components/Theme"
import Privacy from './Privacy';
import background from "./assets/randomshape.png";
import Image from './components/Image'


function App(props) {



  let wallet = useWalletContext();

  const [theme, setTheme] = useState();
  const [cookies, setCookie] = useCookies(['theme']);

  function changeTheme(theme) {
    setCookie("theme", theme, { path: "/" });
    setTheme(theme)
  }

  useEffect(() => {
    document.body.className = theme + " " + (wallet.wallet.network || "no").toLowerCase();
    // document.body.className = "polygon";
  }, [theme]);


  // useEffect(() => {
  //   if (wallet.wallet !== null ** wallet.wallet.address === undefined) {
  //   }
  // }, [wallet.wallet]);



  return (
    <>
      <ScrollToTop />

        <div className={`App min-vh-100 ${theme}`} >
          <Image url={background} background="bg" title="featured" />
          <div >
            <Navigation />
            <div className="content">
              <Routes>
                <Route path='/' element={<Home />} />
                {wallet.wallet.address !== undefined ?
                  <>
                    <Route path='/collection' element={<CreateCollection />} />
                    <Route path='/mint/:contract_address' element={<Mint />} />
                    <Route path='/mint' element={<Mint />} />
                  </> : ""}
                <Route path='/my' element={<BrowseAddress />} />

                <Route path='/privacy' element={<Privacy />} />
                <Route path='/learn/:module' element={<Learn />} />
                <Route path='/address/:address' element={<BrowseAddress />} />
                <Route path='/contract/:contract_address' element={<BrowseContract />} />
                <Route path='/contract/:contract_address/:token_id' element={<BrowseNFT />} />
                {/* <Route path='/login' element={<Login />} /> */}

              </Routes>
            </div>


          </div>

          <div className="footer d-none d-md-inline">
            <Container>
              <Row className="p-5 ms-3 me-3 mt-2">
                <Col xs={12} md={4} className="mt-3">
                  {/* style={{ position: "fixed", zIndex: "999", right: "30px", bottom: "30px" }} */}
                  <div className="mb-3">
                    <Theme theme={cookies.theme || "light"} onThemeToggle={changeTheme} />
                  </div>

                  <h6>Spread the Love!<br />Follow NFT Jugaad on Twitter</h6>
                  <a href="https://twitter.com/nftjugaad" rel="noreferrer" target="_blank"><Icon.Twitter style={{ color: "#1C9BEF" }} size="56" className="me-2" /></a>
                  {/* <Icon.Facebook size="24" className="me-2" />
                <Icon.Instagram size="24" className="me-2" /> */}
                </Col>
                <Col xs={12} md={4} className="mt-3"><h6 className="bold">Learn More</h6>
                  <ul>
                    {
                      sections.map((section, idx) => {
                        return (<li key={idx}><a href={`#/learn/${section.pages[0].page}`}>{section.title}</a></li>)
                      })
                    }
                  </ul>
                </Col>
                <Col xs={12} md={4} className="mt-3"><h6 className="bold">Disclaimer</h6><p>Please note that we provide these marketplace and other resources to you only as a learning platform and convenience. Inclusion of any link does not imply endorsement, approval or recommendation. As always be careful and never share your Wallet private keys with anyone. Make sure you are connected to testnet before approving any transactions.</p>

                  <br />
                  <a href="/#/privacy" className="bold">Read our Privacy Policy</a>
                </Col>

              </Row>
            </Container>
          </div>
        </div>

    </>
  );
}

export default App;
