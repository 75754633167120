import { Container, Row, Col, Button } from 'react-bootstrap';

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import Explore from './Explore'
import { Loading } from './components/UI'

import { getData } from "./utils/api"
import Image from './components/Image'

import NFTDetails from './components/NFTDetails';

const BrowseNFT = (props) => {

  
  // useEffect(() => {
  //   setRadioValue(radioValue)
  //   // props.onThemeToggle(radioValue)
  // }, [radioValue]);



  const { contract_address, token_id } = useParams();
  const [selectedNft, setSelectedNft] = useState(null);
  const [owners, setOwners] = useState([]);
  // const [txns, setTxns] = useState([]);

  const [image, setImage] = useState(null)

  const [favorites, setFavorites] = useState(0);

  // async function addFavorites() {
  //   let favorites_response = await postData(`collections/${contract_address}/${token_id}`, {
  //     wallet_address: wallet.wallet.address,
  //     favorite_id: contract_address + ":" + token_id,
  //   })

  //   setFavorites(favorites + 1)
  // }

  useEffect(() => {
    async function processSelection() {
      
      let api_response = await getData(`collections/${contract_address}/${token_id}`)
      setFavorites(api_response.data.favorites.length || 0)
      setSelectedNft(api_response.data.metadata)
      setOwners(api_response.data.owners.owners)
      setImage(api_response.data.media)


      // let nft_details = await getNFTDetails(contract_address, token_id)
      // setSelectedNft(nft_details)

      // document.title = nft_details.title + " | " + "NFT Jugaad";
      // if (nft_details.media.length > 0) {
      //   var ipfs_url = ""
      //   if(nft_details.media.length > 0) {
      //     if (nft_details.media[0].gateway !== "") {
      //       ipfs_url = nft_details.media[0].gateway
      //       // const imageData = await getImage(nft_details.media[0].gateway)
      //       // setImage(imageData)
      //     } else if (nft_details.media[0].raw !== "") {
      //       ipfs_url = nft_details.media[0].raw
      //       // const imageData = await getImage(nft_details.media[0].raw)
      //       // setImage(imageData)
      //     }
      //     const imageData = await getImage(ipfs_url)
      //     setImage(imageData)
      //   }

      //   let favorites_response = await getData(`collections/${contract_address}/${token_id}`)
      //   // console.log(favorites_response.data)
      //   setFavorites(favorites_response.data.data.length || 0)
      // }

      // // const owners = await getData(`provider/contract/${contract_address}/nfts/${token_id}/owners`)
      // // setOwners(owners.data.owners)
      // const owners = await getNFTOwners(contract_address, token_id)
      // setOwners(owners)


      // const _txns = await getNFTLogs(contract_address, token_id)
      // // const transfers = await getData(`provider/contract/${contract_address}/nfts/${token_id}/transfers`)
      // // const _txns = transfers.data.result.transfers.filter(txn => web3.utils.hexToNumber(txn['erc721TokenId'])  === Number(token_id)) || []
      // // console.log(_txns)
      // setTxns(_txns)
    }

    processSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract_address, token_id]);




  return <>

    {selectedNft !== null ? (
      <>

        

        {image ? <Image url={image} background="bg" title="bg" /> : ""}


        <Container className="main">
          <Row>
            <Col>
            <Container>
            <NFTDetails showTitle={true} nft={selectedNft} media={image} owners={ owners } />
            </Container>
            </Col>
          </Row>
          
        </Container>

        <div className="pt-3 pb-3 mt-3 bg-white-soft">
          <Container>
            <Row>
              <Col>
                <div className="p-5 border-0">
                  <h5 className="mb-3">Other NFTs from this collection</h5>
                  <Explore address={contract_address} skip={token_id} size="sm" limit="6" />
                  <div className="text-center mt-3">
                    <Button variant="dark" href={`#/contract/${contract_address}`}>View Entire Collection</Button>
                  </div>
                </div>

              </Col>
            </Row>
          </Container>
        </div>

      </>

    ) :
      <Container>
        <Row>
          <Col>
            <Loading title="" />
          </Col>
        </Row>
      </Container>
    }

  </>
}

export default BrowseNFT;
