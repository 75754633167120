import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormatAddress } from "./Utils"
import { getData } from "./utils/api"
import { web3, explorerURL } from "./eth/eth";
import Explore from './Explore'
import { useWalletContext } from './contexts/WalletContext';
import * as Icon from 'react-bootstrap-icons';
import etherscan from "./assets/etherscan.png";
import { Loading } from './components/UI'
import Image from './components/Image'
import Text from './components/Text'


require('dotenv').config();


const BrowseContract = (props) => {
  const { contract_address } = useParams();
  const [contractMetadata, setContractMetadata] = useState(null)
  const [contractImage, setContractImage] = useState(null)
  const [contractOwner, setContractOnwer] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [refreshMetadata, setRefershMetadata] = useState(false);

  let wallet = useWalletContext();

  // Get Contract Metadata
  useEffect(() => {
    const _get = async () => {
      // const data = await getData(`provider/contract/${contract_address}`)
      let api_response = await getData(`collections/${contract_address}?metadata=1`)
      setContractMetadata(api_response.data.metadata);
      setContractImage(api_response.data.media)
      document.title = (api_response.data.metadata.contractMetadata.name || "No Name Defined")  + ' | NFT Jugaad';

      // const data = await getContractMetadata(contract_address)
      // if (data == null) {
      //   console.log("NO CONTRACT")
      //   const data = await getData(`provider/contract/${contract_address}`)
      //   setContractMetadata(data.data);

        
      //   return
      // }
      // setContractMetadata(data);
    };

    _get()

  }, [contract_address, refreshMetadata]);

  function setFeaturedImage(img) {
    console.log(img);
  }
  
  // Check Contract Owner
  useEffect(() => {
    if (wallet.wallet.address !== null && contractMetadata != null) {
      if (web3.utils.toChecksumAddress(contractMetadata.contract_owner) === web3.utils.toChecksumAddress(wallet.wallet.address)) {
        setContractOnwer(true)
      }
    }
  }, [wallet.wallet, contractMetadata]);

  return (
    <div>
      <>
      {contractMetadata ? 
        <div className="">
          {/* <pre>{JSON.stringify(contractMetadata, null, 2)}</pre> */}
            <>
              {/* {contractMetadata.contractMetadata_extended !== null && contractMetadata.contractMetadata_extended.image != null ?
                <>
                  {contractMetadata.contractMetadata_extended.image != null ? 
                   <Image url={contractMetadata.contractMetadata_extended.image} background="bg" title="featured" /> : "" }
                  
                  {contractMetadata.contractMetadata_extended.image != null ? 
                  <Image height="150px" url={contractMetadata.contractMetadata_extended.image} background="banner" title="featured" /> : "" }
                </>
                :
                <>
                 <Image height="150px" background="banner" title="featured" />
                </>
              } */}

                {contractImage !== null ?
                <>
                    <Image url={contractImage} background="bg" title="featured" />
                    <Image height="150px" url={contractImage} background="banner" title="featured" />
                </>
                :
                <>
                 <Image height="150px" background="banner" title="featured" />
                </>
              }              

              <Container className="main">
                <Row>
                  <Col xl={4} lg={4} md={12} sm={12} className="mb-3">
                    <div className="mb-3 bg-black rounded-2 p-4" style={{ marginTop: "-100px" }}>

                      {contractMetadata.contractMetadata.name ? <h3 className="m-0 mt-3 mb-2">{contractMetadata.contractMetadata.name || "No Name Defined"}</h3> : 
                        contractMetadata.contractMetadata_extended != null && contractMetadata.contractMetadata_extended.name ? <h3 className="m-0 mt-3 mb-2">{contractMetadata.contractMetadata_extended.name || "No Name Defined"}</h3> : ""
                      }

                      
                      <div className="mb-2" ><FormatAddress address={contractMetadata.address} allowCopy={true} /></div>
                      
                      {/* { contractMetadata.description !== null ?  <p>{contractMetadata.description.description}</p> : "" } */}
                      {contractMetadata.contractMetadata_extended !== null ? 
                      <div>
                        {contractMetadata.contractMetadata_extended.description < 250 ? contractMetadata.contractMetadata_extended.description :
                      <div className="">
                        <Text text={contractMetadata.contractMetadata_extended.description} />
                      </div>
                      }
                      </div> 
                      : ""}

                      
                      

                      <div className="mt-3 mb-3 text-center">
                        <span className="pe-2 border-end">
                          {contractMetadata.contractMetadata.symbol ? contractMetadata.contractMetadata.symbol : contractMetadata.contractMetadata_extended !== null ? contractMetadata.contractMetadata_extended.symbol : ""}</span>
                        {contractMetadata.contractMetadata.tokenType ? (<span className="ps-2 pe-2 "> {contractMetadata.contractMetadata.tokenType}</span>) : ""}
                      </div>

                      <div className="text-center"><Button size="sm" variant="dark" className="" onClick={() => setRefershMetadata(true)}><Icon.ArrowClockwise /> Refresh Metadata</Button></div>
                    </div>

                    

                    {/* {contractOwner ? <Button variant="primary w-100" href={'#/mint/' + contract_address} >Mint on this Collection</Button> : ""} */}

                    <div className="text-center mt-3">
                    <ButtonGroup aria-label="Basic example">
                    <Button variant="dark"><a variant="dark" className="w-100" href={`${explorerURL}/address/${contract_address}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end me-2" src={etherscan} /></a></Button>
                    { contractMetadata.contractMetadata_extended !== null ?  <Button variant="dark"><a href={contractMetadata.contractMetadata_extended.external_link} rel="noreferrer"  target="_blank"><Icon.Link45deg size="18" className="float-end ms-2" /></a></Button> : "" }
                    <Button variant="dark"><Icon.Share /></Button>
                  </ButtonGroup>
                  </div>


                    {/* <pre>
                        { JSON.stringify(contractMetadata, null, 2)}
                      </pre> */}

                    
                  </Col>
                  <Col>
                      <Explore address={contract_address} setImage={setFeaturedImage} />
                  </Col>
                </Row>
              </Container>
            </>
            
        </div>
        : 
        <>
          <Container>
            <Row className="mt-3 p-5 bg-dark-soft rounded-3">
            <Loading title="Loading Data ..." />
            </Row>
            </Container>
          
        </>
       }
      </>
    </div>
  );
};

export default BrowseContract;
