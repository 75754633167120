
import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import * as Icon from 'react-bootstrap-icons';

const Text = (props) => {
    const { text } = props;
    const [showMore, setShowMore] = useState(false);

    return (
        <>
        {text !== undefined ?
        <>
        {text.length < 250 ? 
        text :
        <div>
            {showMore ? 
            <ReactMarkdown children={text} rehypePlugins={[rehypeRaw]} /> : 
            <div>
                <ReactMarkdown children={text.substring(0, 250)} rehypePlugins={[rehypeRaw]} />
                
            </div>
            }
            <a className="pointer text-underline" onClick={() => setShowMore(!showMore)}>{showMore ? <span><Icon.ChevronUp />&nbsp;Less</span> : <span><Icon.ChevronDown />&nbsp;More</span>}</a>
        </div>}
        </>: ""}
        </>
    )
}

export default Text;