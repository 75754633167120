import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Container, Row, Col, Button, ButtonGroup, Carousel, Card } from 'react-bootstrap';
import etherscan from "./assets/etherscan.png";
import { marketplaceContractAddress, explorerURL, web3 } from "./eth/eth";
import * as Icon from 'react-bootstrap-icons';

import { FormatAddress } from './Utils';
import FeaturedCollections from './components/FeaturedCollections';
import Explore from './Explore'
import { useWalletContext } from './contexts/WalletContext';
import { getData } from "./utils/api"
import Image from './components/Image'
import { LearnModeBtn } from "./components/UI";
// import background from "./assets/randomshape.png";


const BrowseAddress = (props) => {
    let wallet = useWalletContext();

    const { address } = useParams();
    const [isMarketplace, setIsMarketplace] = useState(false)
    const [isUserWallet, setIsUserWallet] = useState(false)
    const [collections, setCollections] = useState(null);
    // const [value, setValue] = useState(["id"]);
    const value = "id";


    useEffect(() => {
        if (address !== null) {
            if (web3.utils.toChecksumAddress(address) === web3.utils.toChecksumAddress(marketplaceContractAddress)) {
                setIsMarketplace(true)
                setIsUserWallet(false)
                document.title = 'Marketplace | NFT Jugaad';
            }

            if (wallet.wallet.address !== undefined) {
                if (web3.utils.toChecksumAddress(address) === web3.utils.toChecksumAddress(wallet.wallet.address)) {
                    setIsUserWallet(true)
                    setIsMarketplace(false)
                    document.title = 'My Collection | NFT Jugaad';
                } else {
                    document.title = 'Collections by Address | NFT Jugaad';
                }
            } else {
                document.title = 'Collections by Address | NFT Jugaad';
            }

        }
    }, [address, wallet.wallet]);


    useEffect(() => {
        const _get = async () => {
            const data = await getData(`collections/${wallet.wallet.address}?contracts=1`)
            setCollections(data.data.contracts);
        };
        if (isUserWallet === true) {
            _get()
        }
    }, [isUserWallet, wallet.wallet]);



    return (

        <>
            <Container>
                <Row>
                    <Col>
                        <div className="m-3 mb-4">
                            <span className="float-end">
                                <a variant="dark" className="btn btn-outline-secondary" href={`${explorerURL}/address/${address}`} rel="noreferrer" target="_blank"><img height="18px" className="invert " src={etherscan} alt="Etherscan" /><span className="ms-2 d-none d-md-inline">Explorer</span></a>
                            </span>

                            <h1>
                                {isMarketplace ? <div>Explore <span className='highlight'>Marketplace</span></div> : ""}
                                {isUserWallet ? <div>My <span className='highlight'>Wallet</span></div> : ""}
                                {!isMarketplace && !isUserWallet ? <FormatAddress address={address} /> : ""}
                            </h1>



                        </div>

                    </Col>
                </Row>
            </Container>

            {!isUserWallet ?
                <div className="pt-3 pb-3 mb-3" style={{ zIndex: "-1", display: "block", background: "rgba(255,255,255,0.1)", backgroundPosition: "center center", backgroundSize: "contain" }}>
                    <Container><Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FeaturedCollections title="Featured Collections" address={address} />
                        </Col></Row>
                    </Container>
                </div> : ""}

            <Container className="main">
                <Row>

                    <Col xl={4} lg={4} md={5} sm={12} xs={12} className="mb-3 ">

                        {isMarketplace ?
                            <div className="bg-black d-none d-md-block p-4 rounded-3 position-relative">
                                <LearnModeBtn module="nft_marketplace" />
                                <h4>Welcome to Marketplace</h4>
                                <p>Jugaad is an Indo-Aryan word and it means <b>a flexible approach to problem-solving that uses limited resources in an innovative way!</b>.</p>
                                <p>NFT Jugaad was build as a learning platform. It only works on Goerli testnet currently and so you don't have a worry about Gas fees. You can create a wallet and load it with money from Faucet to start playing with collections, nfts, ownership etc. All along the pages you will find helpful tips which will guide you on the world of crypto and NFTs. Learning is the best way to move forward and no better way than hands-on. In the learn section of this website you will see different articles on how this all comes together and different terminologies associated. </p>
                            </div>
                            : ""
                        }

                        {isUserWallet ?
                            <div className="">
                                <div className="bg-black p-4 rounded-3 ">
                                    <Button size="sm" href="/#/collection" variant="primary" className="ms-2 float-end"><Icon.Plus /> New</Button>
                                    <h4 className="mb-4">My Collections</h4>

                                    {/* <p>An NFT collection is an assortment of digital assets released by you and contains a limited number of individual NFTs.</p> */}
                                    {collections !== null && collections !== undefined && collections.length > 0 ?
                                        <div>
                                            {/* <p>Click on a collection, to mint NFT</p> */}
                                            <Carousel variant="light" className="no-margin-padding">
                                                {collections.map((contract, idx1) => {
                                                    return (
                                                        <Carousel.Item className="mb-5" key={idx1}>
                                                            <div className="nft-card">
                                                                <a href={`/#/contract/${contract.metadata.address}`} className="bg-transparent border-0 shadow-0">
                                                                    <Image title={contract.metadata.name || "No Title"} url={contract.media || null} />
                                                                </a>
                                                                <Card.Title as="div" className="mt-3 mb-3">
                                                                    <div className="float-end ms-2">
                                                                        <ButtonGroup aria-label="Basic example">
                                                                            <Button href={`/#/contract/${contract.metadata.address}`} variant="dark">Browse</Button>
                                                                            <Button href={`/#/mint/${contract.metadata.address}`} variant="dark">Mint</Button>
                                                                        </ButtonGroup>
                                                                    </div>
                                                                    <h6 className="text-truncate m-0">{contract.metadata.name}</h6>
                                                                    {contract.metadata.symbol}

                                                                </Card.Title>
                                                            </div>
                                                        </Carousel.Item>
                                                    )
                                                })}
                                            </Carousel></div> : ""}

                                    {/* <h6>Create Collection</h6> */}


                                </div>



                                {/* <div className="bg-white-soft p-4 rounded-3 mt-3">
                                    <h6>Mint NFT</h6>
                                    <p>Minting an NFT means converting digital data into crypto collections or digital assets recorded on the blockchain.</p>
                                    <Button href="/#/mint" className="w-100 mt-2">Mint NFT</Button>
                                </div> */}


                            </div>
                            : ""
                        }

                        {!isMarketplace && !isUserWallet ?
                            <div className="bg-black p-4 rounded-3 mt-3">
                                <FormatAddress allowCopy={true} address={address} />
                            </div>
                            : ""}


                        {/* <h4 className="mt-3">Sort Collection</h4>

                        <ToggleButtonGroup size="sm" type="radio" name="options" value={value} onChange={sortCollection}>
                            <ToggleButton className="shadow-none" variant="dark" id="tbg-radio-1" value="id">
                                <Icon.Icon123 size="18" />
                            </ToggleButton>
                            <ToggleButton className="shadow-none" variant="dark" id="tbg-radio-2" value="date">
                                <Icon.Calendar size="18" />
                            </ToggleButton>
                            <ToggleButton className="shadow-none" variant="dark" id="tbg-radio-3" value="title">
                                <Icon.SortAlphaDown size="18" />
                            </ToggleButton>
                        </ToggleButtonGroup> */}

                    </Col>
                    <Col>
                        <div className=" p-4 rounded-3">
                            {isUserWallet ? <h4>My NFTs</h4> : <h4>NFTs</h4>}
                            <Explore address={web3.utils.toChecksumAddress(address)} sortBy={value} />
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    );
};


export default BrowseAddress;
