import { useNavigate } from "react-router-dom";
import { Container, Row, Col, ListGroup, ListGroupItem, Card } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import * as Icon from 'react-bootstrap-icons';
// import background from "./assets/randomshape.png";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';


const Privacy = (props) => {
  const navigate = useNavigate();


 

  const [activeKey, setActiveKey] = useState("0");

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    );

    return (
      <span disabled={ activeKey !== eventKey ? true : false }
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  }

  
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="m-3 mb-4">
              <b className="ps-1">Fine Print<Icon.ChevronRight /></b>
              <h1 className="m-0 p-0">
                Privacy Policy
              </h1>
              <p>Copyrights, community guidelines, wallet security, and more</p>

            </div>

<div className="mb-5 bg-white p-4 rounded">
<Accordion activeKey={activeKey}>
<Container>

<Row className="relative">
  <Col xl={3} className="left">
    <div className=" pt-5 text-end">
      <h6>Selected Collection Name</h6>
<img src="https://gateway.pinata.cloud/ipfs/bafybeiapyufq5wbgxwtrjz7h2hcqv76nbiv3hxb7rtsq35kz674baj3oii/saints.png" height="150px" className="img img-thumbnail" />
<p>Since the value attribute is set on our form element, the displayed value will always be this.state.value, making the React state the source of truth. Since handleChange runs on every keystroke to update the React state, the displayed value will update as the user types.</p>

    </div>
  </Col>
  <Col className="middle"><Icon.Images /></Col>
  <Col className="right">
    <div className="p-4">
    <CustomToggle eventKey="0"><h4>Heading</h4></CustomToggle>
    <Accordion.Collapse eventKey="0">
    <p>Since the value attribute is set on our form element, the displayed value will always be this.state.value, making the React state the source of truth. Since handleChange runs on every keystroke to update the React state, the displayed value will update as the user types.</p>
    </Accordion.Collapse>
    <a onClick={() => { setActiveKey("1"); }}>Provide Metadata</a>
    </div>
  </Col>
</Row>

<Row>
  <Col xl={3} className="left">
    <div className="p-4 text-center">
    <Icon.Youtube  />
    </div>
  </Col>
  <Col className="middle"><Icon.Images /></Col>
  <Col className="right">
    <div className="p-4">
    <CustomToggle eventKey="1"><h4>Heading</h4></CustomToggle>
    <Accordion.Collapse eventKey="1">
    <p>Since the value attribute is set on our form element, the displayed value will always be this.state.value, making the React state the source of truth. Since handleChange runs on every keystroke to update the React state, the displayed value will update as the user types.</p>
    </Accordion.Collapse>
    </div>
  </Col>
</Row>

</Container>
</Accordion>
</div>


            {/* <div className="process">
              <Accordion flush activeKey={activeKey}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header >
                    <CustomToggle eventKey="0">
                    <h6>
                      Upload Media
                      <div className="display-block text-small">Lorem ipsum dolor sit amet</div>
                    </h6>
                    </CustomToggle>
                    <span className="aicon"><Icon.Images /></span>

                  </Accordion.Header>
                  <Accordion.Body>
                    <div class="body-left">Body</div>
                    <div class="body-right">Body</div>
                    <a onClick={() => { setActiveKey("1"); }}>Provide Metadata</a>
                  </Accordion.Body>
                </Accordion.Item>


                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <span className="aicon"><Icon.CardHeading /></span>
                    <h6>
                      Provide Metadata
                      <div className="display-block text-small">Lorem ipsum dolor sit amet</div>
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    Body
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>

            </div> */}


          </Col>
        </Row>
      </Container>
      <Container className="main">
        <Row>


          <Col>

            <div className="bg-white-soft p-5 rounded">

              <h4 className="mt-3">Spam Follow Policy</h4>


              <h4 className="mt-3">Adult Content Policy</h4>
              You may not mint NFTs that show excessively graphic sexual content nor pornography. This rule also applies to your profile image, your cover image, collection image and collection cover image. As all NFTs are minted onto the blockchain and the media is hosted on an IPFS server, the NFT will–depending on the circumstance–either remain in the owner’s wallet or in escrow in our market contract. While the owner and creator of the NFT will be able to view the NFT on Foundation, the larger public will be unable to view it.


              <h4 className="mt-3">Counterfeit / Remixes Policy</h4>
              Overview You may not mint NFTs that are exact one-to-one copies of existing artwork (“copyminting”). This rule also a...

              <h4 className="mt-3">Hateful and Abusive Content Policy</h4>
              Overview You may not mint NFTs that aim to harass, bully, or intimidate others. This rule also applies to your profil...

              <h4 className="mt-3">Misleading and Deceptive Identities Policy</h4>
              Overview You may not impersonate individuals, groups, artists, collectors, or organizations to mislead, confuse or de...

              <h4 className="mt-3">Violent and Graphic Content Policy</h4>
              Overview You may not mint NFTs that show excessively Violent and Graphic content. This rule also applies to your prof...

            </div>
          </Col>


        </Row>

      </Container>


    </>
  );
};

export default Privacy;