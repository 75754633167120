import React, { createContext, useReducer, useContext } from 'react';

const initialState = null;

export const ApiContext = createContext();

export const LISTED_ITEMS = 'LISTED_ITEMS';

export const apiReducer = (state, action) => {
    switch (action.type) {
      case LISTED_ITEMS:
        return {
          ...state,
          listed_nfts: action.payload
        };
      default:
        return state;
    }
  };

  
export const ApiContextProvider = props => {
    const [api, apiDispatch] = useReducer(apiReducer, initialState);
    const apiData = { api, apiDispatch };

    return (
      <ApiContext.Provider value={apiData}>
        {props.children}
      </ApiContext.Provider>
    );
  };


export const useApiContext = () => {
    return useContext(ApiContext);
};
  