import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
// import background from "./assets/randomshape.png";
import { marketplaceContractAddress } from "./eth/eth";
import { useApiContext } from './contexts/ApiContext';
import { useWalletContext } from './contexts/WalletContext';
import NFTCard from './components/NFTCard'
import FeaturedCollections from './components/FeaturedCollections';
import Image from './components/Image'
import { getData } from "./utils/api"

const Home = (props) => {

  let wallet = useWalletContext();
  let api = useApiContext();


  const [featuredNFT, setFeaturedNFT] = useState(null)
  const [featuredImage, setFeaturedImage] = useState(null)

  async function refreshFeaturedNFT() {
    
    const _f = api.api.listed_nfts[Math.floor(Math.random() * (api.api.listed_nfts.length))]
    if (_f !== undefined) {
      let api_response = await getData(`collections/${_f.nftContract}/${_f.tokenId}`)
      setFeaturedImage(api_response.data.media)
      setFeaturedNFT(api_response.data.metadata)
    }
  }

  useEffect(() => {
    document.title = "NFT Jugaad";
    console.log("Listed NFTs", api.api)
    if (wallet.wallet !== null && api.api !== null && api.api.listed_nfts != undefined && api.api.listed_nfts.length > 0) {
      refreshFeaturedNFT()
    }

  }, [wallet.wallet, api.api]);

  return (
    <div>
      <>

        {featuredImage ? <Image url={featuredImage} background="bg" title="featured" /> : ""}
        <div className="p-5">
          <Container>
            <Row className="">
          <Col xl={6} lg={6} md={12} sm={12} className="align-items-center d-flex offset-lg-1 offset-xs-0 offset-sm-0">

                
                <div className="mb-3 ms-5 ">
                  <h1>Learn, Create and Curate<br /><span className="highlight">Extraordinary NFTs</span> Collections</h1>
                  <p className="d-none d-md-block">
                    Jugaad is an Indo-Aryan word and it means <b>a flexible approach to problem-solving that uses limited resources in an innovative way!</b>. NFT Jugaad was build as a learning platform. It only works on ethereum testnets and so you don't have a worry about Gas fees. Click on the little birdie in the bottom anytime to learn and search any questions.</p>

                  <div className="mt-2">
                    <Button variant="dark" href={`/#/address/${marketplaceContractAddress}`} className="mt-2 me-3" >
                      <Icon.Shop /> <span className="ms-1 d-none d-md-inline">Explore</span></Button>
                    {wallet.wallet === null || wallet.wallet.address === undefined ? <Button className="mt-2" variant="primary" href="#/learn/welcome">Learn More!</Button> : ""}
                    {wallet.wallet.address !== undefined ? <Button className="mt-2" variant="primary" href="#/collection">
                      <Icon.PlusCircle />  <span className="ms-1">Create</span>
                    </Button> : ""}
                  </div>

                </div>
              </Col>
              {featuredNFT !== null ?
                <Col xl={4} className="">
                  <center className="p-4">
                    <div className="text-start inline-block" style={{ transform: "rotate(-5deg)" }}>
                      {featuredNFT ? <NFTCard showTitle="true" nft={featuredNFT} media={featuredImage} /> : ""}
                    </div>
                  </center>
                </Col>
                : ""}
                <Col xl={2}></Col>
            </Row>
          </Container>
        </div>
        <div className="pt-3 pb-3 bg-white-soft">
          <Container><Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <FeaturedCollections title="Featured Collections" address={marketplaceContractAddress} />
            </Col></Row>
          </Container>
        </div>


      </>
    </div>
  );
};

export default Home;
