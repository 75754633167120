import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, ButtonGroup, Accordion, Badge, Card, Modal, Button } from 'react-bootstrap';

import etherscan from "../assets/etherscan.png";

import { getData, postData, getImage } from "../utils/api"

import { Loading } from "./UI";
import React from "react";
import { marketplaceContractAddress, web3, explorerURL, getNFTLogs } from "../eth/eth";
import placeholder from "../assets/placeholder-square-dark.jpeg";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GetIPFSURL } from '../Utils';
import { useWalletContext } from '../contexts/WalletContext';
import { useApiContext } from '../contexts/ApiContext';
import Image from '../components/Image'
import Text from '../components/Text'
import { FormatAddress } from "../Utils";
import Moment from 'react-moment';
import * as Icon from 'react-bootstrap-icons';
import Buy from "../components/Buy"
import History from './History';

const NFTDetails = (props) => {

  let wallet = useWalletContext();
  let api = useApiContext()
  const [show, setShow] = useState(false);
  const [expired, setExpired] = useState(false);
  const [txns, setTxns] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { nft, media, showTitle, owners } = props;
  const [listed, setListed] = useState(null)
  // const [image, setImage] = useState(placeholder)
  const [imageUrl, setImageUrl] = useState(null)
  const [id, setId] = useState(null);

  const [favorites, setFavorites] = useState(0);

  const axios = require('axios');


  async function addFavorites() {
    let favorites_response = await postData(`collections/${nft.contract.address}/${id}}`, {
      wallet_address: wallet.wallet.address,
      favorite_id: nft.contract.address + ":" + id,
    })
    setFavorites(favorites + 1)
  }



  useEffect(() => {



    if (nft.tokenId) {
      setId(web3.utils.hexToNumberString(Number(nft.tokenId)))
    } else {
      setId(web3.utils.hexToNumberString(Number(nft.id.tokenId)))
    }

    async function processSelection() {
      const _txns = await getNFTLogs(nft.contract.address, web3.utils.hexToNumberString(Number(nft.id.tokenId)))
      // const transfers = await getData(`provider/contract/${contract_address}/nfts/${token_id}/transfers`)
      // const _txns = transfers.data.result.transfers.filter(txn => web3.utils.hexToNumber(txn['erc721TokenId'])  === Number(token_id)) || []
      setTxns(_txns)
    }
    processSelection()

  }, [nft]);

  useEffect(() => {

    async function getDataFromIPFS(url) {
      await axios(url).then(function (response) {
        console.log(response)
          nft.description = response.data.description
          nft.metadata.attributes = response.data.attributes
          setImageUrl("https://ipfs.io/ipfs/" + response.data.image)
      }).catch(function (error) {
          console.log(error)
      })
    }

    async function processImage() {
      
      console.log(nft.error)
      if(nft.error !== undefined && nft.error == "IPFS gateway timed out") {
        // getDataFromIPFS(nft.tokenUri.gateway)
      } else {
        setImageUrl(media)
      }
      // if (nft.media.length > 0) {
      //   if (nft.media[0].gateway != "") {
      //     setImageUrl(nft.media[0].gateway)
      //   } else if (nft.media[0].raw != "") {
      //     setImageUrl(nft.media[0].raw)
      //   }
      // }
    }

    processImage()

  }, [nft]);


  useEffect(() => {
    if (nft !== null) {
      if (nft.tokenId != undefined) {
        const _listed = (nft !== null && api.api !== null && api.api.listed_nfts !== undefined) ? api.api.listed_nfts.find(e => {
          return web3.utils.hexToNumberString(Number(e.tokenId)) === web3.utils.hexToNumberString(Number(nft.tokenId)) && web3.utils.toChecksumAddress(e.nftContract) === web3.utils.toChecksumAddress(nft.contract.address);
        }) : {};

        setListed(_listed);

        if (_listed !== undefined && _listed !== null && Date.now() > _listed.startTime * 1000 + _listed.expiryDays * 1000) {
          setExpired(true)
        } else {
          setExpired(false)
        }
      } else {
        const _listed = (nft !== null && api.api !== null && api.api.listed_nfts !== undefined) ? api.api.listed_nfts.find(e => {
          return web3.utils.hexToNumberString(Number(e.tokenId)) === web3.utils.hexToNumberString(Number(nft.id.tokenId)) && web3.utils.toChecksumAddress(e.nftContract) === web3.utils.toChecksumAddress(nft.contract.address);
        }) : {};

        if (_listed !== undefined && _listed !== null && Date.now() > _listed.startTime * 1000 + _listed.expiryDays * 1000) {
          setExpired(true)
        } else {
          setExpired(false)
        }

        setListed(_listed);
      }
    }
  }, [wallet.wallet, api.api.listed_nfts, nft]);

  return (
    <>
      {nft !== null ?
        <>
          <Row>
            <Col className="mb-2" xl={6} lg={6} md={12} sm={12}>

              <div className="d-block mt-3 d-lg-none mb-4">
                
                <span className="float-end mt-2 me-2"><a variant="dark" className="w-100" href={`${explorerURL}/token/${nft.contract.address}?a=${web3.utils.hexToNumberString(Number(nft.id.tokenId))}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end" src={etherscan} /><span className="ms-2 d-none">Browse on Explorer</span></a></span>

                <h1 className="m-0 highlight"><span className="">#{web3.utils.hexToNumberString(Number(nft.id.tokenId))}</span></h1>
                <h4 className="m-0"><span className="">{nft.title}</span></h4>
                <div className="mt-1  ">
                  {nft.contractMetadata ?
                    <a className="" style={{ textDecoration: "none" }} href={`/#/contract/${nft.contract.address}`}>
                      {nft.contractMetadata.name} ({nft.contractMetadata.symbol})
                    </a> : ""}
                </div>
              </div>

              <div className="bg-black rounded-2 p-2 " style={{ position: "relative" }}>
                <Image style={{ width: '100%' }} className="rounded-3 img img-responsive full-width" url={media} />

                <div className="display-inline  text-center mt-3 mb-2" style={{ position: "absolute", bottom: "10px", left: "20px" }}>
                  <ButtonGroup aria-label="Basic example">
                    <Button onClick={() => addFavorites()} style={{ minWidth: "50px" }} variant="dark">{favorites} <Icon.Heart /></Button>

                  </ButtonGroup>
                </div>

                <div className="display-inline  text-center mt-3 mb-2" style={{ position: "absolute", bottom: "10px", right: "20px" }}>
                  <ButtonGroup aria-label="Basic example">
                    <Button variant="dark"><Icon.Share /></Button>
                  </ButtonGroup>
                </div>
              </div>


              <div className="mt-3 mb-3 border-0 rounded-3">

                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as="h6">Chain Info</Accordion.Header>
                    <Accordion.Body className="bg-black">
                      <Container>

                        {nft.contractMetadata !== undefined ?
                          <Row>
                            <Col className="text-start mb-1" xl={4} lg={3} md={6} sm={6} xs={6}><b>Type</b></Col>
                            <Col className="text-left mb-1">{nft.contractMetadata.tokenType}</Col>
                          </Row> : ""}

                        <Row>
                          <Col className="text-start mb-1" xl={4} lg={3} md={6} sm={6} xs={6}><b>Token ID</b></Col>
                          <Col className="text-left mb-1">
                            <a className="text-underline" href={nft.tokenUri.gateway} target="_blank">{web3.utils.hexToNumberString(Number(nft.id.tokenId))}</a>

                          </Col>
                        </Row>

                        <Row>
                          <Col className="text-start mb-1" xl={4} lg={3} md={6} sm={6} xs={6}><b>Contract Address</b></Col>
                          <Col>
                            <a className="text-underline d-inline-block text-truncate" href={`${explorerURL}/address/${nft.contract.address}`} rel="noreferrer" target="_blank"><FormatAddress address={nft.contract.address} /></a>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-start mb-1" xl={4} lg={3} md={6} sm={6} xs={6}><b>Last Updated</b></Col>
                          <Col className="text-left mb-1"><Moment fromNow>{nft.timeLastUpdated}</Moment></Col>
                        </Row>
                      </Container>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>History</Accordion.Header>
                    <Accordion.Body className="bg-black">
                      <div className="mb-5 rounded border-0 ">
                        {txns.length === 0 ? <Loading title="Going back to genesis..." /> :
                          <Container style={{ maxHeight: "300px", overflow: "scroll" }}>
                            <Row className="mb-1 rounded-3  bg-dark ">
                              <Col xs={2} className="p-2"><b></b></Col>
                              <Col xs={3} className="p-2"><b>From</b></Col>
                              <Col xs={3} className="p-2"><b>To</b></Col>
                              <Col xs={2} className="p-2"><b>Timestamp</b></Col>
                              <Col xs={2} className="p-2"><b></b></Col>
                            </Row>

                            {txns && txns.map((txn, idx) => {
                              return (

                                <Row className="mb-1 rounded-3  bg-dark  " key={idx} >

                                  <Col xs={2} className="p-2">


                                    <div>{txn.from === "0x0000000000000000000000000000000000000000" ? <div><Icon.PlusLg size="14" className="me-2" /> Mint</div> : ""}</div>

                                    <div>{web3.utils.toChecksumAddress(txn.to) == web3.utils.toChecksumAddress(marketplaceContractAddress) ? <div><Icon.BagPlus size="14" className="me-2" /> List</div> : ""} </div>

                                    <div>{web3.utils.toChecksumAddress(txn.to) === web3.utils.toChecksumAddress(wallet.wallet.address) && web3.utils.toChecksumAddress(txn.from) == web3.utils.toChecksumAddress(marketplaceContractAddress) ? <div><Icon.BagX size="14" className="me-2" /> Buy</div> : ""} </div>

                                    <div>{web3.utils.toChecksumAddress(txn.to) !== web3.utils.toChecksumAddress(wallet.wallet.address) && web3.utils.toChecksumAddress(txn.from) == web3.utils.toChecksumAddress(marketplaceContractAddress) ? <div><Icon.BagCheck size="14" className="me-2" /> Buy</div> : ""} </div>

                                  </Col>

                                  <Col xs={3} className="p-2">
                                    <div>

                                      {web3.utils.toChecksumAddress(txn.from) === web3.utils.toChecksumAddress(wallet.wallet.address) ?
                                        <span>You</span> :
                                        <>{web3.utils.toChecksumAddress(txn.from) === web3.utils.toChecksumAddress(marketplaceContractAddress) ? <span>Marketplace</span> : <FormatAddress address={txn.from} />}</>
                                      }

                                    </div>
                                  </Col>
                                  <Col xs={3} className="p-2"><div>

                                    {web3.utils.toChecksumAddress(txn.to) === web3.utils.toChecksumAddress(wallet.wallet.address) ? <span>You</span> :
                                      <>{web3.utils.toChecksumAddress(txn.to) === web3.utils.toChecksumAddress(marketplaceContractAddress) ? <span>Marketplace</span> : <span><FormatAddress address={txn.to} /></span>}</>
                                    }


                                  </div></Col>
                                  <Col xs={2} className="p-2"><div><Moment fromNow>{txn.metadata.blockTimestamp}</Moment></div></Col>

                                  <Col xs={2} className="text-center p-2" >
                                    <Badge bg="dark" style={{ color: "#000000" }}>
                                      <div><a href={`${explorerURL}/tx/${txn.hash}`} rel="noreferrer" target="_blank"><Icon.Link45deg size="14" /></a></div>
                                    </Badge>
                                  </Col>
                                </Row>

                              );
                            })}
                          </Container>
                        }



                      </div>
                    </Accordion.Body>
                  </Accordion.Item>


                  {nft.metadata && nft.metadata.attributes ?
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Attributes</Accordion.Header>
                      <Accordion.Body className="bg-black">
                        <Container className="text-left">
                          <Row>
                            {nft.metadata && nft.metadata.attributes ?
                              nft.metadata.attributes.map((attribute, idx) => {
                                return (
                                  <Col key={idx} xl={6} lg={6} md={6} sm={12}>
                                    <div className="rounded-3 p-3 mb-2 bg-dark text-truncate"><span className="text-orange">{attribute.trait_type}</span><br /><b>{attribute.value}</b></div>
                                  </Col>
                                )
                              })
                              : ""
                            }
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                    : ""}



                </Accordion>



              </div>

            </Col>
            <Col className="mb-2" xl={6} lg={6} md={12} sm={12}>

              <div className="d-none d-lg-block mb-4">
                <span className="float-end mt-2 me-2"><a variant="dark" className="w-100" href={`${explorerURL}/token/${nft.contract.address}?a=${web3.utils.hexToNumberString(Number(nft.id.tokenId))}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end" src={etherscan} /><span className="ms-2 d-none">Browse on Etherscan</span></a></span>

                <h1 className="m-0 highlight"><span className="">#{web3.utils.hexToNumberString(Number(nft.id.tokenId))}</span></h1>
                <h4 className="m-0"><span className="">{nft.title}</span></h4>
                <div className="mt-1  ">
                  {nft.contractMetadata ?
                    <a className="" style={{ textDecoration: "none" }} href={`/#/contract/${nft.contract.address}`}>
                      {nft.contractMetadata.name} ({nft.contractMetadata.symbol})
                    </a> : ""}
                </div>
              </div>

              <h6>Details</h6>
              <Text text={nft.description} />



              {owners !== undefined && owners.length > 0 ?
                <div className='text-center mt-2'>
                  <div className="mb-2">Owned by</div>
                  <a href={`#/address/${owners[0]}`}>
                    <FormatAddress styling={true} address={owners[0]} allowCopy={true} />
                  </a>
                </div>
                : ""}

              <div>
                <Buy nft={nft} owners={owners} />
              </div>



            </Col>

          </Row>

<Row>
  <Col>
  <div className="mb-5 rounded bg-black p-3 border-0 "><History contract={nft.contract.address} token={web3.utils.hexToNumberString(Number(nft.id.tokenId))} /></div>
  </Col>
</Row>

        </>
        : <Loading title="Loading NFT Details..." />}
    </>
  )

}

export default NFTDetails;
