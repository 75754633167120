import * as Icon from 'react-bootstrap-icons';
import CloseButton from 'react-bootstrap/CloseButton';
import { useState } from "react";
import { Loading } from '../components/UI'
import { Image, Form, Alert, Button } from 'react-bootstrap';
import { useToastContext, ADD } from '../contexts/ToastContext';
import { postData } from '../utils/api';


const { createAvatar } = require("@dicebear/avatars")
const style = require("@dicebear/open-peeps")


require('dotenv').config();
const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;

const axios = require('axios');

const NFT_STORAGE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDkwMkM4NWE5OWYwY0U2N0RhNjNDNTQxZTk0N2NiYTFBNjUwRmRDNjMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDE2ODA2NTcwOSwibmFtZSI6Ik1pbnQifQ.udjNalRNB5Q-cyRuoatEn3ly3spcH8-M6UQ6jf_Iqic"

const Upload = (props) => {
    const { setUploadURL } = props;
    const { toastDispatch } = useToastContext();



    const [selectedFile, setSelectedFile] = useState(null);
    const [isSelected, setIsSelected] = useState(false);

    const [ipfsURL, setIpfsURL] = useState(null);

    const [loading, setLoading] = useState(false);
    const [sample, setSample] = useState(null);
    const [originalSample, setOriginalSample] = useState(null);

    // const nftstorage = new NFTStorage({ token: NFT_STORAGE_KEY })

    function makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }
  
    const generateSample = () => {
      let svg = createAvatar(style, { seed: makeid(15) + window.performance.now });
      setOriginalSample(svg);
      setSample("data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg.replace(/"/g, '\'')))));
      setIsSelected(true);
    }

    const unselect = () => {
      setSample(null);
      setSelectedFile(null);
      setIsSelected(false);
    };

    const changeHandler = (event) => {
      setIpfsURL(null)
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    };
  
    const handleSubmission = async (e) => {
      e.preventDefault();
      setLoading(true)

      // const result = await nftstorage.store({
      //   selectedFile,
      // })

      //   setUploadURL(result);

      //   return

      

      
      if(originalSample !== null) {
        // formData.append('file', sample);
        const url = "https://api.nft.storage/upload"
        try { 
          const resFile = await axios(
            {
              method: "post",
              url: url,
              data: originalSample,
              headers: {
                // pinata_api_key: key,
                // pinata_secret_api_key: secret,
                Authorization: `Bearer ${NFT_STORAGE_KEY}`,
                // "Content-Type": "multipart/form-data"
              },
            }
          )
          
            // const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
            // setUploadURL(resFile.data.IpfsHash);
            setUploadURL({
              hash: resFile.data.value.cid,
              metadata: selectedFile,
            });
            setIpfsURL(resFile.data.value.cid)
            setLoading(false)
            toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: "Upload Successful. You are on your way to create an awesome collection" } } })

            // const formData = new FormData();
            // formData.append('file', event.target.files[0]);
            // postData(`media?name=` + resFile.data.value.cid, formData)

            // setMessage("Upload Successful. Proceed to Step 3 and the upload URL is auto populated")
            // setError(null)
            // return false
          }
          catch (err) {
            console.log(err)
            toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
          }        

          return
      }
  
      const formData = new FormData();
      formData.append('file', selectedFile);
      
      // const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      // const url = `/api/ipfs/media`;
      const url = "https://api.nft.storage/upload"
      try { 
        const resFile = await axios(
          {
            method: "post",
            url: url,
            data: formData,
            headers: {
              // pinata_api_key: key,
              // pinata_secret_api_key: secret,
              Authorization: `Bearer ${NFT_STORAGE_KEY}`,
              "Content-Type": "multipart/form-data"
            },
          }
        )
    
        
          // const ImgHash = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;
          // setUploadURL(resFile.data.IpfsHash);
          // setUploadURL(resFile.data.value.cid + "/" + selectedFile.name);

          setUploadURL({
            hash: resFile.data.value.cid + "/" + selectedFile.name,
            metadata: selectedFile,
          });
          
          setIpfsURL(resFile.data.value.cid + "/" + selectedFile.name)
          setLoading(false)

          postData(`media?name=` + resFile.data.value.cid + "/" + selectedFile.name, formData)
          // setMessage("Upload Successful. Proceed to Step 3 and the upload URL is auto populated")
          // setError(null)
          // return false
        }
        catch (err) {
          console.log(err)
          toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
          // return false
        }

        return false; 
    };
  
  
    return (        
        
        <>



          <p className="mb-3">Upload your asset's to IPFS</p>
               
               
               <p>If you don't have a image yet, I recommend trying out a sample image. <b className="pointer" onClick={generateSample}>Click here to auto generate a random avatar</b></p>



          {isSelected ? (
            <>
            <div className="mt-2 mb-2">
                {/* <Col xl={7} lg={7} md={12} sm={12}> */}
                <div style={{ position: "relative"}}>
                  { !loading ? 
                  <div>
    

                    { selectedFile ? <Image className="img w-100 img-thumbnail img-responsive full-width" src={URL.createObjectURL(selectedFile)} /> : "" }
                    
                    {sample ? <Image className="img w-100 img-thumbnail img-responsive full-width" src={sample} /> : "" }
                    
                    

                    { ipfsURL === null ?
                    <>
                      <div className="bg-white-soft rounded-circle p-3" style={{ position: "absolute", top:"20px", right: "20px" }}  onClick={ () => unselect() } >
                        <CloseButton className="shadow-0 rounded-0 border-0 " variant="white"/>
                      </div>
                      <div >
                        <Button variant="primary" className="w-100 mt-3" onClick={handleSubmission}> <Icon.Upload size="24" className="me-2" /> Upload</Button>
                      </div> 
                      </>: "" }
                    </div> : <Loading title="Uploading to IPFS..." /> }
                </div>
                  <ul>
                  <li><div className="text-truncate">Filename: <span >{selectedFile.name}</span></div></li>
                  <li>Filetype: {selectedFile.type}</li>
                  <li>Size in bytes: {selectedFile.size}</li>
                  <li> lastModifiedDate:{' '} {selectedFile.lastModifiedDate.toLocaleDateString()}</li>
                  </ul>
            </div>
            </>
          ) : (
            <Form.Control type="file" name="file" onChange={changeHandler} />
          )}

          

          </>
    );
}

export default Upload;
