
import { useEffect, useState } from "react";
import * as Icon from 'react-bootstrap-icons';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import {  Card, Container, Row, Col } from 'react-bootstrap';

const Theme = (props) => {
    const [radioValue, setRadioValue] = useState(props.theme);
    const radios = [
      { name: 'dark', value: 'dark' },
      { name: 'light', value: 'light' },
    ];
  
    useEffect(() => {
      props.onThemeToggle(radioValue)
    }, [radioValue]);




    return (
        <>
        <div >



          

          


            <ButtonGroup size="sm">
            {radios.map((radio, idx) => (
                <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                size="sm"
                className={radio.name}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                {radio.name == "light" ? <Icon.Sun size="14" /> : <Icon.Moon size="14" />}
                </ToggleButton>
            ))}
            </ButtonGroup>
        </div>       
        </>
    )
}

export default Theme;