import Web3 from 'web3';
import { Network, Alchemy } from "alchemy-sdk";
import { GetIPFSURL } from "../Utils"
import { getData } from "../utils/api"

require('dotenv').config();


const NETWORKS = {
  1: {
    name: "Ethereum Main Network",
    symbol: "ETH",
    explorer: "https://etherscan.io/"
  },
  // 3: "Ropsten Test Network",
  // 4: "Rinkeby Test Network",
  5: {
    name: "Goerli Test Network",
    symbol: "GoerliETH",
    explorer: "https://goerli.etherscan.io/"
  },
  // 42: "Kovan Test Network",
  // 56: "Binance Smart Chain",
  80001: {
    name: "Polygon Mumbai Testnet",
    symbol: "MATIC",
    explorer: "https://mumbai.polygonscan.com/"
  },
  // 1337: "Ganache",
  // 31337: "Anvil"
};


// For example, 1 minutes is 60, 1 hours is 3600 (60 seconds x 60 minutes), etc.


const axios = require('axios')
let provider = window.ethereum

export const web3 = new Web3(window.ethereum);
const collectionContract = require('../eth/contracts/Collection.json')
const marketplaceContract = require('../eth/contracts/Marketplace.json')

// export const web3 = new Web3("https://eth-goerli.g.alchemy.com/v2/oH1TeGsSbsnEBjhyHvZHIye71x6DhKka")


export var chain = "eth-goerli"
let apiKey = "oH1TeGsSbsnEBjhyHvZHIye71x6DhKka"
export let marketplaceContractAddress = web3.utils.toChecksumAddress("0x07Fb27545c81b01F98Fa91b1aA5769f5AE704B42")
export let explorerURL = "https://goerli.etherscan.io/"
let baseURL = `https://${chain}.g.alchemy.com`
let _marketplaceContractABI = new web3.eth.Contract(marketplaceContract.abi, web3.utils.toChecksumAddress(marketplaceContractAddress));
let settings = {
  apiKey: apiKey,
  network: Network.ETH_GOERLI,
};

export let alchemy = new Alchemy(settings);

export const getChain = () => {
  return chain
}


export const switchNetworkConfig = (chain) => {
  if (chain == "80001") {
    chain = "polygon-mumbai"
    apiKey = "UG9AaX9zVirSgWuTodPQfxPt8hKwRSgi"
    marketplaceContractAddress = web3.utils.toChecksumAddress("0x73bADf2B19Bad9BB40Fa4531FFe525a61007A8d9")
    explorerURL = "https://mumbai.polygonscan.com"
    settings.apiKey = apiKey
    settings.network = Network.MATIC_MUMBAI
  }

  if (chain == "5") {
    chain = "eth-goerli"
    apiKey = "oH1TeGsSbsnEBjhyHvZHIye71x6DhKka"
    marketplaceContractAddress = web3.utils.toChecksumAddress("0x07Fb27545c81b01F98Fa91b1aA5769f5AE704B42")
    explorerURL = "https://goerli.etherscan.io/"
    settings.apiKey = apiKey
    settings.network = Network.ETH_GOERLI
  }

  baseURL = `https://${chain}.g.alchemy.com`
  _marketplaceContractABI = new web3.eth.Contract(marketplaceContract.abi, web3.utils.toChecksumAddress(marketplaceContractAddress));

  alchemy = new Alchemy(settings);
}



export const getLogs = async (address) => {
  const contractCreated = web3.utils.sha3('NFTListed(uint256,address,uint256,address,address,uint256)')

  //console.log("Getting Logs")
  await web3.eth.getPastLogs({
    fromBlock: '0x0',
    address: web3.utils.toChecksumAddress(address),
    topics: [
      contractCreated,
    ]
  }).then(res => {
    res.forEach(rec => {
      //console.log(rec);
      const typesArray = [
        { type: 'uint256', name: 'listingId' },
        { type: 'address', name: 'nftContract' },
        { type: 'uint256', name: 'tokenId' },
        { type: 'address', name: 'seller' },
        { type: 'address', name: 'owner' },
        { type: 'uint256', name: 'price' }
      ];
      const json = web3.eth.abi.decodeParameters(typesArray, rec.data);
      //console.log(JSON.stringify(json))
    });
  }).catch(err => console.log("getPastLogs failed", err));
  //console.log("Getting Done")
}

export const getTokenBalance = async () => {
  //index.js

  const Web3 = require("web3");

  const provider = "https://cold-holy-county.matic-testnet.discover.quiknode.pro/ae5a376857a81c917579d172e82f73485fe6b050/"
  const Web3Client = new Web3(new Web3.providers.HttpProvider(provider));

  // The minimum ABI required to get the ERC20 Token balance
  const minABI = [
    // balanceOf
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      type: "function",
    },
  ];
  const tokenAddress = "0x764935F1F115Ef72864469A6CA9eBB26A2ef6fd0";
  const walletAddress = "0x747A04B14CEd1057E260Bac5993D2A81F830F2F5";

  const contract = new Web3Client.eth.Contract(minABI, tokenAddress);

  const result = await contract.methods.balanceOf(walletAddress).call(); // 29803630997051883414242659
  console.log(result);

  const format = Web3Client.utils.fromWei(result); // 29803630.997051883414242659

  console.log(format);

}

export const connectWallet = async (name) => {
  if (window.ethereum) {
    try {

      let provider = window.ethereum



      if (name == "coinbase") {
        provider = window.ethereum.providers.find((provider) => provider.isCoinbaseWallet);
      }

      if (name == "metamask") {
        provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      }



      console.log(provider)

      // const provider = window.ethereum      

      // const providers = await web3.providers
      //console.log("Providers", window.ethereum.providers);

      const chainId = await web3.eth.net.getId();

      // // const chainId = await web3.eth.getChainId()
      // // web3.givenProvider.providers gives a list of all wallets installed in the browser

      // const provider = web3.givenProvider.providers[0]

      // provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
      web3.setProvider(provider)

      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });

      // const accounts = await web3.eth.getAccounts()

      // const chainDetails = getChainName()
      // //console.log(chainDetails)

      // let explorer = "https://goerli.etherscan.io/"
      // if (chainId === 80001) {
      //   explorer = "https://mumbai.polygonscan.com/"
      // }

      // if(chainId === 5 || chainId === 31337 || chainId === 80001) {
      //   localStorage.setItem("chain", chainId)
      // }

      const marketplaceChain = localStorage.getItem("chain")
      console.log(chainId, marketplaceChain)

      let networkSupported = false
      if (chainId === 5 || chainId === 31337 || chainId === 80001) {
        // localStorage.setItem("chain", chainId)
        networkSupported = true

        // if(String(chainId) !== marketplaceChain) {
        //   await switchChain(chainId, Number(marketplaceChain))
        // }
      } else {
        networkSupported = false
      }


      // const walletAddress = accounts[0]; // replace with wallet address
      // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
      // const nfts = await alchemy.nft.getNftsForOwner(walletAddress, {
      //   contractAddresses: [ensContractAddress],
      // });

      // console.log(nfts);
      // const tokenMetadataJugaad = await alchemy.core.getTokenMetadata("0x55eecca0d95a3677d164f51df13324b0762e2b68");
      // console.log(tokenMetadataJugaad)

      // console.log(window.ethereum.providers)
      // let walletProvider = window.ethereum.providers.find((x) => x.isCoinbaseWallet) // isCoinbaseWallet is part of the injected provider
      // console.log(walletProvider)
      

      // const tokenAddress = "0x7d3e7e8f2c8861991ff5d2ea9325c9d3decbb73d" //"0x764935F1F115Ef72864469A6CA9eBB26A2ef6fd0";
      // const walletAddress = "0x747A04B14CEd1057E260Bac5993D2A81F830F2F5";
      // ["0x7d3e7e8f2c8861991ff5d2ea9325c9d3decbb73d
      


      // const data = await alchemy.core.getTokenBalances(
      //   web3.utils.toChecksumAddress(walletAddress),
      //   'erc20'
      // );

      // console.log("Token balance for Address");
      // console.log(data);
      

      // for (let token of data.tokenBalances) {
      //   // console.log(token.contractAddress)
      //   // console.log("WEI", web3.utils.hexToNumber(token.tokenBalance));
      //   const tokenMetadata = await alchemy.core.getTokenMetadata(token.contractAddress);
      //   // console.log(tokenMetadata);
      //   const balance = web3.utils.hexToNumber(token.tokenBalance)/Math.pow(10, tokenMetadata.decimals).toFixed(2)
      //   console.log(`${tokenMetadata.name}: ${balance} ${tokenMetadata.symbol}`);


      // }


      // getTokenBalance()

      const obj = {
        status: true,
        address: web3.utils.toChecksumAddress(accounts[0]),
        error: null,
        chain: Number(chainId),
        network: NETWORKS[chainId].name,
        symbol: NETWORKS[chainId].symbol,
        explorer: NETWORKS[chainId].explorer,
        mareketplaceNetwork: NETWORKS[Number(marketplaceChain)].name,
        isSupported: networkSupported //(String(chainId) === marketplaceChain) ? true : false
        // isSupported: (chainId === 5 || chainId === 31337 || chainId === 80001) ? true : false
      };

      // await getLogs(marketplaceContractAddress)
      return obj;
    } catch (err) {
      return {
        address: null,
        chain: null,
        status: false,
        error: err.message,
        network: null,
        isSupported: false
      };
    }
  } else {
    //console.log("NO_METAMASK")
    return {
      address: null,
      chain: null,
      status: false,
      network: null,
      isSupported: false,
      error: "NO_METAMASK"
    };
  }
};

export async function switchChain(from, to) {
  // //console.log(from, to)
  if (from !== to) {
    try {
      // web3.utils.toHex(51)
      // alert(`Please connect to ${to} to continue`)

      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(to) }]
      })
      return true
    } catch (switchError) {

      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902 || switchError.code == -32603) {
        try {
          if (to == 5) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.asciiToHex(String(5)),
                  chainName: "Goerli test network",
                  rpcUrls: ["https://goerli.infura.io/v3/"],
                  nativeCurrency: {
                    name: "GoerliETH",
                    symbol: "GoerliETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://goerli.etherscan.io/"],
                }
              ]
            })

          }

          else if (to == 80001) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: "0x13881",
                  chainName: "Polygon Testnet Mumbai",
                  rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                  nativeCurrency: {
                    name: "MATIC",
                    symbol: "MATIC",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                }
              ]
            })
          }

          else {
            console.log("Switch Error", switchError)
            return false
          }
        } catch (addError) {
          alert(`Please connect to ${to} to continue`)
        }
      }
      return false
    }
  }
  return true

}


export const getWalletBalance = async (address, network) => {
  try {
    // let currency = "ETH"

    // if(network == "Polygon Mumbai Testnet") {
    //   currency = "MATIC"
    // }

    return web3.eth.getBalance(address).then(function (response) {
      let balance = web3.utils.fromWei(response, "ether");
      return parseFloat(Number(balance).toFixed(4))
    })
  } catch (err) {
    return err.message
  }
}


const getIPFSData = async (cid) => {
  return await fetch("https://gateway.pinata.cloud/ipfs/" + cid, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then(function (response) {
    return response.json();
  })
    .then(function (data) {
      return data
    });
}

export const getContractMetadata = async (contract_address) => {
  try {
    const collectionContractAbi = await new web3.eth.Contract(collectionContract.abi, web3.utils.toChecksumAddress(contract_address));
    // const symbol = await collectionContractAbi.methods.symbol().call();
    // const name = await collectionContractAbi.methods.name().call();
    // const decimals = await window.contract.methods.decimals().call();

    var contractMetadataObj = {}
    contractMetadataObj.name = await collectionContractAbi.methods.name().call();
    contractMetadataObj.symbol = await collectionContractAbi.methods.symbol().call();
    contractMetadataObj.tokenType = "ERC721"

    const owner = await collectionContractAbi.methods.owner().call();

    return {
      address: contract_address,
      contract_owner: owner,
      contractMetadata: contractMetadataObj,
      contractMetadata_extended: null
    };

    var contractMetadataURL = await collectionContractAbi.methods.contractURI().call().then(function (result) {
      return result
    });


    // //console.log(contractMetadataURL);

    return await fetch("https://ipfs.io/ipfs/" + contractMetadataURL, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    })
      .then(function (data) {
        //console.log(data);
        return {
          address: contract_address,
          contract_owner: owner,
          contractMetadata: contractMetadataObj,
          contractMetadata_extended: data
        };

        // return Object.assign(contractMetadata, data);
      });
  } catch (err) {
    //console.log(err);
    return null
  }

}


// export const getContractEvents = async (contract_address) => {

//   const nfts = await getAllNFTsByContract(contract_address)
//   return nfts;

//   // var nfts = []

//   // try {
//   //   window.contract = await new web3.eth.Contract(collectionContract.abi, contract_address);

//   //   return await window.contract.getPastEvents('Transfer', {
//   //     filter: {
//   //       _from: '0x0000000000000000000000000000000000000000'
//   //     },
//   //     fromBlock: 0
//   //   }).then(async (events) => {

//   //     for (let event of events) {
//   //       // //console.log(event);
//   //       // //console.log(event.returnValues.tokenId);
//   //       if (event.returnValues.from === "0x0000000000000000000000000000000000000000") {
//   //         // const contractMetadataURL = 
//   //         await window.contract.methods.tokenURI(event.returnValues.tokenId).call().then(async function (result) {
//   //           //console.log(result)
//   //           var tokenDetails = await getIPFSData(result)
//   //           var obj = {}
//   //           obj.metadataError = null
//   //           obj.tokenId = event.returnValues.tokenId
//   //           obj.contract = {}
//   //           obj.contract.address = contract_address
//   //           obj.title = tokenDetails.name
//   //           obj.description = tokenDetails.description
//   //           obj.media = []
//   //           obj.media[0] = {}
//   //           obj.media[0].gateway = "https://cloudflare-ipfs.com/ipfs/" + tokenDetails.image
//   //           nfts.push(obj)
//   //         });
//   //       }
//   //     }

//   //     return nfts;
//   //   });



//   // } catch (err) {
//   //   //console.log(err);
//   //   return nfts;
//   // }

// }

// export const getAllNFTsByContract = async (contract_address, limit, pageKey) => {

//   var response = await alchemy.nft.getNftsForContract(
//     contract_address,
//     {
//       omitMetadata: false,
//       pageSize: limit,
//       pageKey: pageKey
//     }
//   )

//   // //console.log("contract nfts", response);
//   return response;


//   // var withMetadata = true;
//   // var url = `${baseURL}/nft/v2/${apiKey}/getNFTsForCollection?contractAddress=${contract_address}&withMetadata=${withMetadata}`;
//   // var nfts = []

//   // const config = {
//   //     method: 'get',
//   //     url: url,
//   // };

//   // // //console.log(JSON.stringify(response['data'], null, 2))
//   // await axios(config)
//   //     .then(response => nfts = response['data']["nfts"])
//   //     .catch(error => //console.log(error));

//   // //console.log(nfts);
//   // return nfts;
// };




// export const getOwnersForCollection = async (contract_address) => {

//   const options = { method: 'GET', headers: { accept: 'application/json' } };
//   var apiresonse = {}

//   var url = `${baseURL}/nft/v2/${apiKey}/getOwnersForCollection?withTokenBalances=true&contractAddress=${contract_address}`

//   await fetch(url, options)
//     .then(response => response.json())
//     .then(response => apiresonse = response)
//     .catch(err => console.error(err));

//   //console.log("getOwnersForCollection", contract_address, apiresonse);
//   return apiresonse;

// };

// export const isHolderOfCollection = async (address, contract_address) => {

//   const options = { method: 'GET', headers: { accept: 'application/json' } };
//   var apiresonse = {}

//   var url = `${baseURL}/nft/v2/${apiKey}/isHolderOfCollection?wallet=${address}&contractAddress=${contract_address}`

//   await fetch(url, options)
//     .then(response => response.json())
//     .then(response => apiresonse = response)
//     .catch(err => console.error(err));

//   // //console.log("getContractsForOwner", address, contract_address, apiresonse);
//   return apiresonse.isHolderOfCollection;

// };


// export const getAllContractByAddress = async (address, pageKey) => {

//   const options = { method: 'GET', headers: { accept: 'application/json' } };
//   var apiresonse = {}

//   var url = `${baseURL}/nft/v2/${apiKey}/getContractsForOwner?owner=${address}`
//   if (pageKey != "") {
//     url = url + `&pageKey=${pageKey}`
//   }
//   // https://eth-goerli.g.alchemy.com/nft/v2/oH1TeGsSbsnEBjhyHvZHIye71x6DhKka/getContractsForOwner?owner=0x747A04B14CEd1057E260Bac5993D2A81F830F2F5
//   await fetch(url, options)
//     .then(response => response.json())
//     .then(response => apiresonse = response)
//     .catch(err => console.error(err));

//   // //console.log("getContractsForOwner", address, apiresonse);
//   return apiresonse;

// };


// export const getAllNFTsByAddress = async (address, limit, pageKey) => {

//   const options = { method: 'GET', headers: { accept: 'application/json' } };


//   var apiresonse = {}

//   await fetch(`${baseURL}/nft/v2/${apiKey}/getNFTs?owner=${address}&pageSize=${limit}&pageKey=${pageKey}`, options)
//     .then(response => response.json())
//     .then(response => apiresonse = response)
//     .catch(err => console.error(err));

//   // //console.log(address, limit, pageKey)
//   // var response = await alchemy.nft.getNftsForOwner(
//   //   address,
//   //   {
//   //     omitMetadata: false, 
//   //     pageSize: limit,
//   //     pageKey: pageKey
//   //   }
//   // )

//   // //console.log("contract nfts", apiresonse);
//   return apiresonse;


//   // var withMetadata = true;
//   // var url = `${baseURL}/nft/v2/${apiKey}/getNFTsForCollection?contractAddress=${contract_address}&withMetadata=${withMetadata}`;
//   // var nfts = []

//   // const config = {
//   //     method: 'get',
//   //     url: url,
//   // };

//   // // //console.log(JSON.stringify(response['data'], null, 2))
//   // await axios(config)
//   //     .then(response => nfts = response['data']["nfts"])
//   //     .catch(error => //console.log(error));

//   // //console.log(nfts);
//   // return nfts;
// };

export const getAssetTransfers = async (wallet_address) => {

  let data = JSON.stringify({
    "jsonrpc": "2.0",
    "id": 42,
    "method": "alchemy_getAssetTransfers",
    // "method": "alchemy_getTokenBalances",
    "params":
    //  [ "0xa26aecfbbbeefed0377406735cf3a390b1720e7e", ["0xc5f8b5937220596590baec95aa07802bb0cdaf43"] ]
    {
      // "fromBlock": "0x0",
      "toBlock": "latest",
      "fromAddress": wallet_address,
      // "toAddress": wallet_address,
      // "contractAddresses": [contract_address],
      "excludeZeroValue": false,
      "withMetadata": true,
      "order": "desc",
      "category": ["external"]
    }

  });

  var requestOptions = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: data,
    redirect: 'follow'
  };

  var url = `${baseURL}/v2/${apiKey}`

  // //console.log(url)
  var txns = []
  await axios(url, requestOptions)
    .then(response => txns = response['data']['result']['transfers'].filter(
      txn => txn["to"] === null
    ))
    .catch(error => console.log(error));

  return txns
}


export const getNFTLogs = async (contract_address, token_id) => {

  let data = JSON.stringify({
    "jsonrpc": "2.0",
    "id": 42,
    "method": "alchemy_getAssetTransfers",
    // "method": "alchemy_getTokenBalances",
    "params":
    //  [ "0xa26aecfbbbeefed0377406735cf3a390b1720e7e", ["0xc5f8b5937220596590baec95aa07802bb0cdaf43"] ]
    {
      "fromBlock": "0x0",
      // "fromAddress": "0x0000000000000000000000000000000000000000",
      // "toAddress": "0xa26aecfbbbeefed0377406735cf3a390b1720e7e",
      "contractAddresses": [contract_address],
      "excludeZeroValue": true,
      "withMetadata": true,
      "order": "desc",
      "category": ["erc721"]
    }

  });

  var requestOptions = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: data,
    redirect: 'follow'
  };

  var url = `${baseURL}/v2/${apiKey}`

  // //console.log(url)
  var txns = []
  await axios(url, requestOptions)
    .then(response => txns = response['data']['result']['transfers'].filter(
      txn => web3.utils.hexToNumber(txn['erc721TokenId']) === Number(token_id)
    ))
    .catch(error => console.log(error));

  return txns
}

export const getNFTOwners = async (contract_address, token_id) => {
  const response = await alchemy.nft.getOwnersForNft(contract_address, token_id);
  return response.owners
}


export const getNFTDetails = async (contract_address, token_id) => {
  var response = await alchemy.nft.getNftMetadata(
    contract_address,
    token_id
  )

  response["id"] = {}
  response["id"]["tokenId"] = response.tokenId
  const contractMetadata = await alchemy.nft.getContractMetadata(contract_address);
  response["contractMetadata"] = contractMetadata

  // OR I CAN DO
  // const data = await getData(`provider/contract/${contract_address}`)
  // //console.log(data.data)

  // const nftOwners = await getNFTOwners(contract_address, token_id)
  // response["owners"] = nftOwners

  // const txns = await getNFTLogs(contract_address, response.tokenId)
  // response["txns"] = txns

  return response


  // const customContractAbi = await new web3.eth.Contract(collectionContract.abi, contract_address);
  // // const marketplaceContractAbi = await new web3.eth.Contract(collectionContract.abi, contract_address);

  // // window.contract = await new web3.eth.Contract(collectionContract.abi, contract_address);
  // return await customContractAbi.methods.tokenURI(token_id).call().then(async function (result) {

  //   var tokenDetails = await getIPFSData(result)
  //   var obj = {}
  //   obj.tokenId = token_id
  //   obj.contract = {}
  //   obj.contract.address = contract_address
  //   obj.title = tokenDetails.name
  //   obj.description = tokenDetails.description
  //   obj.media = []
  //   obj.media[0] = {}
  //   obj.media[0].gateway = "https://cloudflare-ipfs.com/ipfs/" + tokenDetails.image
  //   obj.txns = [] // Get Contract events by Token ID

  //   obj.contractMetadata = {}
  //   obj.contractMetadata.name = await customContractAbi.methods.symbol().call();
  //   obj.contractMetadata.symbol = await customContractAbi.methods.name().call();
  //   obj.contractMetadata.tokenType = ""

  //   obj.owners = []
  //   // const nftOwners = await getNFTOwners(contract_address, token_id)
  //   await customContractAbi.methods.ownerOf(token_id).call().then(async function (result) {
  //     //console.log(result);
  //     obj.owners.push(result)
  //   });

  //   const txns = await getNFTLogs(contract_address, token_id)
  //   //console.log("trasnactions", txns)
  //   obj.txns = txns

  //   return obj
  // });

  // // Print NFT metadata returned in the response:
  // var response = await alchemy.nft.getNftMetadata(
  //   contract_address,
  //   token_id
  // )

  // //console.log(response)
  // response["id"] = {}
  // response["id"]["tokenId"] = response.tokenId

  // const contractMetadata = await alchemy.nft.getContractMetadata(contract_address);
  // response["contractMetadata"] = contractMetadata


  // const nftOwners = await getNFTOwners(contract_address, token_id)
  // response["owners"] = nftOwners



  // const txns = await getNFTLogs(contract_address, response.tokenId)
  // response["txns"] = txns

  // return response
}

export const getSignature = async (message, address) => {
  const signature = await provider.request({
    method: "personal_sign",
    params: [message, address],
  });
  //console.log({ signature });
}

export const getAccounts = async () => {
  const accounts = await web3.eth.getAccounts().then(function (response) {
    return response;
  });
  // //console.log(accounts);
}


// export async function getChainName() {
//   try {
//     // const web3 = getWeb3();
//     const chainId = await web3.eth.getChainId();
//     const response = await fetch("https://chainid.network/chains.json");
//     const chainListInfo = await response.json();
//     const currentChain = chainListInfo.find((e) => e.chainId === chainId);
//     //console.log(currentChain);
//     return currentChain;
//   } catch (err) {
//     throw err;
//   }
// }




export const createContract = async (name, symbol, url, wallet_address) => {
  const MyContract = new web3.eth.Contract(collectionContract.abi)
  return MyContract.deploy({
    data: collectionContract.bytecode.object,
    arguments: [marketplaceContractAddress, name, symbol, url],
  }).send({
    from: wallet_address,
  }).then(function (result) {
    // //console.log(result);
    return result
  });


  // .on('error', (error) => {
  //   //console.log("error", error)
  // })
  // .on('transactionHash', (transactionHash) => {
  //   //console.log("transactionHash", transactionHash)
  // })
  // .on('receipt', (receipt) => {
  //    // receipt will contain deployed contract address
  //    //console.log("receipt", receipt)
  // })
  // .on('confirmation', (confirmationNumber, receipt) => {
  //   //console.log("confirmation", confirmationNumber, receipt)
  // })
}


export const mintOnContract = async (contract_address, token_uri) => {
  const collectionContractAbi = await new web3.eth.Contract(collectionContract.abi, contract_address);

  try {
    const transactionParameters = {
      to: contract_address,
      from: provider.selectedAddress,
      'data': collectionContractAbi.methods.mint(token_uri).encodeABI()
    };

    return executeTransaction(transactionParameters)
    // const txHash = await provider.request({
    //   method: 'eth_sendTransaction',
    //   params: [transactionParameters],
    // });

    // return {
    //   error: null,
    //   txHash: txHash
    // }
  } catch (err) {
    return {
      txHash: null,
      error: err.message
    };
  }

}


export const getListedNFTDetails = async (contract_address, token_id) => {
  try {
    return await _marketplaceContractABI.methods.getNftListing(contract_address, token_id).call().then(function (result) {
      //console.log("NFT Listing Details", result);
      return result[1]
    });
  } catch (error) {
    //console.log(error)
  }


}


export const getListedNFTs = async () => {
  // const marketplaceContractABI = await new web3.eth.Contract(marketplaceContract.abi, marketplaceContractAddress);
  try {


    const listings = await getData(`listings?refresh=1`)
    return listings.data.data || []

    // let _method = _marketplaceContractABI.methods.getListedNfts()
    // let transactionParameters = {
    //   to: marketplaceContractAddress,
    //   // from: provider.selectedAddress,
    // };

    // // const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    // // //console.log(estimatedGasAmount)
    // // transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)
    // transactionParameters.data = _method.encodeABI()

    // //console.log(transactionParameters);


    // return await web3.eth.call(transactionParameters).then(function(rec) {
    //   //console.log(rec)
    //   const typesArray = [
    //     { type: 'uint256', name: 'listingId' },
    //     { type: 'address', name: 'nftContract' },
    //     { type: 'uint256', name: 'tokenId' },
    //     { type: 'address', name: 'seller' },
    //     { type: 'address', name: 'owner' },
    //     { type: 'uint256', name: 'price' },
    //     { type: 'bool', name: 'listed' }
    //   ];
    //   const json = web3.eth.abi.decodeParameters([typesArray], rec);

    //   //console.log(JSON.stringify(json))

    //   let nfts = []
    //   nfts.push(JSON.parse(JSON.stringify(json)))
    //   //console.log(nfts)

    //   return nfts
    // })




    // await _marketplaceContractABI.methods.getNftListing("0x093b8501ef5a726415a45677aa024f5952937f7c", 1).call().then(function (result) {
    //   //console.log("NFT Listing Result", result);
    //   return result
    // });

    return await _marketplaceContractABI.methods.getListedNfts().call().then(function (result) {
      //console.log("LISTED NFTS", result);
      return result
    });
  } catch (error) {
    console.log(error)
  }


}



export const getMyNfts = async () => {
  const marketplaceContractABI = await new web3.eth.Contract(marketplaceContract.abi, marketplaceContractAddress);
  // .send({ from: provider.selectedAddress });
  return await marketplaceContractABI.methods.getMyNfts().call({
    from: provider.selectedAddress
  }).then(function (result) {
    //console.log("my nfts", result);
    return result
  });


}

export const executeTransaction = async (transactionParameters) => {
  // const txHash = await provider.request({
  //   method: 'eth_sendTransaction',
  //   params: [transactionParameters],
  // });

  // return {
  //   error: null,
  //   txHash: txHash
  // }

  const receipt = await web3.eth.sendTransaction(transactionParameters)
  //console.log(`Transaction hash: ${receipt.transactionHash}`);

  return {
    error: null,
    txHash: receipt.transactionHash
  }
}

export const delistNft = async (contract_address, listing_id) => {

  try {
    let _method = _marketplaceContractABI.methods.delistNft(contract_address, listing_id)
    let transactionParameters = {
      to: marketplaceContractAddress,
      from: provider.selectedAddress,
    };

    const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)
    transactionParameters.data = _method.encodeABI()

    //console.log(transactionParameters);

    return executeTransaction(transactionParameters)

    // const receipt = await web3.eth.sendTransaction(transactionParameters) 
    // //console.log(`Transaction hash: ${receipt.transactionHash}`);

    // return {
    //   error: null,
    //   txHash: receipt.transactionHash
    // }


    // const txHash = await provider.request({
    //   method: 'eth_sendTransaction',
    //   params: [transactionParameters],
    // });

    // return {
    //   error: null,
    //   txHash: txHash
    // }
  } catch (err) {
    //console.log(err);
    return {
      txHash: null,
      error: err.message
    };
  }

}

export const approveListing = async (contract_address, token_id) => {
  //load smart contract
  // const marketplaceContractABI = await new web3.eth.Contract(marketplaceContract.abi, marketplaceContractAddress);
  // const customContractABI = await new web3.eth.Contract(collectionContract.abi, contract_address);
  // const _customContractABI = new web3.eth.Contract(collectionContract.abi, contract_address);
  const collectionContractAbi = await new web3.eth.Contract(collectionContract.abi, contract_address);

  let _method = collectionContractAbi.methods.approve(marketplaceContractAddress, token_id)
  let transactionParameters = {
    from: provider.selectedAddress,
    to: contract_address,
  };
  transactionParameters.data = _method.encodeABI()
  //console.log(transactionParameters);

  return executeTransaction(transactionParameters)

  // const txHash = await provider.request({
  //   method: 'eth_sendTransaction',
  //   params: [transactionParameters],
  // });

  // //console.log(txHash);
  // return {
  //   error: null,
  //   txHash: txHash
  // }




  // const isApprovedForAll = await web3.eth.ens.isApprovedForAll(provider.selectedAddress, marketplaceContractAddress).then(function (isApproved) {
  //     //console.log("response", isApproved);
  //     return isApproved
  // })

  // if(!isApprovedForAll) {
  // return _customContractABI.methods.approve(marketplaceContractAddress, token_id).send({
  //   to: marketplaceContractAddress,
  //   from: provider.selectedAddress,
  // }, function (error, transactionHash) {
  //   return { error, transactionHash }
  // });
  // }
}


export const listNft = async (contract_address, token_id, price, listing_type, expiry_days, reserved_address) => {
  console.log(contract_address, token_id, price, listing_type, expiry_days)
  try {

    let listingFee = await _marketplaceContractABI.methods.getListingFee().call()
    listingFee = listingFee.toString()

    //console.log(listingFee)

    let _method = _marketplaceContractABI.methods.listNft(contract_address, token_id, web3.utils.toWei(price), Number(listing_type), expiry_days, reserved_address)
    let transactionParameters = {
      to: marketplaceContractAddress,
      from: provider.selectedAddress,
      value: web3.utils.toHex(listingFee)
    };

    const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    //console.log(estimatedGasAmount)
    transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)
    transactionParameters.data = _method.encodeABI()

    //console.log(transactionParameters);

    // await alchemy.core.call(transactionParameters).then(function(rec) {
    //   //console.log(rec)
    //   return {
    //     error: null,
    //     txHash: rec
    //   }
    // })

    return executeTransaction(transactionParameters)

    // const receipt = await web3.eth.sendTransaction(transactionParameters) 
    // //console.log(`Transaction hash: ${receipt.transactionHash}`);

    //   return {
    //   error: null,
    //   txHash: receipt.transactionHash
    // }



    // const txHash = await provider.request({
    //   method: 'eth_sendTransaction',
    //   params: [transactionParameters],
    // });

    // return {
    //   error: null,
    //   txHash: txHash
    // }
  } catch (err) {
    return {
      txHash: null,
      error: err.message
    };
  }
}

export async function monitorTxnSync(txHash) {
  return await new Promise(resolve => {
    // alchemy.core.getTransactionReceipt(txHash).then(function(rec) {
    //   //console.log(rec);
    //     if (rec) {
    //       resolve(rec);
    //     }
    // })

    web3.eth.getTransactionReceipt(txHash, function (err, rec) {
      if (rec) {
        resolve(rec);
      }
      // return rec
    });
  });
}


export const makeOffer = async (listing_id, price_in_wei, expiry_days) => {

  try {

    //console.log(provider.selectedAddress, marketplaceContractAddress, price_in_wei.toString())

    let _method = _marketplaceContractABI.methods.makeOffer(listing_id, expiry_days)
    let transactionParameters = {
      to: marketplaceContractAddress,
      from: provider.selectedAddress,
      value: web3.utils.toHex(price_in_wei.toString()),
      data: _method.encodeABI()

    };

    //console.log(transactionParameters);
    //console.log("estimating gas");
    const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    //console.log(estimatedGasAmount)
    transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)

    transactionParameters.value = web3.utils.toHex(price_in_wei)
    //console.log(transactionParameters);

    return executeTransaction(transactionParameters)

  } catch (err) {
    //console.log(err);
    return {
      error: err.message,
      txHash: null
    }
  }
}


export const claimNft = async (contract_address, listing_id, price_in_wei) => {

  try {

    let _method = _marketplaceContractABI.methods.claimNft(contract_address, listing_id)
    let transactionParameters = {
      to: marketplaceContractAddress,
      from: provider.selectedAddress,
      data: _method.encodeABI()
    };

    if (price_in_wei !== 0) {
      transactionParameters.value = web3.utils.toHex(price_in_wei)
    }

    const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)

    // transactionParameters.value = web3.utils.toHex(price_in_wei)

    return executeTransaction(transactionParameters)

  } catch (err) {
    //console.log(err);
    return {
      error: err.message,
      txHash: null
    }
  }
}


export const buyNft = async (contract_address, listing_id, price_in_wei) => {

  // //console.log(contract_address, token_id, web3.utils.fromWei(price));


  // const customContractABI = await new web3.eth.Contract(collectionContract.abi, contract_address);
  //load smart contract
  // const marketplaceContractABI = await new web3.eth.Contract(marketplaceContract.abi, marketplaceContractAddress);

  // const gasAmount = await getGasAmount(provider.selectedAddress, marketplaceContractAddress, price)

  // 
  // var hash = null
  // var receipt = null
  // var confirmationNumber = null
  // var error = null

  try {

    //console.log(provider.selectedAddress, marketplaceContractAddress, price_in_wei.toString())

    let _method = _marketplaceContractABI.methods.buyNft(contract_address, listing_id)
    let transactionParameters = {
      to: marketplaceContractAddress,
      from: provider.selectedAddress,
      value: web3.utils.toHex(price_in_wei.toString()),
      data: _method.encodeABI()

    };

    //console.log(transactionParameters);

    //console.log("estimating gas");
    const estimatedGasAmount = await _method.estimateGas(transactionParameters);
    //console.log(estimatedGasAmount)
    transactionParameters.gas = web3.utils.toHex(estimatedGasAmount)

    transactionParameters.value = web3.utils.toHex(price_in_wei)
    //console.log(transactionParameters);

    return executeTransaction(transactionParameters)
    // const txHash = await provider.request({
    //   method: 'eth_sendTransaction',
    //   params: [transactionParameters],
    // });

    // return {
    //   error: null,
    //   txHash: txHash
    // }

  } catch (err) {
    //console.log(err);
    return {
      error: err.message,
      txHash: null
    }
  }
}


export const resellNft = async (contract_address, token_id, price) => {

  // await customContractABI.setApprovalForAll(marketplaceContractAddress, true)

  //console.log(contract_address, token_id, price);
  // let listingFee = await marketPlaceContract.methods.getListingFee().call()

  //load smart contract

  var hash = null
  var receipt = null
  var confirmationNumber = null
  var error = null

  try {

    // //console.log("approving")
    // // try {

    // // } catch (e) {
    // //   //console.log(e);
    // //   // return 0;
    // // }
    // // const nftContract = await new web3.eth.Contract(collectionContract.abi, contract_address);
    // // //console.log(nftContract._address);
    // try {
    //   // const customContractABI = await new web3.eth.Contract(collectionContract.abi, contract_address);
    //   const collectionContractAbi = await new web3.eth.Contract(collectionContract.abi, contract_address);
    //   await collectionContractAbi.methods.setApprovalForAll(marketplaceContractAddress, true).send({ from: provider.selectedAddress });
    // } catch (e) {
    //   //console.log(e);
    //   return
    // }


    //console.log("resell")

    const marketplaceContractAbi = await new web3.eth.Contract(marketplaceContract.abi, marketplaceContractAddress);

    let listingFee = await marketplaceContract.methods.getListingFee().call()
    listingFee = listingFee.toString()
    //console.log(listingFee)

    return marketplaceContractAbi.methods.resellNft(contract_address, token_id, web3.utils.toWei(price)).send({
      // to: marketplaceContractAddress,
      from: provider.selectedAddress,
      value: listingFee,
      // value: web3.utils.toHex(web3.utils.toWei("0.0001", "ether"))
    })
      .on('transactionHash', function (hash) {
        //console.log(hash);
        // hash = hash
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        //console.log(confirmationNumber, receipt);
        // receipt = receipt
        // confirmationNumber = confirmationNumber
      })
      .on('receipt', function (receipt) {
        //console.log(receipt);
        // receipt = receipt
      })
      .on('error', function (error, receipt) {
        //console.log(error, receipt)
        error = error.message
      })
      .then(function (receipt) {
        return {
          status: false,
          confirmationNumber: confirmationNumber,
          hash: hash,
          receipt: receipt,
          error: error
        };
      });
  } catch (err) {
    return {
      status: false,
      confirmationNumber: confirmationNumber,
      hash: hash,
      receipt: receipt,
      error: err.message
    };
  }
}

