import { Container, Row, Col, Image, Button, ToggleButton, ToggleButtonGroup, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';

import { marketplaceContractAddress, web3 } from "./eth/eth";

import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import NFTCard from './components/NFTCard'
import { FormatAddress } from './Utils';
import Card from 'react-bootstrap/Card';

import ListGroup from 'react-bootstrap/ListGroup'
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import FeaturedCollections from './components/FeaturedCollections';
// import background from "./assets/randomshape.png";

import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { getData } from "./utils/api"
import { getAllNFTsByAddress, getAllNFTsByContract, getOwnersForCollection } from "./eth/eth"
import { useWalletContext } from './contexts/WalletContext';
import { Loading } from './components/UI'
import { GetIPFSURL } from './Utils';
import { useApiContext } from './contexts/ApiContext';


const Explore = (props) => {
    let wallet = useWalletContext();
    let api = useApiContext()

    const [value, setValue] = useState(["id"]);
    const [filter, setFilter] = useState(["all"]);


    const { address, skip, size, limit, sortBy } = props;
    const [loading, setLoading] = useState(true)

    const [collections, setCollections] = useState([])
    const [filteredCollections, setFilteredCollections] = useState([])


    const [owners, setOwners] = useState([])
    const [total, setTotal] = useState(0)
    const [pagekey, setPagekey] = useState(null)
    const [featuredImage, setFeaturedImage] = useState(null)


    const [isContract, setIsContract] = useState(null)


    useEffect(() => {
        if (sortBy !== null) {
            sortCollection(sortBy)
        }
    }, [sortBy]);

    function getOwners(nft) {
        let _temp = owners.filter(function (own_nft) {
            if (web3.utils.toChecksumAddress(own_nft.ownerAddress) === web3.utils.toChecksumAddress(wallet.wallet.address)) {
                const found = own_nft.tokenBalances.find(
                    el => web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.tokenId))
                )
                if (found) {
                    return true;
                }
            }
        });

        let ownersArr = []
        if (_temp.length > 0) {
            ownersArr.push(_temp[0].ownerAddress)
        }


        return ownersArr


        // api.api.listed_nfts.some(el => (web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.metadata.id.tokenId)) && web3.utils.toChecksumAddress(el.nftContract) === web3.utils.toChecksumAddress(nft.metadata.contract.address)));
        // })

        // let tokenBalances = []
        // for (let i = 0; i < owners.length; i++) {
        //     if (web3.utils.toChecksumAddress(owners[i].ownerAddress) === web3.utils.toChecksumAddress(wallet.wallet.address)) {
        //         tokenBalances = owners[i].tokenBalances
        //         break
        //     }
        // }

    }

    function filterCollection(val) {
        setFilter(val);
        let _collections = collections;
        if (val == "listed") {
            _collections = _collections.filter(function (nft) {
                if (isContract) {
                    return api.api.listed_nfts.some(el => (web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.metadata.tokenId)) && web3.utils.toChecksumAddress(el.nftContract) === web3.utils.toChecksumAddress(nft.metadata.contract.address)));
                }
                return api.api.listed_nfts.some(el => (web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.metadata.id.tokenId)) && web3.utils.toChecksumAddress(el.nftContract) === web3.utils.toChecksumAddress(nft.metadata.contract.address)));
            })
        }

        if (val == "owned") {
            let tokenBalances = []
            for (let i = 0; i < owners.length; i++) {
                if (web3.utils.toChecksumAddress(owners[i].ownerAddress) === web3.utils.toChecksumAddress(wallet.wallet.address)) {
                    tokenBalances = owners[i].tokenBalances
                    break
                }
            }

            _collections = _collections.filter(function (nft) {
                return tokenBalances.some(el => web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.metadata.tokenId)))
            })
        }

        if (val == "my") {
            _collections = _collections.filter(function (nft) {
                return api.api.listed_nfts.some(el => (web3.utils.hexToNumberString(Number(el.tokenId)) === web3.utils.hexToNumberString(Number(nft.metadata.tokenId)) && web3.utils.toChecksumAddress(el.nftContract) === web3.utils.toChecksumAddress(nft.metadata.contract.address) && web3.utils.toChecksumAddress(el.seller) === web3.utils.toChecksumAddress(wallet.wallet.address)));
            })
        }

        setFilteredCollections(_collections)

    }

    function sortCollection(val) {
        setValue(val);
        let _collections = filteredCollections;
        if (val == "date") {
            _collections.sort((a, b) => (a.timeLastUpdated < b.timeLastUpdated) ? 1 : -1)
        }
        if (val == "title") {
            _collections.sort((a, b) => (a.title !== undefined && b.title !== undefined && a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
        }

        if (val == "id") {
            _collections.sort((a, b) => (Number(a.tokenId) > Number(b.tokenId)) ? 1 : -1)
        }
        // web3.utils.hexToNumberString(id.tokenId)
        // setCollections(_collections)
        setFilteredCollections(_collections)
    }


    async function loadMoreData() {
        let nfts = []
        // if (!isContract) {
        //     let api_response = await getData(`collections/${address}`)
        //     // console.log(favorites_response.data)
        //     if(api_response.data.nfts) {
        //         nfts = api_response.data.nfts
        //         setTotal(api_response.data.nfts.length || 0)
        //     }

        //     // const data = await getAllNFTsByAddress(address, limit || 50, pagekey)
        //     // nfts = data.ownedNfts
        //     // setTotal(data.totalCount || data.nfts.length);
        //     // setPagekey(data.pageKey);
        // } else {
        let api_response = await getData(`collections/${address}`)
        // console.log(favorites_response.data)
        if (api_response.data.nfts) {
            nfts = api_response.data.nfts
            setTotal(api_response.data.nfts.length || 0)
            setOwners(api_response.data.owners.ownerAddresses)
        }

        // const data = await getAllNFTsByContract(address, limit || 50, pagekey)
        // nfts = data.nfts
        // setTotal(data.totalCount || data.nfts.length);
        // setPagekey(data.pageKey);
        // }

        if (limit !== undefined && nfts.length > limit) {
            nfts = nfts.slice(0, limit);
        }


        const newData = [...collections, ...nfts];


        setCollections(newData);
        setFilteredCollections(newData)
        setLoading(false)
    }

    useEffect(() => {
        if (collections.length > 0 && size != "sm") {
            let nft_details = collections[0]
            console.log("featured image", nft_details)
            if (nft_details.media.length > 0) {
                if (nft_details.media[0].gateway !== "") {
                    setFeaturedImage(nft_details.media[0].gateway)
                } else if (nft_details.media[0].raw !== "") {
                    setFeaturedImage(nft_details.media[0].raw)
                }
            } else {
                // setFeaturedImage(background)
            }
        } else {
            // setFeaturedImage(background)
        }
    }, [collections, size]);

    useEffect(() => {
        setLoading(true)
        setCollections([])
        setFilteredCollections([])
        async function _get() {
            if (isContract !== null) {
                // const owners = await getOwnersForCollection(address)
                // console.log(owners);
                // setOwners(owners.ownerAddresses);
                setCollections([])
                setFilteredCollections([])

                loadMoreData()
            }
        }
        _get()
    }, [address, isContract]);

    useEffect(() => {
        setCollections([])
        setFilteredCollections([])
    }, []);


    useEffect(() => {
        if (address !== null && address !== undefined) {
            // THIS PROVIDES EITHER IF THE ADDRESS IS CONTRACT OR WALLET
            // WALLET WILL RETURN RESULT AS 0x
            web3.eth.getCode(address, function (error, result) {
                if (result === "0x" || address === marketplaceContractAddress) {
                    setIsContract(false)
                } else {
                    setIsContract(true)
                }
            });
        }
    }, [address]);

    // async function loadMoreData() {
    //     const data = await getAllNFTsByContract(contract_address, limit || 50, pagekey)
    //     const newData = [...nfts, ...data.nfts];        
    //     setNfts(newData);
    //     setTotal(data.totalCount);
    //     setPagekey(data.pageKey);
    //   }



    // useEffect(() => {
    //     const _get = async (contract_address) => {
    //         const data = await getAllNFTsByContract(contract_address, limit || 50)
    //         console.log(data);
    //         setCollections(data.nfts)
    //         setTotal(data.totalCount || data.nfts.length);
    //         setPagekey(data.pageKey);

    //         // const data = await getData(`provider/contract/${contract_address}/nfts`)
    //         // console.log(data.data.nfts)
    //         // setNfts(data.data.nfts);
    //     };

    //     if (props.contract_address !== null && props.contract_address !== undefined) {
    //         setFeatured(false)
    //         console.log("GETTING CONTRACT DATA ", props.contract_address)
    //         _get(props.contract_address);
    //     }



    // }, [props.contract_address]);

    // useEffect(() => {
    //     function getDistinctContracts() {
    //         const uniqueContracts = [];
    //         const uniqueContractsMetadata = [];

    //         collections.map(collection => {
    //             if (uniqueContracts.indexOf(collection.contract.address) === -1) {
    //                 uniqueContracts.push(collection.contract.address)
    //                 uniqueContractsMetadata.push({
    //                     address: collection.contract.address,
    //                     metadata: collection.contractMetadata
    //                 })
    //             }
    //         });

    //         setContracts(uniqueContractsMetadata)
    //     }
    //     if (collections != null) {
    //         getDistinctContracts();
    //     }
    // }, [collections]);



    return (

        <>
            {/* <div className="bg-image" style={{ backgroundImage: `url(${featuredImage})` }}></div> */}

            {/* {featuredImage ? <Image url={featuredImage} background="bg" title="featured" /> : ""} */}


            {loading ?
                <Loading className="mt-2" title="Loading Data ..." />
                :
                <div>
                    <Row className="mb-3 mt-2">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            {size !== "sm" ?
                                <div>

                                    <Row>
                                        <Col>
                                            <h6 className="pt-2">Showing {filteredCollections.length} of {total} Items
                                                {isContract ? <span>&nbsp; | {owners.length} Owners</span> : ""}
                                            </h6>
                                        </Col>
                                        <Col className="">
                                            <DropdownButton
                                                align="end" 
                                                as={ButtonGroup}
                                                title={<Icon.Filter />}
                                                variant="secondary"
                                                id="bg-vertical-dropdown-1 "
                                                className="float-end ms-1"
                                                onSelect={(e) => sortCollection(e)}
                                            >
                                                <Dropdown.Item eventKey="title"><Icon.Icon123 size="18" /> Title</Dropdown.Item>
                                                <Dropdown.Item eventKey="date"><Icon.Calendar size="18" /> Date</Dropdown.Item>
                                                <Dropdown.Item eventKey="id"><Icon.SortAlphaDown size="18" /> ID</Dropdown.Item>
                                            </DropdownButton>

                                            <DropdownButton
                                                align="end" 
                                                as={ButtonGroup}
                                                title={<Icon.Funnel />}
                                                id="bg-vertical-dropdown-1"
                                                variant="secondary"
                                                className="float-end"
                                                onSelect={(e) => filterCollection(e)}
                                            >
                                                <Dropdown.Item eventKey="all">All</Dropdown.Item>
                                                <Dropdown.Item eventKey="listed">Listed</Dropdown.Item>
                                                {!isContract ? "" : <Dropdown.Item eventKey="owned">Owned</Dropdown.Item>}
                                                {address === marketplaceContractAddress ? <Dropdown.Item eventKey="my">My Listings</Dropdown.Item> : ""}
                                            </DropdownButton>

                                        </Col>

                                    </Row>
                                </div>
                                : ""}
                            {!loading && collections.length == 0 ? <Alert className="mt-3" variant="warning">You have not created or owned any NFTs so far. But fret not and we are here to help!</Alert> : ""}
                        </Col>
                    </Row>
                    <Row>
                        {filteredCollections.map((collection, idx) => {
                            return (

                                (collection.metadata.id && web3.utils.hexToNumber(collection.metadata.id.tokenId) != skip) ||
                                    (collection.metadata.tokenId && collection.metadata.tokenId != skip)
                                    ?
                                    (
                                        size == "sm" ?
                                            <Col key={idx} xl={2} lg={3} md={3} sm={6} xs={6}>
                                                <NFTCard showTitle={false} nft={collection.metadata} media={collection.media} />
                                            </Col> :
                                            <Col key={idx} xl={4} lg={4} md={6} sm={12} xs={12}>

                                                {/* <pre>{JSON.stringify(collection, null, 2)}</pre> */}
                                                <NFTCard showTitle={true} nft={collection.metadata} media={collection.media} owners={getOwners(collection.metadata)} /></Col>

                                    ) : "");
                        })}

                    </Row>
                    {size !== "sm" ?
                        <Row>
                            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="text-center">
                                {pagekey ? <Button className="mt-3" variant="primary" onClick={() => loadMoreData()}>Load More</Button> : ""}
                            </Col>
                        </Row> : ""}
                </div>
            }

        </>
    );
};



export default Explore;
