import * as Icon from 'react-bootstrap-icons';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { web3 } from "./eth/eth";
import { connectWallet, getWalletBalance, switchChain } from './eth/eth'
import { getData, postData } from "./utils/api"
import { useWalletContext, SET, ERROR } from './contexts/WalletContext';
import { useToastContext, ADD } from './contexts/ToastContext';
import { Loading } from './components/UI'
import { useLearnContext, SHOW } from './contexts/LearnContext';

const Web3 = require('web3');


require('dotenv').config();

const ConnectWallet = (props) => {
    const { learnDispatch } = useLearnContext();

    const { walletDispatch } = useWalletContext();
    const { toastDispatch } = useToastContext();

    // const WalletConnectProvider = window.WalletConnectProvider.default;
    // console.log("WalletConnectProvider is", window.WalletConnectProvider);
      const navigate = useNavigate();

    const { locked } = props;

    // const [walletAddress, setWalletAddresss] = useState(null);
    // const [balance, setBalance] = useState("");
    // const [network, setNetwork] = useState(null);
    // const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    // const [ethEnabled, setEthEnabled] = useState(null)

    const [name, setName] = useState("");

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);


    const target = useRef(null);


    var [drop, setDrop] = useState(false)

    function navigateTo(location) {
        setDrop(false);
        navigate(location)
    }

    // useEffect(() => {
    //     const contractABI = require('./MyNFT.json')
    //     const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    //     const helloWorldContract = new web3.eth.Contract(
    //         contractABI,
    //         contractAddress
    //     );

    //     function addSmartContractListener() {

    //         // {address: '0x875B820f28dBe12b74BEF8337d801A983B927BB7', blockNumber: 7519720, transactionHash: '0x42c88ea4ccd4482eba02bd8294b29dc22dc3ea3db48dcd89bc89df76780f91ec', transactionIndex: 5, blockHash: '0x5e8a7f64d279e5d0b58a61ddd4b57b1e5b4c703985ff5d61bdcf98cb513f7e8f', …}
    //         // address: "0x875B820f28dBe12b74BEF8337d801A983B927BB7"
    //         // blockHash: "0x5e8a7f64d279e5d0b58a61ddd4b57b1e5b4c703985ff5d61bdcf98cb513f7e8f"
    //         // blockNumber: 7519720
    //         // event: "NFTListedNew"
    //         // id: "log_85fc6935"
    //         // logIndex: 11
    //         // raw:
    //         // data: "0x000000000000000000000000875b820f28dbe12b74bef8337d801a983b927bb70000000000000000000000000000000000000000000000000000000000000001000000000000000000000000747a04b14ced1057e260bac5993d2a81f830f2f5000000000000000000000000875b820f28dbe12b74bef8337d801a983b927bb70000000000000000000000000000000000000000000000000000000077359400"
    //         // topics: ['0x82936fdc247a851c57042d02c70980f6674bf7721673bc54093e2af30340a1ff']
    //         // [[Prototype]]: Object
    //         // removed: false
    //         // returnValues: Result
    //         // 0: "0x875B820f28dBe12b74BEF8337d801A983B927BB7"
    //         // 1: "1"
    //         // 2: "0x747A04B14CEd1057E260Bac5993D2A81F830F2F5"
    //         // 3: "0x875B820f28dBe12b74BEF8337d801A983B927BB7"
    //         // 4: "2000000000"
    //         // from: "0x747A04B14CEd1057E260Bac5993D2A81F830F2F5"
    //         // to: "0x875B820f28dBe12b74BEF8337d801A983B927BB7"
    //         // _nftContract: "0x875B820f28dBe12b74BEF8337d801A983B927BB7"
    //         // _price: "2000000000"
    //         // _tokenId: "1"
    //         // [[Prototype]]: Object
    //         // signature: "0x82936fdc247a851c57042d02c70980f6674bf7721673bc54093e2af30340a1ff"
    //         // transactionHash: "0x42c88ea4ccd4482eba02bd8294b29dc22dc3ea3db48dcd89bc89df76780f91ec"
    //         // transactionIndex: 5
    //         // [[Prototype]]: Object
    //         helloWorldContract.events.NFTListedNew({}, (error, data) => {
    //             if (error) {
    //                 console.log("error", error)
    //             } else {
    //                 console.log(data);
    //             }
    //         });
    //     }
    //     addSmartContractListener();
    // }, []);



    const loginWithEthereum = (walletResponse) => {
        fetchNonce(walletResponse) // The signature starts by fetching the nonce
    }

    const fetchNonce = async (walletResponse) => {
        var request = {}
        request["wallet_address"] = walletResponse.address
        request["chain_id"] = walletResponse.chain

        const { status, data } = await postData(`nonce`, request)
        console.log(status, data)
        if (!status) {
            toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: data } } })
            return
        }

        // console.log(data)
        createSignature(walletResponse, data.nonce)

        // axios.post("/api/nonce", request, { headers: {} })
        //     .then(function (response) {
        //         console.log(response);
        //         createSignature(address, response.data.nonce)
        //     })
        //     .catch(function (error) {
        //         console.log(error)
        //         setError(error.message);
        //     });
    }

    const createMessage = (walletResponse, nonce) => {
        console.log(walletResponse);
        // Substitute this message with the parameters you need for the EIP-4361 standard
        // Message:
        // Welcome to OpenSea!

        // Click to sign in and accept the OpenSea Terms of Service: https://opensea.io/tos

        // This request will not trigger a blockchain transaction or cost any gas fees.

        // Your authentication status will reset after 24 hours.

        // Wallet address:
        // 0x747a04b14ced1057e260bac5993d2a81f830f2f5

        // Nonce:
        // 1d032011-0558-4933-9525-f0499e59be70

        return (
            "Welcome to NFT Jugaad! Click to sign in and accept the NFT Jugaad Terms of Service:\n" +
            "This request will not trigger a blockchain transaction or cost any gas fees.\n\n" +
            "Wallet Address\n" + walletResponse.address + "\n" +
            "\n" +
            "URI: https://nftjugaad.app\n" +
            "Version: 1\n" +
            "Nonce:" + nonce + "\n" +
            "Network: " + walletResponse.network + "\n"
            // "Issued At: " + issuedAt + "\n" +
            // "Expiration Time: " + expiresAt + "\n"
        );
    }


    const createSignature = async (walletResponse, nonce) => {
        var message = createMessage(walletResponse, nonce);
        // message = "hello";

        var hashedMessage = web3.utils.sha3(message);
        // sign hashed message
        try {
            const signature = await window.ethereum.request({
                method: "personal_sign",
                params: [message, walletResponse.address],
            });
            // var signature = Signature.signature.substr(2); 
            // console.log({ signature });

            // split signature
            const r = signature.slice(0, 66);
            const s = "0x" + signature.slice(66, 130);
            const v = parseInt(signature.slice(130, 132), 16);
            // console.log({ r, s, v });


            var request = {}
            request["signature"] = signature
            request["wallet_address"] = walletResponse.address
            request["message"] = message
            request["hashed_message"] = hashedMessage
            request["chain_id"] = walletResponse.chain

            const { status, data } = await postData(`verify`, request)
            console.log(status, data )
            if (!status) {
                // console.log(data)

                toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: data } } })
                return
            }

            if (data.valid) {
                localStorage.setItem('authorization', data.authorization)
                // initWallet(walletResponse);
                loadApp(walletResponse)
            }

            else {
                walletDispatch({ type: ERROR, payload: { "error": "Error Verifying Signature"} })
                return
            }
            // axios
            //     .post("/api/verify", request, {
            //         headers: {

            //         }
            //     })
            //     .then(function (response) {
            //         console.log(response);
            //         if (response.data.valid) {
            //             initWallet(address);
            //             // setWalletAddresss(address);
            //             // props.appendData({ wallet_address: address })
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error)
            //         setError(error.message);
            //     });
        } catch (err) {
            walletDispatch({ type: ERROR, payload: { "error": err.message} })
        }
    }



    const initWallet = async (walletResponse) => {
        localStorage.setItem('isWalletConnected', "true")
        console.log("INITIALIZING WALLET", walletResponse.address)
        // setWalletAddresss(address);


        const _balance = await getWalletBalance(walletResponse.address, walletResponse.network)
        walletResponse["balance"] = _balance
        
        // props.onWalletConnected(walletResponse.address);
        walletDispatch({ type: SET, payload: walletResponse })

        addWalletListener();


        // const nweb3 = new Web3('https://damp-hidden-choice.ethereum-goerli.discover.quiknode.pro/475a03c2e6e6065eebab285378feea8b2aa2377c/');
        // nweb3.eth.getBlock('latest').then(answer => console.log(answer))
        // nweb3.eth.getBlockNumber().then(blockNum => console.log(blockNum))
        

        // props.appendData({ wallet_address: walletResponse.address })

        // const txns = await getAssetTransfers(walletResponse.address)
        // console.log(txns)

        
        // const interval = setInterval(async () => {
        // fetchMe(props)
        //       clearInterval(interval);
        //   }, 10000);

    }

    const loadApp = async (walletResponse) => {
        const { status, data } = await getData(`wallet/${walletResponse.address}?chain=${walletResponse.chain}`)

        if (!status) {
            console.log(data)
            loginWithEthereum(walletResponse)
            return
        }

        if(data.Verified === false) {
            loginWithEthereum(walletResponse)
            return
        }


        walletResponse.display_name = data.displayName
        walletResponse.avatar = data.avatar 

        initWallet(walletResponse);
    }



    const toggleDrop = () => {
        // console.log(drop)
        drop = !drop
        // console.log(drop)
        setDrop(drop)
    }

    const connectWalletPressed = async () => {

        // const provider = new ethers.providers.Web3Provider(window.ethereum)
        // const accounts = await provider.send("eth_requestAccounts", []);
        // console.log(provider, accounts);


        // if(!window.ethereum) {
        //     toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: `You dont' have metamask installed or are runnign in incognito` } } })
        //     walletDispatch({ type: ERROR, payload: { "error": "No Metamask Plugin found"} })
        // }

        const walletResponse = await connectWallet(name);
        // console.log("walletResponse", walletResponse);
        if (walletResponse.error) {
            // alert(walletResponse.error)
            // setError(walletResponse.error);
            
            if(walletResponse.error === "NO_METAMASK") {
                // setEthEnabled(false)   
                learnDispatch({ type: SHOW, payload: { module: "welcome", expand: true } })
                toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: "No compatible wallet browser plugin installed!" } } })
            } else {
                toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: walletResponse.error } } })
                // setEthEnabled(true)
            }
            walletDispatch({ type: SET, payload: {} })
            return
        }

        

        if (walletResponse.address !== "" && walletResponse.address !== null) {
            if (!walletResponse.isSupported) {
                toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: `<b className="pointer" onClick=${switchChain(walletResponse.chain, localStorage.getItem("chain"))}>You're viewing data from the ${walletResponse.mareketplaceNetwork} network, but your wallet is connected to the ${walletResponse.network}. Please switch your wallet connected network.</b>` } } })
            }

            

            if (String(walletResponse.chain) !== localStorage.getItem("chain")) {
                // toastDispatch({ type: ADD, payload: { content: { variant: 'warning', message: `You're viewing data from the ${walletResponse.mareketplaceNetwork} network, but your wallet is connected to the ${walletResponse.network}. Please switch your wallet connected network.` } } })
                
                await switchChain(walletResponse.chain, localStorage.getItem("chain"))
                return connectWalletPressed()

                
                
            }          
            
            // setNetwork(walletResponse.network);
            loadApp(walletResponse);
        }
    };


    function addWalletListener() {
        if (window.ethereum) {
            const provider = window.ethereum
            // isCoinbaseWallet
            // .providers.find((provider) => provider.isMetaMask);
            provider.on('accountsChanged', (x) => {
                console.log("event accountsChanged:" + x)
                // loadApp(x[0]);
                window.location.reload();
            });

            // provider.on("accountsChanged", (accounts) => {
            //     if (accounts.length > 0) {
            //         localStorage.setItem('isWalletConnected', "true")

            //         props.appendData({ wallet_address: accounts[0] })
            //         // fetchMe(props)




            //     } else {
            //         console.log("Account disconnected");
            //         localStorage.removeItem('isWalletConnected')
            //         // setWallet("");
            //         // setStatus("false");
            //         window.location.reload();
            //     }
            // });

            provider.on('connect', (x) => {
                console.log("event connect" + JSON.stringify(x))               
            });

            provider.on('disconnect', (x) => {
                console.log("event disconnect" + x)
                localStorage.removeItem('isWalletConnected')
            });


            provider.on('message', (x) => {
                console.log("event message" + JSON.stringify(x))
            });

            //catch chainChanged
            provider.on('chainChanged', (chainId) => {
                console.log("chainChanged:" + chainId);
                window.location.reload();
            });
        }
    }


    let wallet = useWalletContext();


    useEffect(() => {
        console.log(wallet)
        // walletDispatch({ type: SET, payload: {
        //     address: null,
        //     chain: null,
        //     status: false,
        //     network: null,
        //     isSupported: false,
        //     error: ""
        //   }  })
               
        if (locked) {
            connectWalletPressed();
        }
    }, [locked]);

    useEffect(() => {
        console.log(name)
        if(name !== "") {
            connectWalletPressed()
        }
    }, [name]);    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  
    return (
    <>
                
        
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
        <Modal.Title>Wallet</Modal.Title>
      </Modal.Header>

        <Modal.Body>
        <p>Select a Wallet provider you like to use</p>
        <Button className="w-100 mb-1" onClick={(e) => setName("metamask")}>Metamask</Button>
        <Button className="w-100 mb-1" onClick={(e) => setName("coinbase")}>Coinbase</Button>
      </Modal.Body>
    </Modal>


        {!locked ? 
            // <Button variant="primary" className=" pointer" onClick={connectWalletPressed}>
            <Button variant="primary" className=" pointer" onClick={(e) => setShow(true)}>
                <Icon.Wallet2 className="d-inline" /> 
                <span className="bold ms-1 d-none d-lg-inline">Connect Wallet</span>
            </Button>
        : 
        <Container><Row><Col className="p-5 mt-5">
            <Loading title="Connecting to Wallet ..." />
        </Col></Row>
        </Container>
        }
        
    </>);
}


export default ConnectWallet;
