import { Spinner, Button } from 'react-bootstrap';
import { useLearnContext, SHOW } from '../contexts/LearnContext';
import * as Icon from 'react-bootstrap-icons';

export const Loading = (props) => {
    const { title } = props;

    return (
        <div className="text-center">
            <h6>
                <Spinner size="sm" className="me-2" animation="grow" variant="light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> {title}</h6>
        </div>
    )
}

export const LearnModeBtn = (props) => {
    const { learnDispatch } = useLearnContext();
    const { module } = props;

    return (
      <span  className="pointer " onClick={() => learnDispatch({ type: SHOW, payload: { module: module, expand: true } })} style={{ position: "absolute",  top: "10px", right: "10px"}}><Icon.QuestionCircleFill style={{color: "var(--blue)"}} size="24" /></span>
    );

}

