import { useNavigate } from "react-router-dom";
import { Container, Row, Col, ListGroup, ListGroupItem, Card } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import * as Icon from 'react-bootstrap-icons';
// import background from "./assets/randomshape.png";

import { useToastContext, ADD } from './contexts/ToastContext';

export const sections = [
  {
    title: "Welcome",
    pages: [
      {
        title: "Introduction",
        description: "As part of this learning module, we have tried to provide a brief introduction to various tools and technologies associated with an NFT marketplace. Hopefully that will help you navigate your experience and be a more smarter person",
        page: "welcome"
      }
    ]
  },
  {
    title: "Blockchain and Ethereum",
    pages: [
      {
        title: "Blockchain",
        description: "All about blockchain and networks",
        page: "blockchain"
      },
      {
        title: "dAPP",
        description: "What is a dApp?",
        page: "dapp"
      },
      {
        title: "web3",
        description: "What is a Web3?",
        page: "web3"
      }
    ]
  },
  {
    title: "Developing on Ethereum",
    pages: [
      {
        title: "EVM and Smart Contracts",
        description: "Learn all about EVM and Smart Contracts",
        page: "smartcontract"
      },
      {
        title: "Smart Contracts",
        description: "Smart Contracts with hands on lab to deploy your own contract",
        page: "solidity"
      },
      {
        title: "Wallet",
        description: "Install, Setup and Fund",
        page: "wallets"
      },
      {
        title: "Deploying Smart Contracts",
        description: "Smart Contracts with hands on lab to deploy your own contract",
        page: "solidity_contd"
      },
      {
        title: "NFTs",
        description: "Learn about NFT, create your smart contract and mint your own very first token",
        page: "nft"
      },
      {
        title: "Collection",
        page: "nft_collection"
      },      
      {
        title: "ERC20 Tokens",
        description: "Learn about NFT, create your smart contract and mint your own very first token",
        page: "erc20"
      },      
      {
        title: "Airdrop",
        description: "An airdrop is a distribution of NFTs, tokens or cryptocurrency that can be sent in either an unsolicited or solicited manner to web3 wallets. They are commonly used for both promotional and loyalty purposes.",
        page: "airdrop"
      }
    ]
  },
  {
    title: "NFT Marketplace",
    pages: [
      {
        title: "Overview",
        description: "Overview of NFT marketplace",
        page: "nft_marketplace"
      },
      {
        title: "Authentication",
        description: "Learn how authentication works in NFT marketplace",
        page: "nft_marketplace_authentication"
      },
      {
        title: "Collection",
        description: "Learn more about creating your own NFT Colleciton",
        page: "nft_marketplace_collection"
      },
      {
        title: "Mint",
        description: "Learn more about miniting your NFT on the collection",
        page: "nft_marketplace_mint"
      },
      {
        title: "Sell NFT",
        description: "Learn more selling NFT works on the Marketplace",
        page: "nft_marketplace_sell"
      },
      {
        title: "Buy NFT",
        description: "Learn more about buying process works on the Marketplace",
        page: "nft_marketplace_buy"
      },
      {
        title: "DeList NFT",
        description: "Learn more about buying process works on the Marketplace",
        page: "nft_marketplace_delist"
      }
    ]
  },
  {
    title: "More about Tokens",
    pages: [
      {
        title: "Swapping",
        page: "swapping"
      },
      {
        title: "Staking",
        page: "staking"
      }
    ]
  },
  {
    title: "Security",
    pages: [
      {
        title: "Security",
        description: "Known Hacks, Security Gaps etc.",
        page: "security"
      },
      {
        title: "Links",
        description: "A collection of interesting links and reading materials",
        page: "links"
      }
    ]
  },
];

const Learn = (props) => {
  const { toastDispatch } = useToastContext();

  let { module } = useParams();
  const navigate = useNavigate();
  let [content, setContent] = useState("");
  let [section, setSection] = useState(null);
  let [page, setPage] = useState(null);


  useEffect(() => {

    for (let i = 0; i < sections.length; i++) {
      // let pages = sections[i].pages
      for (let j = 0; j < sections[i].pages.length; j++) {
        // console.log(sections[i].pages[j])
        if (sections[i].pages[j].page === module) {
          setPage(sections[i].pages[j])
          setSection(sections[i])
          document.title = sections[i].title + " | " + sections[i].pages[j].title + " | NFT Jugaad";
          break
        }
      }


    }

    async function _getData() {


      // toastDispatch({ type: ADD, payload: { content: { variant: "danger", message: "This is a test message" } } })
      // toastDispatch({ type: ADD, payload: { content: { variant: "info", message: "As part of this learning module, we have tried to provide a brief introduction to various tools and technologies associated with an NFT marketplace. Hopefully that will help you navigate your experience and be a more smarter person" } } })

      // toastDispatch({ type: ADD, payload: { content: { variant: "warning", message: "We are so glad you are here! May be you are someone who wants to buy/sell NFTs, or someone who wants to build their own marketplace or a lifelong learner, this place is for you." } } })

      // toastDispatch({ type: ADD, payload: { content: { variant: "success", message: "We are so glad you are here! May be you are someone who wants to buy/sell NFTs, or someone who wants to build their own marketplace or a lifelong learner, this place is for you." } } })



      let _module = module
      if (module === undefined) {
        _module = "welcome"
      }

      const readmePath = await import("./assets/docs/" + _module + ".md");
      fetch(readmePath.default)
        .then((res) => res.text())
        .then((md) => {
          setContent(md)
        })
    }
    _getData()
  }, [module])


  function Sections({ section }) {
    return (
      <ListGroup className="">
        {section.pages.map((page, idx1) => {
          return <ListGroupItem key={idx1} className={module === page.page ? "active" : "pointer"} onClick={() => navigate("/learn/" + page.page)}>
            <div className="row">
              <div className="col-2" style={{ width: '15px' }} href="#">
                <Icon.Files />
              </div>
              <div className="col-10">
                <b>{page.title}</b>
                {page.description ? <div>{page.description}</div> : ""}
              </div>
            </div>
          </ListGroupItem>
        })}
      </ListGroup>
    );
  }


  return (
    <>
      {section !== null ?
        <Container>
          <Row>
            <Col>
              <div className="m-3 mb-4">
                <b className="ps-1">{section.title} <Icon.ChevronRight /></b>
                <h1 className="m-0 p-0">
                  {page.title}
                </h1>
                <p>{page.description}</p>

              </div>

            </Col>
          </Row>
        </Container>
        : ""}
      <Container className="main">
        <Row>

          <Col className="d-none  d-md-inline mt-3" sm={12} md={3} xl={3}>
          {sections.map((section, idx1) => {
            return (
              <div key={idx1}>
                <ListGroup className="top mt-3" >
                  <ListGroupItem><h6 >{section.title}</h6></ListGroupItem>
                  {section.pages.map((page, idx2) => {
                    return <ListGroupItem className={module === page.page ? "active" : "pointer"} key={idx2} onClick={() => navigate("/learn/" + page.page)}>
                      <div className="row">
                        <div className="col-2" style={{ width: '15px' }} href="#">
                          <Icon.File />
                        </div>
                        <div className="col-10">
                          <b>{page.title}</b>
                          {/* {page.description ? <div>{page.description}</div> : ""} */}
                        </div>
                      </div>
                    </ListGroupItem>
                  })}
                </ListGroup>
              </div>
            )
          })}
        </Col>
          
          <Col sm={12} md={9} xl={9}>
            <div className="markdown bg-black h-100 rounded-3 p-5">
              <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
            </div>
          </Col>

          {section !== null && section.pages.length > 1 ?
            <Col className="d-inline d-md-none mt-3" sm={12} md={3} xl={3}>
              <Sections section={section} />
            </Col>
            : ""}

        </Row>

      </Container>


    </>
  );
};

export default Learn;