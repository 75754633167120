import placeholder from "../assets/placeholder-square-dark.jpeg";
require('dotenv').config();



const axios = require('axios');

// const url = `https://83ga63o4sj.execute-api.us-east-1.amazonaws.com/dev/api/nfts`;
// const url = `/api`;
// const url = `http://localhost:8000/api`;
const url = `https://nftjugaad.app/api`;




// export const getENS = async(address) => {  

//     var url = "https://api.ensideas.com/ens/resolve/" + address

//     return axios.get(`${address}`, null, { headers: { "Content-Type": "application/json" }}).then(function (response) {
//         return response.data 
//     }).catch(function (error) {
//         console.log(error)
//         return null
//     })
// }

export const getImage = async(ipfs_url) => {  

    // var parts = ipfs_url.split('/');
    // var ipfs_hash = parts.pop() || parts.pop();  // handle potential trailing slash
    // console.log(ipfs_url)
    // console.log(ipfs_hash)
    // var image_url = url + "/ipfs/" + ipfs_hash
    // console.log(ipfs_url)

    var ipfs_hash = ipfs_url
    var pattern = /^((http|https|ftp):\/\/)/;
    if(pattern.test(ipfs_url)) {
        let result = ipfs_url.indexOf("/ipfs/");
        ipfs_hash = ipfs_url.substring(result+6);
    } 
    // console.log(ipfs_hash)

    if(ipfs_hash == null || ipfs_hash == "") {
        return placeholder;
    }

    // return "https://ipfs.io/ipfs/" + ipfs_hash

    var image_url = url + "/ipfs/image?file=" + ipfs_hash
    // console.log(image_url)

    return axios.get(`${image_url}`, null, { headers: { "Content-Type": "application/json" }}).then(function (response) {
        // return "data:image/png;base64, " + response.data.body         
        // console.log(response);
        return response.data.url 
    }).catch(function (error) {
        // console.log(error)
        return ipfs_url
    })
}

export const getData = async(parts) => {  
    const authToken = localStorage.getItem("authorization")
    const chain = localStorage.getItem("chain")

    return axios 
        .get(`${url}/${parts}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken,
                "chain": chain
            }
        })
        .then(function (response) {
           return {
                status: true,
                data: response.data || {}
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};

export const postData = async(parts, request) => {  
    const authToken = localStorage.getItem("authorization")
    const chain = localStorage.getItem("chain")

    return axios 
        .post(`${url}/${parts}`, request, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken,
                "chain": chain
            }
        })
        .then(function (response) {
           return {
                status: true,
                data: response.data || {}
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};



export const getAllCollections = async(wallet_address) => {  
    return axios 
        .get(`${url}/collections`, null, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function (response) {
           return {
                status: true,
                data: response.data.data || []
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};

export const getCollections = async(wallet_address) => {  
    return axios 
        .get(`${url}/wallet/${wallet_address}/collections`, null, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function (response) {
           return {
                status: true,
                data: response.data.data || []
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};

export const createCollection = async(request) => {  
    return axios 
        .post(`${url}/wallet/${request.wallet_address}/collections`, request, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(function (response) {
           return {
                status: true,
                data: response.data.data || {}
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};


export const getItems = async() => {  
    return axios 
        .get(url, null, {
            headers: {
                
            }
        })
        .then(function (response) {
           return {
                status: true,
               data: response.data.data || []
           };
        })
        .catch(function (error) {
            console.log(error)
            
            return {
                status: false,
                data: error.message,
            }

    });
};


export const addItem = async(request) => {  
    return axios 
        .post(url, request, {
            headers: {
                
            }
        })
        .then(function (response) {
           return {
               status: true,
               data: response.data.data
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                status: false,
                data: error.message,
            }

    });
};

export const deleteItem = async(request) => {  
    return axios 
        .delete(url, { data: request }, {
            headers: {
                
            }
        })
        .then(function (response) {
           return {
               status: true,
               data: response.data
           };
        })
        .catch(function (error) {
            console.log(error)
            return {
                status: false,
                data: error.message,
            }

    });
};

