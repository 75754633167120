
import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";

import { FormatAddress } from "./Utils"
import { Container, Row, Col, Alert, Form, Modal, Button, CloseButton, ButtonGroup } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Upload from "./components/Upload";

// import { fetchMe } from "./store/load";
import { web3, mintOnContract, monitorTxnSync } from './eth/eth'
import { pinJSONToIPFS } from './utils/pinata.js'
// import background from "./assets/randomshape.png";
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import { useWalletContext } from './contexts/WalletContext';
import { useToastContext, ADD } from './contexts/ToastContext';
import { getData } from "./utils/api"
import Image from './components/Image'
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import etherscan from "./assets/etherscan.png";
import { Loading } from './components/UI';
// const axios = require('axios');
import Confetti from 'react-confetti'
import Text from './components/Text'


import { LearnModeBtn } from './components/UI';

const Mint = (props) => {
  let wallet = useWalletContext();
  const { toastDispatch } = useToastContext();

  const { contract_address } = useParams();
  // const [signature, setSignature] = useState(null);
  // const { wallet_address } = props;
  // const [balance, setBalance] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [urlMetadata, setUrlMetadata] = useState({});

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [log, setLog] = useState(null);
  const [txnInProgress, setTxnInProgress] = useState(false);


  // const [success, setSuccess] = useState(true);
  const [numberOfPieces, setNumberOfPieces] = useState(0);


  const [collections, setCollections] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);

  const [txnHash, setTxnHash] = useState(null);

  const [traits, setTraits] = useState([{
    "trait_type": "owner",
    "value": wallet.wallet.address
  }]);


  const addNewTrait = () => {

    const newData = [...traits, {
      "trait_type": "",
      "value": ""
    }];
    setTraits(newData);
  }

  const updateTrait = (index, type, value) => {
    const newState = traits.map((obj, idx) => {
      if (idx === index) {
        obj[type] = value
        return obj
      }

      return obj;
    });
    setTraits(newState);
  }


  // Get Contract Metadata
  useEffect(() => {
    if (selectedContract !== null) {
      setActiveKey("1");
    } else {
      setActiveKey("0");
    }
  }, [selectedContract]);

  // Get Contract Metadata
  useEffect(() => {
    const _get = async () => {
      const data = await getData(`wallet/${wallet.wallet.address}/collections`)
      setCollections(data.data.data);

    };
    _get()
  }, []);

  useEffect(() => {


    if (contract_address !== undefined && collections !== null) {
      const result = collections.find((collection) => web3.utils.toChecksumAddress(collection.contract_address) === web3.utils.toChecksumAddress(contract_address));
      if (result !== null) {
        setSelectedContract(result)
      }

    }
  }, [contract_address, collections]);



  //   console.log("collection for ", contract_address)

  //   const [contractMetadata, setContractMetadata] = useState(null)


  //   useEffect(() => {

  //     const getData = async () => {

  //       const contractMetadata = await getContractMetadata(contract_address);
  //       console.log(contractMetadata);
  //       setContractMetadata(contractMetadata)
  //     };

  //     getData();



  //   }, [contract_address]);

  function uploadComplete(upload) {
    console.log({
      name: upload.metadata.name,
      format: upload.metadata.type,
      size: upload.metadata.size,
      last_modified: upload.metadata.lastModifiedDate.toLocaleDateString(),
    })
    setURL(upload.hash);
    setUrlMetadata({
      name: upload.metadata.name,
      format: upload.metadata.type,
      size: upload.metadata.size,
      last_modified: upload.metadata.lastModifiedDate.toLocaleDateString(),
    })
    setActiveKey("2");
  }

  async function fetchMonitorTxn(txnHash) {
    setTxnInProgress(true);

    const txnResponse = await new Promise(resolve => {
      const interval = setInterval(async () => {

        setLog(`<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> Attempting to get transaction receipt. Please wait...`);
        const rec = await monitorTxnSync(txnHash)
        if (rec) {
          resolve(rec);
          // fetchMe(props);
          setTxnInProgress(false);
          clearInterval(interval);

          toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: "Your NFT is minted. It can take upto 10-15 minutes for all systems to refresh the data. If you visit the collection page, you may not see all details right away!" } } })
          setNumberOfPieces(500);

          setTimeout(function () {
            setNumberOfPieces(0);
          }.bind(this), 3000);

        }
      }, 1000);

    });

    setLog(`<pre>${JSON.stringify(txnResponse, null, 4)}</pre>`)
  }



  // const fetchSignature = async () => {
  //   var request = {}
  //   request["wallet_address"] = wallet_address
  //   return axios.get("http://localhost:8000/api/wallet/" + wallet_address + "/mint", { headers: {} })
  //     .then(function (response) {
  //       console.log(response);
  //       // setSignature(response.data);
  //       return response.data
  //       // createSignature(address, response.data.nonce)
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //       setError(error.message);
  //       return {}
  //     });
  // }



  const onMintPressed = async () => {
    //error handling
    if (url.trim() === "" || (name.trim() === "" || description.trim() === "")) {
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: `Please make sure all fields are completed before minting` } } })
      return
    }
    setActiveKey("4");
    setTxnInProgress(true);

    //make metadata
    const metadata = {};
    metadata.name = name;
    metadata.image = url;
    metadata.description = description;
    metadata.attributes = traits;
    metadata.image_details = urlMetadata;
    metadata.created_by = wallet.wallet.address;

    console.log(metadata)
    //pinata pin request
    const pinataResponse = await pinJSONToIPFS(metadata);
    if (!pinataResponse.success) {
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: "Something went wrong while uploading your tokenURI" } } })
      setTxnInProgress(false);
      return
    }

    const tokenURI = pinataResponse.pinataUrl;
    // const tokenURI = "bafkreihylufvhrctw55nkh6xu32huhgwmd22wmxalefkyqeebsldf563i4"

    try {
      const { txHash, error } = await mintOnContract(selectedContract.contract_address, tokenURI)
      if (error) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        setTxnInProgress(false);
        return
      }
      setTxnHash(txHash)
      fetchMonitorTxn(txHash);
    } catch (err) {
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
    }

    // const web3 = new Web3('http://localhost:8545');
    // const contractABI = require('./MaskNFT.json')
    // window.contract = await new web3.eth.Contract(contractABI, "0x5FbDB2315678afecb367f032d93F642f64180aa3");//loadContract();
    // const provider = window.ethereum

    // const txHash = await window.contract.methods.mint(tokenURI).send({from: provider.selectedAddress})
    // .on('transactionHash', function(hash){
    //   console.log(hash);
    // })
    // .on('confirmation', function(confirmationNumber, receipt){
    //   console.log(confirmationNumber, receipt);
    //   setLog(`<pre>${JSON.stringify(receipt, null, 4)}</pre>`)
    // })
    // .on('receipt', function(receipt){
    //     console.log(receipt);
    //     setLog(`<pre>${JSON.stringify(receipt, null, 4)}</pre>`)
    // })
    // .on('error', function(error, receipt) { 
    //     console.log(error, receipt)
    //     setError(error)
    // });







    // const { success, status, txHash } = await mintNFT(signature_data["message_hash"], signature_data["signature"], url, name, description);

    // // Qmbqnksc1Y83gqYQxBtebgxGkeHeeQKx6Sgf4x6y4yMhQ5

    // if (!status) {
    //   setError(status)
    // }
    // setStatus(status);

    // fetchMonitorTxn(txHash);


    // if (success) {
    //   setURL("")
    //   setName("")
    //   setDescription("")
    // }

  };

  const [activeKey, setActiveKey] = useState("0");

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log(eventKey),
    );

    return (
      <span disabled={activeKey !== eventKey ? true : false}
        onClick={decoratedOnClick}
      >
        {children}
      </span>
    );
  }




  return (
    <div>

      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        numberOfPieces={numberOfPieces}
      />

      {/* <div className="bg-image" style={{ backgroundImage: `url(${background})` }}></div> */}


      <>
        <>
          <Container className="main">
            <Row>
              <div className="row"><div className="col">
                <div className="m-3 mb-4 position-relative">
                  <LearnModeBtn module="nft_marketplace_mint" />
                  <h1 className="mt-3">Mint NFT</h1>
                  <p>Minting NFT is as simple as - Selecting Collection, Provide Asset, Metadata and Payment and Submit!</p>
                </div></div></div>


              <Col xl={4} lg={4} md={12} sm={12} >

                <div className="">
                  {selectedContract !== null ?
                    <div >
                      <div className="mb-3 bg-black rounded-2 p-4" >
                        <Icon.Pencil className="shadow-0 pointer rounded-0 border-0 float-end" onClick={() => setSelectedContract(null)} />
                        {/* <CloseButton className="shadow-0 rounded-0 border-0 float-end" onClick={() => setSelectedContract(null)} ></CloseButton> */}
                        <b>Selected Collection</b>
                        <h3 className="m-0 mt-1 mb-2">{selectedContract.name}</h3>
                        <div className="mb-2" ><FormatAddress address={selectedContract.contract_address} allowCopy={true} /></div>
                        <Image height="150px" url={selectedContract.image || null} background="banner" title="featured" />
                        <div className="text-center mt-2">
                          <ButtonGroup aria-label="Basic example">
                            <Button variant="dark"><a variant="dark" className="w-100" href={`https://goerli.etherscan.io/address/${selectedContract.contract_address}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end me-2" src={etherscan} /></a></Button>
                            <Button variant="dark" href={`/#/contract/${selectedContract.contract_address}`}><Icon.Link45deg /></Button>
                          </ButtonGroup>
                        </div>
                      </div>


                    </div>
                    : ""
                  }
                  {url !== "" ?
                    <div className="">
                      <div className="mb-3 bg-black rounded-2 p-4" >
                        {/* <CloseButton className="shadow-0 rounded-0 border-0 float-end" onClick={() => setSelectedContract(null)} ></CloseButton> */}
                        <b>NFT Preview</b>
                        <Icon.Pencil className="shadow-0 pointer rounded-0 border-0 float-end" onClick={() => setActiveKey("1")} />
                        {/* <CloseButton className="shadow-0 rounded-0 border-0 float-end" onClick={() => setActiveKey("1") } ></CloseButton> */}
                        <h3 className="m-0 mt-1 mb-2">{name}</h3>
                        <Image url={`https://gateway.pinata.cloud/ipfs/${url}`} title="featured" />
                        <div className="mt-2">
                          <Text text={description} />
                        </div>
                      </div>
                    </div>
                    : ""}
                  <div className=" d-none d-md-block p-4  rounded-3 mt-3 position-relative">
                    <div className=" p-1 m-1 mt-2 ">
                      Minting an NFT means converting digital data into crypto collections or digital assets recorded on the blockchain. The digital products or files will be stored in a distributed ledger or decentralized database and cannot be edited, modified, or deleted. Minting an NFT, or non-fungible token, is publishing a unique digital asset on a blockchain so that it can be bought, sold, and traded.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12} style={{ position: "relative" }}>

                <div className="bg-black rounded-3 p-5">
                  <div className="process">
                    <form className="">
                      <Accordion flush activeKey={activeKey}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header >
                            <CustomToggle eventKey="0">
                              <h6>
                                Select Collection to Mint On
                                <div className="display-block text-small">Lorem ipsum dolor sit amet</div>
                              </h6>
                            </CustomToggle>
                            <span className="aicon"><Icon.Images /></span>

                          </Accordion.Header>
                          <Accordion.Body>
                            {collections ? <Row>
                              {collections.map((contract, idx1) => {
                                return (
                                  <Col key={idx1} className="mb-2" style={{ position: "relative" }} xl={3} lg={4} md={12} sm={12}>
                                    <div className="bg-dark border-1 p-1">
                                      <Image title={contract.name || "No Title"} url={contract.image || null} />
                                      <Button style={{ position: "absolute", marginTop: "-55px", left: "50%", width: "100px", marginLeft: "-50px" }} onClick={() => setSelectedContract(contract)} variant="primary"><Icon.Check2Circle /> Pick</Button>
                                      <h6 className="mt-2 text-truncate">{contract.name}</h6>
                                    </div>
                                  </Col>
                                  // <Col>
                                  //   <h6 className="mt-2 text-truncate">{contract.name}</h6>
                                  //   <b>{contract.symbol}</b><br />
                                  //   <Text text={contract.description} />
                                  // </Col>
                                )
                              })}</Row> : "Loading..."}
                            {/* <a onClick={() => { setActiveKey("1"); }}>Provide Metadata</a> */}
                          </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <span className="aicon"><Icon.Image /></span>
                            <h6>
                              NFT Asset
                              <div className="display-block text-small">ok - Create a unique image which reflects your collection. Take a look at a collage of bored ape</div>
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>

                            <Upload text="Click" setUploadURL={uploadComplete}  />

                            <Form.Group className="mt-3" controlId="formBasicEmail">
                              <Form.Label>Image URL</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Auto populated by upload step"
                                value={url}
                                disabled />
                            </Form.Group>

                            <a onClick={() => { uploadComplete("bafybeiapyufq5wbgxwtrjz7h2hcqv76nbiv3hxb7rtsq35kz674baj3oii/saints.png"); }}>Test</a>


                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <span className="aicon"><Icon.CardHeading /></span>
                            <h6>
                              NFT Metadata
                              <div className="display-block text-small">Create a unique image which reflects your collection. Take a look at a collage of bored</div>
                            </h6>
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* <div>
                              <a className="mb-3 btn btn-secondary" onClick={() => { setActiveKey("1"); }}>Change Asset?</a>
                            </div> */}
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="e.g. My first NFT!"
                                onChange={(event) => setName(event.target.value)} />
                              <Form.Text className="text-muted">Crypto Kittens?</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                type="text"
                                as="textarea" style={{ height: "150px" }}
                                placeholder="e.g. Even cooler than cryptokitties ;)"
                                onChange={(event) => setDescription(event.target.value)} />
                              <Form.Text className="text-muted">A descrptive metadata for your NFT</Form.Text>
                            </Form.Group>

                            <Button className="float-end btn-sm" onClick={() => addNewTrait()} variant="secondary"><Icon.Plus /> New Trait</Button>
                            <h5>Attributes</h5>
                            {traits.map((trait, tidx) => {
                              return (
                                <Row key={tidx}>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Trait Type</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        defaultValue={trait.trait_type}
                                        onChange={(event) => updateTrait(tidx, "trait_type", event.target.value)}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Trait Value</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        defaultValue={trait.value}
                                        onChange={(event) => updateTrait(tidx, "value", event.target.value)}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              )
                            })
                            }
                            


                            <center>
                              <Button variant="primary" className="mt-3 large w-100" onClick={onMintPressed}>Mint</Button>
                            </center>


                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                          <Accordion.Header >
                            <CustomToggle eventKey="4">
                              <h6>
                                Transaction
                                <div className="display-block text-small">Processing on chain</div>
                              </h6>
                            </CustomToggle>
                            <span className="aicon"><Icon.Check2All /></span>

                          </Accordion.Header>
                          <Accordion.Body>

                            {txnInProgress ?
                              <Spinner animation="border" className="" style={{ width: "24px", height: "24px" }} role="status"><span className="visually-hidden">Loading...</span></Spinner> : ""}
                            {txnHash ?
                              <div><a href={`${wallet.wallet.explorer}/tx/${txnHash}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end me-2" src={etherscan} /> View on Explorer</a></div>
                              : ""}

                            {log ? <Alert className="mt-3" variant="secondary"><div dangerouslySetInnerHTML={{ __html: log }} /></Alert> : ""}

                          </Accordion.Body>
                        </Accordion.Item>

                      </Accordion>
                    </form>

                  </div>
                </div>





                <div style={{ display: "none" }}>
                  <div className="bicon">
                    {txnInProgress ?
                      <Spinner animation="border" className="" style={{ width: "24px", height: "24px" }} role="status"><span className="visually-hidden">Loading...</span></Spinner> :
                      <Icon.Image size="24" className="me-2" />
                    }
                  </div>

                  {!txnInProgress ?
                    <div>
                      <h1 className="mt-3">Mint NFT</h1>

                      {selectedContract == null ?
                        collections !== null ?
                          <>
                            <Row>
                              <Col><p className="mb-3 p-1">Select a collection where you like to Mint your NFT</p></Col>
                            </Row>
                            {collections.map((contract, idx1) => {
                              return (
                                <Row key={idx1} className="mb-2">
                                  <Col style={{ position: "relative" }} xl={3} lg={4} md={12} sm={12}>
                                    <Image title={contract.name || "No Title"} url={contract.image || null} />                                                                :
                                    <Button style={{ position: "absolute", marginTop: "-55px", left: "50%", width: "100px", marginLeft: "-50px" }} onClick={() => setSelectedContract(contract)} variant="primary"><Icon.Check2Circle /> Pick</Button>
                                  </Col>
                                  <Col>
                                    <h6 className="mt-2 text-truncate">{contract.name}</h6>
                                    <b>{contract.symbol}</b><br />
                                    <Text text={contract.description} />
                                  </Col>
                                </Row>
                              )
                            })}
                          </>
                          : <Loading />
                        :

                        <form className="">
                          <Row>
                            <Col><p className="mb-3 p-1">Simply add your asset's name and description, then press "Mint."</p></Col>
                          </Row>
                          <Row>
                            <Col xl={3} md={4} sm={12}>
                              <h6 className="mt-3 mb-2">Image</h6>
                              <p>Look - Create a unique image which reflects your collection. Take a look at a collage of bored ape</p>
                            </Col>
                            <Col>

                              <div className="bg-white-soft p-3">
                                <Upload text="Click" setUploadURL={uploadComplete} />

                                <Form.Group className="mt-3" controlId="formBasicEmail">
                                  <Form.Label>Image URL</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Auto populated by upload step"
                                    value={url}
                                    disabled />
                                </Form.Group>
                              </div>
                            </Col>

                          </Row>

                          <Row className="mt-3">
                            <Col xl={3} md={4} sm={12}>
                              <h6 className="mt-3 mb-2">Metadata</h6>
                              <p>Look - Create a unique image which reflects your collection. Take a look at a collage of bored ape</p>
                            </Col>
                            <Col>
                              <div className="bg-white-soft p-3">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="e.g. My first NFT!"
                                    onChange={(event) => setName(event.target.value)} />
                                  <Form.Text className="text-muted">Crypto Kittens?</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    type="text"
                                    as="textarea" style={{ height: "150px" }}
                                    placeholder="e.g. Even cooler than cryptokitties ;)"
                                    onChange={(event) => setDescription(event.target.value)} />
                                  <Form.Text className="text-muted">A descrptive metadata for your NFT</Form.Text>
                                </Form.Group>
                              </div>
                            </Col>

                          </Row>



                          <center>
                            <Button variant="primary" className="mt-3 large w-100" onClick={onMintPressed}>Mint</Button>
                          </center>






                          {log ? <Alert className="mt-3" variant="secondary"><div dangerouslySetInnerHTML={{ __html: log }} /></Alert> : ""}


                        </form>
                      }
                    </div> : ""}
                </div>


              </Col>
            </Row>
          </Container>


        </>
        {/* ) : ""} */}
      </>
    </div>
  );
};


export default Mint;
