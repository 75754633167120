import { useEffect, useState } from "react";
import { Carousel, Stack, Card } from 'react-bootstrap';
import { useWalletContext } from '../contexts/WalletContext';
import { Loading } from './UI'
import Image from './Image'
import LazyLoad from 'react-lazy-load';
import { getData } from "../utils/api"

const FeaturedCollections = (props) => {
    let wallet = useWalletContext();

    const { address, title } = props;
    const [userContracts, setUserContracts] = useState([])
    const [chunks, setChunks] = useState(1)
    const [chunkWidth, setChunkWidth] = useState("25%")
    const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     function handleResize() {
    //         console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    //         if (window.innerWidth < 900) {
    //             setChunks(2)
    //             setChunkWidth("50%")
    //         } else if (window.innerWidth < 600) {
    //             setChunks(1)
    //             setChunkWidth("100%")
    //         } else {
    //             setChunks(4)
    //         }
    //         // processChunks(userContracts)
    //     }
    //     window.addEventListener('resize', handleResize)
    // })

    // useEffect(() => {
    //     function processChunks() {
    //         if (userContracts.length > 0) {
    //             const result = userContracts.reduce((resultArray, item, index) => {
    //                 const chunkIndex = Math.floor(index / chunks)

    //                 if (!resultArray[chunkIndex]) {
    //                     resultArray[chunkIndex] = [] // start a new chunk
    //                 }

    //                 resultArray[chunkIndex].push(item)

    //                 return resultArray
    //             }, [])

    //             setUserContracts(result)
    //         }
    //     }
    //     processChunks()
    // }, [chunks])


    useEffect(() => {
        async function _getData(address) {

            let api_response = await getData(`collections/${address}?contracts=1`)
            const data = api_response.data
            if(data.contracts === null || data.contracts === undefined) {
                setLoading(false)
                return
            }

            // const data = await getAllContractByAddress(address, "")
            let chunkSize = 4;

            if (window.innerWidth < 900) {
                setChunks(2)
                chunkSize = 2
                setChunkWidth("50%")
            } 
            if (window.innerWidth < 600) {
                setChunks(1)
                chunkSize = 1
                setChunkWidth("100%")
            } 
            

            // processChunks(data.contracts);
            // setUserContracts(data.contracts)
            const result = data.contracts.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / chunkSize)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [] // start a new chunk
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])

            setUserContracts(result)

            setLoading(false);
        }

        setLoading(true);
        if (address != null) {
            _getData(address);
        }
        else if (wallet.wallet !== null && wallet.wallet.error === undefined) {
            console.log(wallet.wallet)
            _getData(wallet.wallet.address);
        }
    }, [wallet.wallet, address]);





    return (

        <>


            {!loading ?
                <div >

{ userContracts.length > 0 ?  <Image url={userContracts[0][0].media} background="bg" title="featured" /> : "" }

                    {title ? <h6 className="mt-2 mb-0 ms-3">{title}</h6> : ""}
                    <LazyLoad offset={300} threshold={0.95} onContentVisible={() => { console.log('loaded!') }}>
                        <Carousel variant="light">
                            {userContracts.map((contractgroup, idx) => {
                                return (

                                    <Carousel.Item className="" key={idx}>
                                        <Stack direction="horizontal" gap={3}>
                                            {contractgroup.map((contract, idx1) => {
                                                return (
                                                    <Card key={idx1} className="nft-card border-0 nft-card bg-dark shadow-0" style={{ width: `${chunkWidth}` }}>
                                                        <a href={`/#/contract/${contract.metadata.address}`}>
                                                            <Image title={contract.metadata.name || "No Title"} url={contract.media} />
                                                        </a>
                                                    </Card>
                                                )
                                            })}
                                        </Stack>
                                    </Carousel.Item>
                                )
                            })}

                        </Carousel>
                    </LazyLoad>
                </div>
                : <Loading title="Gathering Featured Collections ..." />}
        </>
    );
};


export default FeaturedCollections;
