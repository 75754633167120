import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormatAddress, GetIPFSURL } from "../Utils"
import _placeholder from "../assets/placeholder-square-dark.jpeg";
import _background from "../assets/randomshape.png";

import {  getImage } from "../utils/api"

const Image = (props) => {
    const { url, title, height, background } = props;
    const [image, setImage] = useState(null)

    useEffect(() => {
        async function processImage() {
          if(url !== null && url !== undefined) {
            //  const imageData = await getImage(url)
            setImage(url)
          }
        }
        processImage()
      }, [url]);



    return (
        <>
        { image !== null ? 
        <div>
            { background == "bg" ? <div className="bg-image" style={{ backgroundImage: `url(${image})` }}></div> : "" }

            { background == "banner" ? <div className="" style={{ zIndex: "-1", backgroundColor: "rgba(255,255,255,0.2)", display: "block", height: '150px', backgroundImage: `url(${image})`, backgroundPosition: "center center", backgroundSize: "contain" }}></div> : "" }

            { background !== "banner" &&  background !== "bg" ? 
            <div className="image" style={{ maxHeight: `${height || "100%"}`}}><img style={{ maxHeight: height || "100%"}}  className="img img-rounded img-responsive full-width" alt={title} src={image} /></div> : ""}
        </div>
        : 
        <div>
            { background == "bg" ? <div className="bg-image" style={{ backgroundImage: `url(${_background})` }}></div> : "" }

            { background == "banner" ?  <div className="" style={{ zIndex: "-1", display: "block", height: '150px',backgroundColor: "rgba(255,255,255,0.2)",  backgroundPosition: "center center", backgroundSize: "contain" }}></div> : "" }

            { background !== "banner" &&  background !== "bg" ? <div className="image" style={{ maxHeight: `${height || "100%"}`}}><img style={{ maxHeight: height || "100%"}}  width="100%" className="img img-rounded img-responsive full-width" alt={title} src={_placeholder} /></div> : ""}
        </div>
        }
        </>
    )
}

export default Image;