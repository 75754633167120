require('dotenv').config();
const key = process.env.REACT_APP_PINATA_KEY;
const secret = process.env.REACT_APP_PINATA_SECRET;

const axios = require('axios');
const NFT_STORAGE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDkwMkM4NWE5OWYwY0U2N0RhNjNDNTQxZTk0N2NiYTFBNjUwRmRDNjMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDE2ODA2NTcwOSwibmFtZSI6Ik1pbnQifQ.udjNalRNB5Q-cyRuoatEn3ly3spcH8-M6UQ6jf_Iqic"

export const pinJSONToIPFS = async(JSONBody) => {
    // const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    // const url = `/api/ipfs/metadata`;
    const url = "https://api.nft.storage/upload"

    //making axios POST request to Pinata ⬇️
    return axios 
        .post(url, JSONBody, {
            headers: {
                // pinata_api_key: key,
                // pinata_secret_api_key: secret,
                Authorization: `Bearer ${NFT_STORAGE_KEY}`,
                "Content-Type": "application/json"
            }
        })
        .then(function (response) {
           return {
               success: true,
            //    pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
                // pinataUrl: response.data.IpfsHash
                pinataUrl: response.data.value.cid
           };
        })
        .catch(function (error) {
            return {
                success: false,
                message: error.message,
            }

    });
};


// export const pinFileToIPFS = async(JSONBody) => {
//     const formData = new FormData();
//     formData.append("file", fileImg);

//     const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
//     //making axios POST request to Pinata ⬇️
//     return axios 
//         .post(url, 
//             formData,
//             {
//             headers: {
//                 pinata_api_key: key,
//                 pinata_secret_api_key: secret,
//                 "Content-Type": "multipart/form-data"
//             }
//         })
//         .then(function (response) {
//            return {
//                success: true,
//                pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash
//            };
//         })
//         .catch(function (error) {
//             console.log(error)
//             return {
//                 success: false,
//                 message: error.message,
//             }

//     });
// };
