import { Spinner, Accordion, Card, Form, Alert, Container, Row, Col, ListGroup, ListGroupItem, CloseButton, Button, ButtonGroup, Dropdown, DropdownButton, ToggleButton, Badge, Table } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import React from "react";

import Moment from 'react-moment';

import Confetti from 'react-confetti'

import ConnectWallet from "../ConnectWallet";

import { marketplaceContractAddress, explorerURL, web3, listNft, buyNft, delistNft, makeOffer, claimNft, approveListing, monitorTxnSync, getNFTLogs, getNFTDetails, getListedNFTs, getListedNFTDetails, getNFTOwners } from "../eth/eth"
import token_svg from "../assets/ethereum-eth-logo.png";

import { useToastContext, ADD } from '../contexts/ToastContext';
import { useWalletContext } from '../contexts/WalletContext';
import { useApiContext, LISTED_ITEMS } from '../contexts/ApiContext';

import { FormatAddress } from "../Utils";

import History from './History';




import { LearnModeBtn } from "../components/UI"
import { solarizedDark } from 'react-code-blocks';


const Buy = (props) => {

  const { nft, owners } = props;
  const { apiDispatch } = useApiContext();
  const { toastDispatch } = useToastContext();
  let wallet = useWalletContext();
  let api = useApiContext();

  const [listingTypeValue, setListingTypeValue] = useState('0');
  const listingType = [
    { name: 'Fixed', value: '0' },
    { name: 'Auction', value: '1' },
    { name: 'Reserved', value: '2' },
  ];

  const LISTING = {
    FIXED: 0,
    AUCTION: 1,
    RESERVED: 2
  }


  const [offers, setOffers] = useState(null)
  const [listed, setListed] = useState(null)

  // const [owners, setOwners] = useState(props);
  const [txns, setTxns] = useState([]);

  const [owned, setOwned] = useState(false)

  const [price, setPrice] = useState(null)
  const [reservedAddress, setReservedAddress] = useState(web3.utils.toChecksumAddress("0x0000000000000000000000000000000000000000"))

  const [expiryDays, setExpiryDays] = useState(1)
  const [expiryDurationValue, setExpiryDurationValue] = useState("days")
  const [image, setImage] = useState(null)

  const [offerPrice, setOfferPrice] = useState(null)

  const [txnInProgress, setTxnInProgress] = useState(false);
  const [txnUrl, setTxnUrl] = useState(null);


  // const [message, setMessage] = useState(null);
  const [log, setLog] = useState(null);

  const [showMore, setShowMore] = useState(false);
  const [numberOfPieces, setNumberOfPieces] = useState(0);


  const [favorites, setFavorites] = useState(0);
  const [expired, setExpired] = useState(false);
  const [allowRelist, setAllowRelist] = useState(false);

  const NETWORK_SYMBOL = {
    '5': "GoerliETH",
    '80001': "MATIC"
  }
  const [networkSymbol, setNetworkSymbol] = useState(false);


  useEffect(() => {
    setNetworkSymbol(NETWORK_SYMBOL[localStorage.getItem("chain")])

    async function getListedStatus() {
      if (nft !== null && api.api !== null && api.api.listed_nfts !== undefined) {
        // const listed_items = await getListedNFTs()
        let listed = api.api.listed_nfts.find(e => {
          return String(e.tokenId) === web3.utils.hexToNumberString(Number(nft.id.tokenId)) && web3.utils.toChecksumAddress(e.nftContract) === web3.utils.toChecksumAddress(nft.contract.address);
        });


        if (listed !== undefined && listed !== null && Date.now() > listed.startTime * 1000 + listed.expiryDays * 1000) {
          setExpired(true)
          console.log(listed)
          if(listed.totalOffers == 1) {
            setAllowRelist(true)
            // if(listed.seller == wallet.wallet.address.toLowerCase() && Object.keys(wallet.wallet).length !== 0 ) {
            //   setOwned(true)
            // }
            // listed = null
          }
        } else {
          setExpired(false)
        }


        if (listed !== undefined && listed !== null && listed.listingType !== undefined && listed.listingType !== LISTING.FIXED) {
          let listing_offers = await getListedNFTDetails(nft.contract.address, String(nft.id.tokenId))
          setOffers(listing_offers || []);
          if (listed.startTime * 1000 + listed.expiryDays * 1000 > Date.now()) {
            countDownFromTime(listed.startTime * 1000 + listed.expiryDays * 1000);
          }
        }


        if (listed !== undefined && listed !== null) {
          setListed(listed)
        }
      }
    }
    if (nft !== null && api.api != null && api.api.listed_nfts !== undefined) {
      getListedStatus()
    }

  }, [nft, wallet.wallet, api.api]);


  useEffect(() => {
    async function processSelection() {
      if (Object.keys(wallet.wallet).length !== 0 && owners.includes(wallet.wallet.address.toLowerCase())) {
        setOwned(true);
      }
    }

    if (owners != null) {
      processSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owners, wallet]);




  async function fetchMonitorTxn(txnHash) {
    setTxnInProgress(true);

    const _txnURL = `${explorerURL}/tx/${txnHash}`
    setTxnUrl(_txnURL)

    // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: `See transactiond details at <a href=${_txnURL} target='_blank'>${_txnURL}</a>` } } })


    const txnResponse = await new Promise(resolve => {
      const interval = setInterval(async () => {

        setLog(`<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> Attempting to get transaction receipt. Please wait...`);
        const rec = await monitorTxnSync(txnHash)
        if (rec) {
          resolve(rec);
          // fetchMe(props);
          setTxnInProgress(false);
          clearInterval(interval);
        }
      }, 1000);
    });

    setLog(`<pre>${JSON.stringify(txnResponse, null, 4)}</pre>`)
    toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: `Your transaction is Completed. You can see all details on <a target='_blank' href='${_txnURL}'>Explorer</a>` } } })

  }



  // const setSellPrice = (event) => {
  //   setPrice(event.target.value);
  // }

  // const setExpiryDays = (event) => {
  //   setExpiryDays(event.target.value);
  // }

  async function verifyApproval(selectedNft) {

    setLog(null);
    toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: 'Requesting approval on the token for Marketplace to list' } } })

    var { error, txHash } = await approveListing(selectedNft.contract.address, web3.utils.hexToNumberString(Number(selectedNft.id.tokenId)));
    if (error) {
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
      setTxnInProgress(false);
      return;
    }

    await fetchMonitorTxn(txHash)

  }



  async function doListNFT(selectedNft, getApproval) {
    try {

      // For example, 1 minutes is 60, 1 hours is 3600 (60 seconds x 60 minutes), etc.
      let duration = 60 * 60 * 24 * 365
      if (listingTypeValue == LISTING.AUCTION) {
        if (expiryDurationValue == "minutes") {
          duration = expiryDays * 60
        }

        if (expiryDurationValue == "hours") {
          duration = expiryDays * 60 * 60
        }

        if (expiryDurationValue == "days") {
          duration = expiryDays * 60 * 60 * 24
        }

        if (expiryDurationValue == "weeks") {
          duration = expiryDays * 60 * 60 * 24 * 7
        }
      }

      if (price == null || Number(price) <= 0) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: 'You need to set valid and >0 price to sell the item' } } })
        return
      }
      // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Requesting confirmation on Wallet to list the Item" } } })

      setTxnInProgress(true);
      setLog(null);

      if (getApproval) {
        await verifyApproval(selectedNft)
        toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Approval Completed! Requesting confirmation on Wallet to list this item for Sale" } } })
      }

      setTxnInProgress(true);

      var { error, txHash } = await listNft(selectedNft.contract.address, web3.utils.hexToNumberString(Number(selectedNft.id.tokenId)), price, listingTypeValue, duration, reservedAddress);
      if (error) {
        setTxnInProgress(false);
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        if (error.includes("execution reverted: ERC721: caller is not token owner or approved")) {
          toastDispatch({ type: ADD, payload: { content: { variant: 'warning', message: "You need to approve this token for Marketplace" } } })
          doListNFT(selectedNft, true)
        }
        return;
      }

      setNumberOfPieces(500)

      await fetchMonitorTxn(txHash)

      // const _txnURL = `${explorerURL}/tx/${txHash}`


      toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: `Your NFT is now listed for sale in Marketplace` } } })

      setTimeout(function () {
        setNumberOfPieces(0);
      }.bind(this), 3000);

      // getData(`marketplace/listing/refresh`)
      const updated_listing = await getListedNFTs();
      apiDispatch({ type: LISTED_ITEMS, payload: updated_listing || [] })

      // props.listed_nfts = updated_listing.data || []

    } catch (error) {
      setLog(null);
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error.message } } })
    }
  }


  async function doDeListNFT(selectedNft) {
    setLog(null);
    // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Requesting confirmation on Wallet to de-list the Item" } } })
    setTxnInProgress(true);


    try {
      var { error, txHash } = await delistNft(selectedNft.contract.address, listed.listingId);
      if (error) {
        setTxnInProgress(false);
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        return;
      }

      await fetchMonitorTxn(txHash)


      toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: `Your NFT is now de-listed from Marketplace` } } })
      // const updated_listing = await getData(`marketplace/listing/refresh`)
      // getData(`marketplace/listing/refresh`)

      const updated_listing = await getListedNFTs();
      apiDispatch({ type: LISTED_ITEMS, payload: updated_listing || [] })

    } catch (err) {
      setLog(null);
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
    }

    // props.listed_nfts = updated_listing.data || []

  }



  async function makeOfferNFT(selectedNft) {
    try {


      if (offerPrice < 0) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: "Offer cannot be 0 ETH" } } })
        return
      }

      if (web3.utils.toWei(offerPrice) <= listed.lastOfferPrice) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: "Offer needs to be greater than last offer price" } } })
        return
      }


      // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Requesting confirmation on Wallet to buy the Item" } } })
      setTxnInProgress(true);


      var { error, txHash } = await makeOffer(web3.utils.hexToNumberString(Number(listed.listingId)), web3.utils.toWei(offerPrice), 3)
      if (error) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        setTxnInProgress(false);
        return;
      }

      if (txHash !== null) {
        await fetchMonitorTxn(txHash)

        const updated_listing = await getListedNFTs();
        apiDispatch({ type: LISTED_ITEMS, payload: updated_listing || [] })

        let listing_offers = await getListedNFTDetails(selectedNft.contract.address, String(selectedNft.id.tokenId))
        // setOffers(listing_offers);

        toastDispatch({ type: ADD, payload: { content: { variant: 'success', message: "Your bid is successfully placed. Keep checking this listing and if you are the higgest bidder when the auction is over, then you can claim the NFT" } } })

      }
    } catch (err) {
      setLog(null);
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
    }
  }


  async function doClaimNFT(selectedNft) {
    try {
      // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Requesting confirmation on Wallet to buy the Item" } } })
      setTxnInProgress(true);

      let price = 0
      if (listed.listingType == LISTING.RESERVED) {
        price = listed.lastOfferPrice
      }

      var { error, txHash } = await claimNft(selectedNft.contract.address, web3.utils.hexToNumberString(Number(listed.listingId)), price)
      if (error) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        setTxnInProgress(false);
        return;
      }

      if (txHash !== null) {
        await fetchMonitorTxn(txHash)
        setNumberOfPieces(500)
        setOwned(true);

        const updated_listing = await getListedNFTs();
        apiDispatch({ type: LISTED_ITEMS, payload: updated_listing || [] })
        // setOffers(null)


        setTimeout(function () {
          setNumberOfPieces(0);
        }.bind(this), 3000);


      }
    } catch (err) {
      setLog(null);
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
    }
  }

  async function doBuyNFT(selectedNft) {
    try {
      // toastDispatch({ type: ADD, payload: { content: { variant: 'info', message: "Requesting confirmation on Wallet to buy the Item" } } })
      setTxnInProgress(true);

      var { error, txHash } = await buyNft(selectedNft.contract.address, web3.utils.hexToNumberString(Number(listed.listingId)), listed.price)
      if (error) {
        toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: error } } })
        setTxnInProgress(false);
        return;
      }

      if (txHash !== null) {
        await fetchMonitorTxn(txHash)
        setNumberOfPieces(500)
        setOwned(true);

        // const updated_listing = await getData(`marketplace/listing/refresh`)
        const updated_listing = await getListedNFTs();
        apiDispatch({ type: LISTED_ITEMS, payload: updated_listing || [] })

        setTimeout(function () {
          setNumberOfPieces(0);
        }.bind(this), 3000);

        // const updated_listing = await getListedNFTs();
        // props.appendData({
        //   listed_nfts: updated_listing.data || [],
        // });

        // props.listed_nfts = updated_listing.data || []
      }
    } catch (err) {
      setLog(null);
      setTxnInProgress(false);
      toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
    }
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }


  const [days, setDays] = useState(0);
  const [hours, setMinutes] = useState(0);
  const [minutes, setHours] = useState(0);
  const [seconds, setSeconds] = useState(0);

  function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }


  function countDownFromTime(countFrom) {
    countFrom = new Date(countFrom).getTime();
    var now = new Date(),
      countFrom = new Date(countFrom),
      timeDifference = (countFrom - now);

    var secondsInADay = 60 * 60 * 1000 * 24,
      secondsInAHour = 60 * 60 * 1000;

    let days = Math.floor(timeDifference / (secondsInADay) * 1);
    let hours = Math.floor((timeDifference % (secondsInADay)) / (secondsInAHour) * 1);
    let mins = Math.floor(((timeDifference % (secondsInADay)) % (secondsInAHour)) / (60 * 1000) * 1);
    let secs = Math.floor((((timeDifference % (secondsInADay)) % (secondsInAHour)) % (60 * 1000)) / 1000 * 1);


    setDays(addLeadingZeros(days, 2));
    setHours(addLeadingZeros(hours, 2));
    setMinutes(addLeadingZeros(mins, 2));
    setSeconds(addLeadingZeros(secs, 2));

    clearTimeout(countDownFromTime.interval);
    countDownFromTime.interval = setTimeout(function () { countDownFromTime(countFrom); }, 1000);
  }

  return (
    <>

      {numberOfPieces > 0 ?
        <div style={{ position: "fixed", left: "0", right: "0", top: "0", bottom: "0", zIndex: "1234" }}>
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={numberOfPieces}
          />
        </div>
        : ""}

      {nft !== null ?
        <div>


          <div className="p-5 bg-white-soft mb-5 mt-5 rounded border-0" style={{ position: "relative", margin: "auto", maxWidth: "100%" }}>
            <LearnModeBtn module="nft_marketplace_buy" />
            <div className="bicon">
              {txnInProgress ?

                <Spinner animation="border" className="" style={{ width: "24px", height: "24px" }} role="status"><span className="visually-hidden">Loading...</span></Spinner> :
                <Icon.Cart size="24" className="me-2" />
              }
            </div>


            {listed !== null && listed !== undefined && listed.listingType !== undefined ?
              <div className="text-center">
                <div className="text-muted mb-4">
                  <b>
                    {/* Listing ID #{listed.listingId}<br /> */}
                    Listed by <a href={`#/address/${listed.seller}`}>
                      <FormatAddress styling={true} address={listed.seller} allowCopy={true} />
                    </a></b>

                  <div className="mt-2">
                    Created <Moment fromNow>{listed.startTime * 1000}</Moment> (<Moment>{listed.startTime * 1000}</Moment>)
                  </div>

                  {listed.listingType == LISTING.AUCTION ?
                    <div className="mt-1">Expires <Moment fromNow>{listed.startTime * 1000 + listed.expiryDays * 1000}</Moment> (<Moment>{listed.startTime * 1000 + listed.expiryDays * 1000}</Moment>)</div> : ""}

                  {/* <br /><Timer initialMinute={4} initialSeconds={0} /> */}
                </div>

                <div className="mb-4 position-relative">
                  {listed.listingType == LISTING.FIXED ? <span className="bg-black bold rounded-pill p-2 ps-3 pe-3"><Icon.Cart /> Fixed Price Listing</span> : ""}
                  {listed.listingType == LISTING.AUCTION ?
                    <span style={{ lineHeight: "28px" }} className="display-inline bg-black bold rounded-pill p-2 ps-3 pe-3">

                      <span className="countup" id="countup1">
                        <Icon.Clock size="18" className="display-inline me-1" />
                        <span className="timeel days">{days}</span>:
                        <span className="timeel hours">{hours}</span>:
                        <span className="timeel minutes">{minutes}</span>:
                        <span className="timeel seconds">{seconds}</span>
                      </span>
                    </span>
                    : ""}
                  {listed.listingType == LISTING.RESERVED ? <span className="bg-black bold rounded-pill p-2 ps-3 pe-3"><Icon.Lock /> Reserved Listing</span> : ""}
                </div>

                <div>{listed.listingType == LISTING.AUCTION ? "Current Offer Price" : "Price"}</div>
                <h2 className="price ">
                  <img src={token_svg} height="20px" className=" me-2 " />
                  {web3.utils.fromWei(String(listed.lastOfferPrice), "ether")} <span className="text-success">{networkSymbol}</span>
                </h2>
              </div>
              : ""}

            {wallet.wallet.address === undefined ?
              <div className="text-center">
                <ConnectWallet locked={false} />
              </div>
              :
              <div className="text-center">
                {txnInProgress ?
                  <div>
                    <h6 className="text-center">Your transaction is in progress.</h6>
                  </div>
                  :
                  listed !== null && listed !== undefined && listed.listingType !== undefined ?
                    <div>
                      {/* <pre>{JSON.stringify(listed, null, 2)}</pre> */}
                      {web3.utils.toChecksumAddress(listed.seller) === web3.utils.toChecksumAddress(wallet.wallet.address) ?
                        <div>


                          {/* You are owner {listed.listingType} {expired ? "Expired": "Active"} and {offers.length} offers */}
                          {/* Not Expired Fixed Listing allows you to remove from Marketplace */}
                          {listed.listingType == LISTING.FIXED ? <>
                            {!expired ?
                              <div><Button className="mt-2 w-100 large" variant="warning" onClick={() => doDeListNFT(nft)}>Remove from Marketplace</Button></div> :
                              ""}
                          </> : ""}


                          {/* Expired Auction Listing has offers, don't allow remove from Marketplace */}
                          {/* If not Expired Auction Listing but No offers allows you to remove from Marketplace */}
                          {listed.listingType == LISTING.AUCTION ? <>
                            {!expired && listed.totalOffers == 1 ?
                              <div>
                                <Button className="mt-2 w-100 large" variant="warning" onClick={() => doDeListNFT(nft)}>Remove from Marketplace</Button>
                                <div className="mt-1">The auction is in progress and but there are NO active offers and so you can delisted the item </div>
                              </div> :
                              ""}

{/* { expired ? "EXPIRED": "ACTIVE"} */}

                            {expired && listed.totalOffers > 1 ?
                                <b className="text-danger">The auction is expired and it cannot be delisted anymore. The highest bidder (
                                <FormatAddress address={offers[offers.length - 1].bidder} />) need to claim the NFT</b> :
                                ""
                              }

                            {expired && listed.totalOffers == 1 ?
                                <div>The listing is expired and so remove from Marketplace to start the process again<br/><Button className="mt-2 w-100 large" variant="warning" onClick={() => doDeListNFT(nft)}>Remove from Marketplace</Button></div> : ""
                            }

                            {!expired && listed.totalOffers > 1 ?
                              <b>The auction is in progress and there are active offers. It cannot be delisted at this time.</b> : ""
                            }


                          </> : ""}


                          {/* Expired Reserved Listing  don't allow remove from Marketplace */}
                          {listed.listingType == LISTING.RESERVED ? <>
                            {expired ?
                              <b className="text-danger">The auction is reserved and it cannot be delisted anymore. The reserved wallet (
                                <FormatAddress address={offers[offers.length - 1].bidder} />) need to claim the NFT</b> :
                              ""}
                          </> : ""}
                        </div> :
                        <>
                          {expired && listed.listingType !== LISTING.FIXED ?
                            <>

                              {web3.utils.toChecksumAddress(listed.lastOfferFrom) === web3.utils.toChecksumAddress(wallet.wallet.address) ?
                                <div>
                                  {listed.listingType == "1" ?
                                    <b>Congratulations! You were the top bidder on this auction!</b> :
                                    <b>Congratulations! This NFT was reserved for you!</b>
                                  }
                                  <br />Go ahead and claim your NFT<br />

                                  <Button className="large mt-2 w-100" variant="success" onClick={() => doClaimNFT(nft)}>Claim NFT</Button>
                                </div>
                                : "The auction is now over! Better luck next time"}
                            </>
                            :
                            <>
                              {listed.listingType == LISTING.FIXED ?
                                <>
                                  <div><Button className="mt-2 w-100" variant="primary" onClick={() => doBuyNFT(nft)}>Buy Now</Button></div>
                                </> :
                                <>
                                  <div className="input-group mt-1">
                                    <Form.Control className="" type="number" placeholder="What's your offer?" defaultValue={nft.price} name="text" onChange={(e) => setOfferPrice(e.target.value)} />
                                    <div className="input-group-append">
                                      <Button className="large" variant="primary" onClick={() => makeOfferNFT(nft)}>Place Bid</Button>
                                    </div>
                                  </div>
                                  <div className="mt-1">Your bid will be escorwed in the smart contract until the auction is completed</div>

                                </>
                              }
                            </>
                          }
                        </>

                      }
                    </div> :
                    <div><h6 className="text-center">This item is currently not listed for sale in marketplace</h6>

                    </div>

                }


                {owned && listed == null && listingType !== undefined ?
                  (
                    txnInProgress ?
                      ""
                      :
                      <>
                        <div className="mt-3">
                          <ButtonGroup size="sm" className="border-0 rounded-pill">
                            {listingType.map((listing, idx) => (
                              <ToggleButton
                                key={idx}
                                id={`listing-${idx}`}
                                type="radio"
                                size="sm"
                                variant="transparent"
                                className={`${listing.name} border-0 text-dark rounded-pill`}
                                name="listing"
                                value={listing.value}
                                checked={listingTypeValue === listing.value}
                                onChange={(e) => setListingTypeValue(e.currentTarget.value)}
                              >
                                <div className="ps-3 pe-3">
                                  {listing.name}
                                </div>
                              </ToggleButton>
                            ))}
                          </ButtonGroup>

                          <div className="text-start mt-3 ps-1">
                            <label >
                              {listingTypeValue == LISTING.FIXED ? <span>Price</span> : ""}
                              {listingTypeValue == LISTING.AUCTION ? <span>Minimum Bid Price</span> : ""}
                              {listingTypeValue == LISTING.RESERVED ? <span>Reserved Price</span> : ""}
                            </label>
                          </div>
                          <div className="input-group mt-1">
                            <Form.Control className="w-100" type="number" placeholder="Price" defaultValue={nft.price} name="text"
                              onChange={(e) => setPrice(e.target.value)}
                            />
                            <div className="text-left ps-1 mt-1">
                              {
                                listingTypeValue == LISTING.FIXED ?
                                  <>{price ? <b>You will recieve {price - 0.0001} {networkSymbol}</b> : ""}</>
                                  : <b>You will recieve highest bid - listing fee (0.0001) </b>}
                            </div>
                          </div>



                          {listingTypeValue == LISTING.AUCTION ?
                            <>
                              <div className="text-start mt-3 ps-1">
                                <label >Duration</label>
                              </div>
                              <div className="input-group">

                                <Form.Control type="number" placeholder="Duration after which listing expires" defaultValue={nft.price} name="text"
                                  onChange={(e) => setExpiryDays(e.target.value)} />

                                <div className="input-group-append">
                                  <DropdownButton style={{ fontSize: "1em" }} variant="secondary" size="sm" className="p-0 m-0 btn btn-outline-secondary dropdown-toggle" id="dropdown-basic-button" onSelect={(e) => setExpiryDurationValue(e)} title={expiryDurationValue}>

                                    <Dropdown.Item eventKey="minutes">Minutes</Dropdown.Item>
                                    <Dropdown.Item eventKey="hours">Hours</Dropdown.Item>
                                    <Dropdown.Item eventKey="days">Days</Dropdown.Item>
                                    <Dropdown.Item eventKey="weeks">Weeks</Dropdown.Item>
                                  </DropdownButton>
                                </div>
                              </div>

                            </>
                            : ""}

                          {listingTypeValue == LISTING.RESERVED ?
                            <>
                              <div className="text-start mt-3 ps-1">
                                <label >Reserve For?</label>
                              </div>
                              <div className="input-group">

                                <Form.Control placeholder="Wallet Address" defaultValue={reservedAddress} name="text"
                                  onChange={(e) => setReservedAddress(e.target.value)} />

                              </div>

                            </>
                            : ""}



                          <Button className="mt-2 w-100 large" variant="primary" onClick={() => doListNFT(nft, false)}>List for Sell</Button>


                          <div className="mt-2"><small>You will see 2 prompts from your Wallet as listing is a 2 step process - Approve transfer of token to NFT Marketplace and List for sell. </small></div>

                          <LearnModeBtn module="nft_marketplace_sell" />

                        </div>


                      </>

                  ) : ""

                }
              </div>
            }
          </div>

          {/* { listed != null && listed !== undefined ? <div className="mb-5 rounded bg-black p-3 border-0 "><History listing_id={listed.listingId} /></div> : ""} */}

          {offers && offers.length > 0 && listed !== null && listed.listingType == LISTING.AUCTION ?
            <div className="mb-5 rounded bg-black p-3 border-0 ">
              <h6 className="mb-3">Bids</h6>
              <Container>
                {[...offers].reverse().map((offer, idx) => {
                  return (
                    <Row className="border-top " key={idx}>
                      <Col className="pt-3">
                        {web3.utils.toChecksumAddress(offer.bidder) === web3.utils.toChecksumAddress(wallet.wallet.address) ? <b>You</b> : <FormatAddress address={offer.bidder} />}
                      </Col>
                      <Col className="pt-3">{web3.utils.fromWei(String(offer.price), "ether")} {networkSymbol}</Col>
                      <Col className="p-2" width="150px">
                        {web3.utils.toChecksumAddress(offer.bidder) === web3.utils.toChecksumAddress(wallet.wallet.address) ?
                          <Button variant="secondary" size="sm">Withdraw Offer</Button> : ""}
                        {/* <Moment fromNow>{offer.expiryTime * 1000}</Moment> */}
                      </Col>
                    </Row>
                  )
                })}
              </Container>

            </div>
            : ""}

          {/* {log ?
            <Alert variant="warning"><div dangerouslySetInnerHTML={{ __html: log }} /></Alert> : ""
          } */}


        </div>
        : ""}
    </>

  )

}

export default Buy;
