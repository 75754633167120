import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import Overlay from 'react-bootstrap/Overlay';
import QRCode from "react-qr-code";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};


export const FormatAddress = (props) => {
  const { address, allowCopy, styling } = props;
  const [show, setShow] = useState(false);

  const target = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
       <div>
            <QRCode size={128} value={address} />
          </div>
    </Tooltip>
  );

  return <>
    <span className={styling ? "pointer bg-white-soft rounded-pill ps-3 pe-3 p-1 d-inline-block" : ""}>
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {/* onClick={() => {navigator.clipboard.writeText(address)}}  */}
      <span className={styling ? "blue" : ""}>
        {
          String(address).substring(0, 6) +
          "..." +
          String(address).substring(38)
        }
      </span>
    </OverlayTrigger>
    {/* {allowCopy ?
      <Icon.Clipboard ref={target}  onClick={() => { setShow(true); navigator.clipboard.writeText(address); setInterval(function () { setShow(false); }, 1000); }} className="display-inline ms-2" /> : ""} */}




    {/* <Overlay target={target.current} show={show} placement="bottom" >
      {({ placement, arrowProps, show: _show, popper, ...props }) => (
        <div
          {...props}
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '2px 10px',
            color: 'white',
            borderRadius: 3,
            ...props.style,
          }}
        >
          Address Copied to Clipboard
        </div>
      )}
    </Overlay>   */}
    </span >

    </>
}



export const GetIPFSURL = (ipfs_hash) => {
  var pattern = /^((http|https|ftp):\/\/)/;
  var url = ipfs_hash
  if (!pattern.test(ipfs_hash)) {
    // gateway.pinata.cloud
    // url = "https://gateway.pinata.cloud/ipfs/" + ipfs_hash;
    url = "https://ipfs.io/ipfs/" + ipfs_hash
  }


  // console.log(url)
  return url
}




export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

