import Moment from 'react-moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Accordion, Card, Container, Row, Col, Nav, Navbar, ButtonGroup, DropdownButton, Dropdown, ListGroup, ListGroupItem, NavDropdown, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import ConnectWallet from "../ConnectWallet";
import { switchNetworkConfig, getListedNFTs, marketplaceContractAddress } from '../eth/eth';
import { useWalletContext } from '../contexts/WalletContext';
import { useApiContext, LISTED_ITEMS } from '../contexts/ApiContext';
import { FormatAddress } from '../Utils';
import { sections } from '../Learn';
import RenderToast from '../components/Toast';
import { ToastContainer } from 'react-bootstrap';

import metamask_icon from "../assets/metamask.webp";
import token_icon from "../assets/token.svg";
import { useToastContext, GET } from '../contexts/ToastContext';
import jugaad from "../assets/logo_2.png";
import matic from "../assets/matic.png";
import geth from "../assets/geth.png";
import { getItems } from '../utils/api';



const Navigation = (props) => {
  const { toast, toastDispatch } = useToastContext();

  let wallet = useWalletContext();
  const navigate = useNavigate();
  const { apiDispatch } = useApiContext();

  const [chain, setChain] = useState(localStorage.getItem("chain") || 80001)
  const [show, setShow] = useState(false)

  useEffect(() => {

    if (localStorage.getItem("chain") !== chain) {
      localStorage.setItem("chain", chain)
      navigate("/")
      window.location.reload(false);
    }

    switchNetworkConfig(chain)

  }, [chain]);

  useEffect(() => {
    async function processSelection() {
      const saleItems = await getListedNFTs()
      apiDispatch({ type: LISTED_ITEMS, payload: saleItems || [] })
    }
    processSelection()

  }, []);

  useEffect(() => {
    setShow(true)
  }, [toast]);

  function navigateTo(location) {
    setCurrentActiveKey(null)
    navigate(location)
  }

  const [currentActiveKey, setCurrentActiveKey] = useState(null);

  const toggleActiveKey = (key) => {
    setCurrentActiveKey(currentActiveKey === key ? null : key);
  };


  return (
    <>
      <div className="sticky-top header text-center">
        <Container><Row>
          <Col xl={12} className="mb-3">
            <Navbar className="bg-black rounded-5 m-3 ps-3 pe-3">
              <Nav defaultActiveKey="/#/" className="me-auto">
                <Nav.Link className="" href={`/#/`}><Icon.House className="d-inline" /> <span className="d-none d-lg-inline">Home</span></Nav.Link>
                <Nav.Link className="" href={`/#/address/${marketplaceContractAddress}`}><Icon.Shop className="d-inline" /> <span className="d-none d-lg-inline">Marketplace</span></Nav.Link>
                {wallet.wallet.address ?
                  <Nav.Link className="" href={`/#/address/${wallet.wallet.address}`}><Icon.PersonCircle className="d-inline" /> <span className="d-none d-lg-inline">My Collection</span></Nav.Link> : ""}

                {wallet.wallet.address ?
                  <NavDropdown title={<span className="pt-0"><Icon.PlusCircle className="d-inline" /> <span className="d-none d-lg-inline">Create <Icon.ChevronDown /></span></span>} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/#/collection">Collection</NavDropdown.Item>
                    <NavDropdown.Item href="/#/mint">Mint NFT</NavDropdown.Item>
                  </NavDropdown>
                  : ""}


              </Nav>
              
              <Nav className="">



                <Nav.Link onClick={() => setShow(!show)}>
                  <Icon.Bell className="d-inline" />
                    <span style={{ minWidth: "auto" }} className="d-none d-md-inline badge badge-pill bg-danger" >{toast.length} </span>
                </Nav.Link>

                <ToastContainer className={show ? "" : "hidden"} position="top-end" containerPosition="fixed">
                    {
                      toast ?
                        toast.map((msg, idx) => {
                          return (
                            <RenderToast key={idx} msg={msg} />
                          )
                        }) : ""
                    }
                  </ToastContainer>                

                <NavDropdown as="span" align="end"  className="bg-black rounded dropdown-menu-end"  id="dropdown-basic-button" onSelect={(e) => setChain(e)}
                    title={chain === "5" ? 
                    <span><img src={geth} height="15px" /> <span className=" d-none d-md-inline">Goerli Test Network <Icon.ChevronDown /></span></span>: 
                    <span><img src={matic} height="15px" /> <span className=" d-none d-md-inline">Polygon Mumbai Testnet <Icon.ChevronDown /></span></span>
                    }>
                    <NavDropdown.Item eventKey="5">Goerli Test Network</NavDropdown.Item>
                    <NavDropdown.Item eventKey="80001">Polygon Mumbai Testnet</NavDropdown.Item>
                  {/* </DropdownButton> */}
                </NavDropdown>

              {wallet.wallet.address ?
                  <NavDropdown align="end" style={{ fontSize: "1em" }} variant="dark"
                     id="dropdown-basic-button" 
                     className="bg-black rounded dropdown-menu-end" 
                    title={
                      <span className="pt-0"><Icon.Wallet className="" /> 
                      <span className="d-none d-lg-inline ms-1">
                        {wallet.wallet.display_name} <Icon.ChevronDown />
                        {/* {String(wallet.wallet.address).substring(0, 6) + "..." + String(wallet.wallet.address).substring(38)} */}
                        </span>
                      </span>
                    }>
                    <NavDropdown.Item>
                    <h6 className="text-start m-0 bold">Wallet</h6>
                    <img src={metamask_icon} height="15px" className="me-1" />
                    <FormatAddress allowCopy={true} address={wallet.wallet.address} />
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                    <h6 className=" m-0 bold">Balance</h6>
                    <img src={token_icon} height="15px" className=" me-1" />
                    {wallet.wallet.balance} {wallet.wallet.symbol}
                    </NavDropdown.Item>
                    <NavDropdown.Item><h6 className=" m-0 bold">Network</h6>
                    {wallet.wallet.network}</NavDropdown.Item>
                  </NavDropdown>
                : <ConnectWallet locked={false} /> }                
              </Nav>
            </Navbar>
          </Col>
</Row></Container>
      </div>
{/* <pre>{JSON.stringify(wallet.wallet, null, 4)}</pre> */}

    </>
  )
}

export default Navigation;
