import React, { createContext, useReducer, useContext } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import ConnectWallet from "../ConnectWallet";
import metamask_icon from "../assets/metamask.webp";
import token_icon from "../assets/token.svg";
import { FormatAddress } from '../Utils';

const initialState = null;

export const WalletContext = createContext();

export const SET = 'SET';
export const ERROR = 'ERROR';

export const walletReducer = (state, action) => {
  switch (action.type) {
    case SET:
      return action.payload;
    case ERROR:
      return action.payload;
    default:
      return state;
  }
};


export const WalletContextProvider = props => {
  const [wallet, walletDispatch] = useReducer(walletReducer, initialState);
  const walletData = { wallet, walletDispatch };

  return (
    <WalletContext.Provider value={walletData}>
      {wallet != null ?
        props.children
         : <ConnectWallet locked={true} />} 
    </WalletContext.Provider>
  );
};


export const useWalletContext = () => {
  return useContext(WalletContext);
};
