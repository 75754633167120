import React, { createContext, useReducer, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useRef, useEffect, useState } from "react";

import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import * as Icon from 'react-bootstrap-icons';

import { sections } from '../Learn';
import jugaad from "../assets/logo_2.png";
import { Container, Row, Col, ListGroup, ListGroupItem, Card, Button } from 'react-bootstrap';



const initialState = null;

export const LearnContext = createContext();

export const SET = 'SET';
export const SHOW = 'SHOW';

export const learnReducer = (state, action) => {
  switch (action.type) {
    case SET:
      return action.payload;
    case SHOW:
      return action.payload;      
    default:
      return state;
  }
};


export const LearnContextProvider = props => {
  const [learn, learnDispatch] = useReducer(learnReducer, initialState);
  const learnData = { learn, learnDispatch };

  const wrapperRef = useRef(null);


  let [content, setContent] = useState("");
  let [section, setSection] = useState(null);
  let [page, setPage] = useState(null);
  // const [module, setModule] = useState(null);




  useEffect(() => {

    async function _getData() {
      if (learnData.learn === null) {
        // _module = "welcome"
        return
      }

      if (learnData.learn.module === null || learnData.learn.module === undefined) {
        return
      }
      let _module = learnData.learn.module

      for (let i = 0; i < sections.length; i++) {
        // let pages = sections[i].pages
        for (let j = 0; j < sections[i].pages.length; j++) {
          // console.log(sections[i].pages[j])
          if (sections[i].pages[j].page === learnData.learn.module) {
            setPage(sections[i].pages[j])
            setSection(sections[i])
            document.title = sections[i].title + " | " + sections[i].pages[j].title + " | NFT Jugaad";
            break
          }
        }
      }

      const readmePath = await import("../assets/docs/" + _module + ".md");
      fetch(readmePath.default)
        .then((res) => res.text())
        .then((md) => {
          setContent(md)
          setShow(true)
        })
    }

    _getData()

  }, [learnData])


  // useEffect(() => {

  //   if(learnData.learn !== null) {
  //     setModule(learnData.learn)
  //   }
  // }, [learnData])


  function Sections({ section }) {
    return (
      <ListGroup className="">
        {section.pages.map((page, idx1) => {
          return <ListGroupItem key={idx1} className={learnData.learn.module === page.page ? "active" : "pointer"} onClick={() => loadSection(page.page)}>
            <div className="row">
              <div className="col-2" style={{ width: '15px' }} href="#">
                <Icon.Files />
              </div>
              <div className="col-10">
                <b>{page.title}</b>
                {page.description ? <div>{page.description}</div> : ""}
              </div>
            </div>
          </ListGroupItem>
        })}
      </ListGroup>
    );
  }





  const loadSection = async (_module) => {
    if (_module === "") {
      learnDispatch({ type: SET, payload: { module: null, expand: expand } })
      return
    }
    learnDispatch({ type: SET, payload: { module: _module, expand: expand } })
  }

  const [show, setShow] = useState(false);

  const showLearn = async () => {
    let _show = false;
    if (show) {
      _show = false
      learnDispatch({ type: SET, payload: { module: null, expand: false } })
    }
    setShow(!show)
  }

  const back = () => {
    // setModule(null)
    setContent("")
    setSection(null)
    learnDispatch({ type: SET, payload: { module: null, expand: expand } })

  }

  const [expand, setExpand] = useState(false)

  const showExpand = () => {
    setExpand(!expand)
    
    // learnDispatch({ type: SET, payload: { module: learnData.learn.module, expand: expand } })

  }


  useEffect(() => {
    if (show) {
      document.body.classList.remove("help_close")
      document.body.className = document.body.className + " help_open";
    } else {
      document.body.classList.remove("help_open")
      document.body.className = document.body.className + " help_close";
    }
  }, [show])


  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       if (show) {
  //         learnDispatch({ type: SET, payload: { module: null, expand: false } })
  //         setShow(false)
  //       }
  //     }
  //   }
  //   if (show !== undefined && show === true) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }
  // }, [wrapperRef, show]);

  // useEffect(() => {
  //   if (learnData.learn !== null) {
  //     setExpand(learnData.learn.expand)
  //   }

  // }, [learnData])

  return (
    <LearnContext.Provider value={learnData}>
      <div ref={wrapperRef}>

        <Button className="learn_btn" onClick={showLearn}>
          {/* <img src={jugaad} /> */}
          <center>
            { show ? <Icon.X size="56" />: <Icon.Question size="56" /> }
          </center>
        </Button>

        <div className={show ? `learn show ${expand ? "expand" : ""}` : "learn"}>

          <div className=" bg-dark">

            <Container fluid>
              <Row>
                <Col>
                  {section !== null ?
                    <div className="mb-2 mt-3">
                      <span className="pointer btn btn btn-secondary" onClick={() => back()} >
                      <Icon.ChevronLeft className="pointer" size="18" />
                      </span>
                      </div>
                    : ""}
                </Col>
                <Col className="text-end">
                  <div className="mb-2 btn-group mt-3">

                    <span className="pointer btn btn btn-secondary" onClick={() => showExpand()} >
                    {expand ? <Icon.ArrowsAngleContract size="18" /> : <Icon.ArrowsAngleExpand className="pointer" onClick={() => showExpand()} size="18" />}
                    </span>
                    <span className=" pointer btn btn btn-secondary" onClick={showLearn}>
                    <Icon.XLg className="pointer "  size="18" />
                    </span>


                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {section !== null ?
          <>
            <Container fluid>
              <Row className="pri  mt-2">
                <Col >
                  <div className="mt-3 mb-4">
                    <b className="">{section.title} <Icon.ChevronRight /></b>
                    <h1 className="m-0 p-0">
                      {page.title}
                    </h1>
                    <p>{page.description}</p>

                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                {section !== null && section.pages.length > 1 && expand ?
                  <Col className=" mt-3" sm={12} md={3} xl={3}>
                    <div className=""><Sections section={section} /></div>
                  </Col>
                  : ""}

                <Col>
                  <div className="markdown bg-black p-3 mt-2 rounded h-100 ">
                    <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
                  </div>
                </Col>

                {section !== null && section.pages.length > 1 && !expand ?
                  <Col className=" mt-3" sm={12} md={12} xl={12}>
                    <h6>More Topics in this Section</h6>
                    <Sections section={section} />
                  </Col>
                  : ""}
              </Row>
            </Container>
            </>
            :
            <>
            <Container fluid>
              <Row className='pri  mt-2'>
                <Col>

                  <div className="m-3 mb-4">
                    <b className="">Welcome To  </b>
                    <h1 className="m-0 p-0">
                      NFT Jugaad!
                    </h1>
                    <p>Jugaad is an Indo-Aryan word and it means <b>a flexible approach to problem-solving that uses limited resources in an innovative way!</b>. </p>
                    <p>NFT Jugaad was build as a learning platform. It only works on Goerli testnet currently and so you don't have a worry about Gas fees. You can create a wallet and load it with money from Faucet to start playing with collections, nfts, ownership etc. All along the pages you will find helpful tips which will guide you on the world of crypto and NFTs.  </p>

                  </div>


                </Col></Row>
</Container>
            <Container>

             <Row>
                <Col>
                <p className="mt-2">What would you like to do today?</p>
                <ListGroup >
                <ListGroupItem><h6 className="m-0">Submit Question</h6>Get help, ask a question, or share your feedback!</ListGroupItem>
                <ListGroupItem>
                <h6 className="m-0">Self Learn Topics</h6>
                Learning is the best way to move forward and no better way than hands-on. In the learn section of this website you will see different articles on how this all comes together and different terminologies associated.
                <Row className="mt-2">
                {
                  sections.map((lsection, idx) => {
                    return (<Col className="mb-3" xl={6} lg={4} sm={6} key={idx}>
                      <Card bg="dark pointer" className="text-center" style={{ minHeight: "140px" }} onClick={() => loadSection(`${lsection.pages[0].page}`)} >
                        <Card.Body>
                          <Card.Title as="h6" className="" >
                            <div className="mb-2 mt-3"><Icon.Journal size="36" /></div>
                            {lsection.title}
                          </Card.Title>
                        </Card.Body>
                      </Card></Col>)
                  })
                }
                </Row>
                </ListGroupItem>
                <ListGroupItem>
                <h6 className="bold">Disclaimer</h6><p>Please note that we provide these marketplace and other resources to you only as a learning platform and convenience. Inclusion of any link does not imply endorsement, approval or recommendation. As always be careful and never share your Wallet private keys with anyone. Make sure you are connected to testnet before approving any transactions.</p>

                </ListGroupItem>
                </ListGroup>
                </Col>
              </Row>
            </Container>
            </>
          }

        </div>
      </div>

      {props.children}
    </LearnContext.Provider>
  );
};


export const useLearnContext = () => {
  return useContext(LearnContext);
};
