import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CloseButton } from 'react-bootstrap';

import { useEffect, useState } from "react";
import Upload from "./components/Upload";
// import background from "./assets/randomshape.png";
import etherscan from "./assets/etherscan.png";

import * as Icon from 'react-bootstrap-icons';

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { pinJSONToIPFS } from './utils/pinata.js'
import { createCollection } from "./utils/api"

import Button from 'react-bootstrap/Button';
import { createContract } from './eth/eth'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import { useWalletContext } from './contexts/WalletContext';
import { useToastContext, ADD } from './contexts/ToastContext';

import { LearnModeBtn } from './components/UI'

import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Text from './components/Text'
import Image from './components/Image'

import { postData } from './utils/api';

require('dotenv').config();



const CreateCollection = (props) => {
    let wallet = useWalletContext();
    const { toastDispatch } = useToastContext();

    // const [signature, setSignature] = useState(null);
    // const { wallet_address } = props;
    // const [balance, setBalance] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [symbol, setSymbol] = useState("");

    const [collectionImage, setCollectionImage] = useState(null);
    const [txnInProgress, setTxnInProgress] = useState(false);
    const [contractAddress, setContractAddress] = useState(null);

    const navigate = useNavigate();

    const [error, setError] = useState("");
    // const [log, setLog] = useState(null);
    // const [txnInProgress, setTxnInProgress] = useState(false);

    function uploadComplete(upload) {
        setCollectionImage(upload.hash);
        setActiveKey("2");
    }

    // function uploadComplete(upload) {
    //     console.log({
    //       name: upload.metadata.name,
    //       format: upload.metadata.type,
    //       size: upload.metadata.size,
    //       last_modified: upload.metadata.lastModifiedDate.toLocaleDateString(),
    //     })
    //     setURL(upload.hash);
    //     setUrlMetadata({
    //       name: upload.metadata.name,
    //       format: upload.metadata.type,
    //       size: upload.metadata.size,
    //       last_modified: upload.metadata.lastModifiedDate.toLocaleDateString(),
    //     })
    //     setActiveKey("2");
    //   }
    

    // async function fetchMonitorTxn(txnHash) {
    //     setTxnInProgress(true);

    //     const txnResponse = await new Promise(resolve => {
    //         const interval = setInterval(async () => {

    //             setLog(`<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner> Attempting to get transaction receipt. Please wait...`);
    //             const rec = await monitorTxnSync(txnHash)
    //             if (rec) {
    //                 console.log("in parent", rec);
    //                 resolve(rec);
    //                 fetchMe(props);
    //                 setTxnInProgress(false);
    //                 clearInterval(interval);
    //             }
    //         }, 1000);

    //     });

    //     setLog(`<pre>${JSON.stringify(txnResponse, null, 4)}</pre>`)
    // }



    // const fetchSignature = async () => {
    //     var request = {}
    //     request["wallet_address"] = wallet_address
    //     return axios.get("http://localhost:8000/api/wallet/" + wallet_address + "/mint", { headers: {} })
    //         .then(function (response) {
    //             console.log(response);
    //             // setSignature(response.data);
    //             return response.data
    //             // createSignature(address, response.data.nonce)
    //         })
    //         .catch(function (error) {
    //             console.log(error)
    //             setError(error.message);
    //             return {}
    //         });
    // }


    const onContractCreatePressed = async (event) => {

        event.preventDefault();


        if (collectionImage === null) {
            toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: `Please upload a metadata image` } } })
            return
        }


        if (event.target.name.value === "" || event.target.name.symbol === "" || event.target.description.value === "") {
            toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: `Invalid or Missing Values` } } })
            return
        }

        setActiveKey("4");
        setTxnInProgress(true);
        try {
            var obj = {}
            obj.name = event.target.name.value;
            obj.symbol = event.target.symbol.value;
            obj.image = collectionImage
            obj.description = event.target.description.value;
            obj.external_link = event.target.external_link.value;
            obj.seller_fee_basis_points = event.target.seller_fee_basis_points.value;
            obj.fee_recipient = event.target.fee_recipient.value;

            console.log(obj)

            const pinataResponse = await pinJSONToIPFS(obj);
            if (!pinataResponse.success) {
                setError("Something went wrong while uploading your tokenURI");
                return
            }

            const contractURI = pinataResponse.pinataUrl;

            const tmpContractResponse = await createContract(obj.name, obj.symbol, contractURI, wallet.wallet.address)
            console.log(tmpContractResponse)
            obj.contract_address = tmpContractResponse._address;
            obj.wallet_address = wallet.wallet.address;
            obj.chain_id = wallet.wallet.chain;

            // createCollection(obj)
            postData(`wallet/${wallet.wallet.address}/collections`, obj)

            setTxnInProgress(false);
            setContractAddress(tmpContractResponse._address)
            // navigate("/contract/" + contractResponse._address)
        } catch (err) {
            toastDispatch({ type: ADD, payload: { content: { variant: 'danger', message: err.message } } })
            setTxnInProgress(false);
        }
    }

   

  // Get Contract Metadata
  useEffect(() => {
    console.log(wallet.wallet)
  }, []);

    const [activeKey, setActiveKey] = useState("1");

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log(eventKey),
        );

        return (
            <span disabled={activeKey !== eventKey ? true : false}
                onClick={decoratedOnClick}
            >
                {children}
            </span>
        );
    }



    return (
        <div>
            {/* <div className="bg-image" style={{ backgroundImage: `url(${background})` }}></div> */}

            <>
                <>
                    <Container className="main">
                        <Row>


                            <div className="row"><div className="col">
                                <div className="m-3 mb-4 position-relative">
                                    <LearnModeBtn module="nft_marketplace_collection" />
                                    <h1 className="mt-3">Create Collection</h1>
                                    <p>There are 3 parts to creating a collection - Image, Metadata and Payment.</p>
                                </div></div></div>
                            <Col xl={4} lg={4} md={12} sm={12} >

                                {collectionImage !== null ?
                                    <div className="">
                                        <div className="mb-3 bg-black rounded-2 p-4" >
                                            {/* <CloseButton className="shadow-0 rounded-0 border-0 float-end" onClick={() => setSelectedContract(null)} ></CloseButton> */}
                                            <b>Collection Preview</b>
                                            <Icon.Pencil className="shadow-0 pointer rounded-0 border-0 float-end" onClick={() => setActiveKey("1")} />
                                            <h3 className="m-0 mt-1 mb-2">{name}</h3>
                                            {/* <div>{symbol ? <b className="m-0 mt-1 mb-2">{symbol}</b> : "" }</div> */}
                                            {/* <span className="shadow-0 bg-black text-dark p-3 float-end m-2 rounded-pill" onClick={() => { setActiveKey("1"); }}><Icon.XLg /></span> */}
                                            <Image url={`https://gateway.pinata.cloud/ipfs/${collectionImage}`} background="banner" title="featured" />
                                            <div className="mt-2">
                                                <Text text={description} />
                                            </div>
                                        </div>
                                    </div>
                                    : ""}


                                <div className="p-3 rounded-3  d-none d-md-block">
                                    {/* <h5 className="mb-3">What is a Collection?</h5> */}
                                    {/* <div className="  m-1 mt-2 mb-3"><a className="bold text-white" href="#/learn" >Learn More about Collections in the learning section</a></div> */}

                                    <div className="mt-2 ">

                                        <p>An NFT collection is an assortment of digital assets released by you and contains a limited number of individual NFTs.</p>
                                        <p>A good example of this would be the BoredApeYachtClub, which is one of the most popular and valuable NFT collections in the world. This specific collection is made up of 10,000 unique non-fungible tokens on the Ethereum blockchain depicting simian avatars with various characteristics.</p>
                                        <p>Creating collection on-chain! This will deploy a new contract with you as the owner. It allows you to retain true ownership and maintain the highest level of provenance of your work. The power of owning your own contract allows you to launch customized web3 campaigns and build meaningful relationships with audiences.</p>
                                        <p>NFT Collection contract does not lazy mint your NFTs. Instead, NFTs are minted immediately when they are added to the collection.</p>

                                    </div>
                                </div>
                            </Col>

                            <Col xl={8} lg={8} md={12} sm={12} className="">
                                <div className="bg-black rounded-3 p-5">
                                    <div className="process ">
                                        <Form onSubmit={onContractCreatePressed}>
                                            <Accordion flush activeKey={activeKey}>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header >
                                                        <CustomToggle eventKey="1">
                                                            <h6>
                                                                Select Media
                                                                <div className="display-block text-small">Upload a unique image which reflects your collection</div>
                                                            </h6>
                                                        </CustomToggle>
                                                        <span className="aicon"><Icon.Images /></span>

                                                    </Accordion.Header>
                                                    <Accordion.Body>

                                                        {/* <h6 className="mt-3 mb-2">Image</h6>
                                                        <p>Look - Create a unique image which reflects your collection. Take a look at a collage of bored ape</p> */}
                                                        <div >
                                                            <Upload text="Click" setUploadURL={uploadComplete} />
                                                            <Form.Group className="mt-3" controlId="formBasicEmail">
                                                                <Form.Label>Image URL</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={collectionImage ? collectionImage : ""}
                                                                    disabled
                                                                    placeholder="Auto populated by upload step"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        {/* <a onClick={() => { setActiveKey("4"); }}>Test</a> */}
                                                        {/* <br/> */}
                                                        <a onClick={() => { uploadComplete("bafybeiapyufq5wbgxwtrjz7h2hcqv76nbiv3hxb7rtsq35kz674baj3oii/saints.png"); }}>Test</a>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header >
                                                        <CustomToggle eventKey="2">
                                                            <h6>
                                                                Metadata
                                                                <div className="display-block text-small">Description what your collection representes and how it is unique</div>
                                                            </h6>
                                                        </CustomToggle>
                                                        <span className="aicon"><Icon.CardHeading /></span>

                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div >
                                                            <div>

                                                            </div>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Name</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Mask Collection"
                                                                    onChange={(event) => setName(event.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Symbol</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="symbol"
                                                                    placeholder="N95"
                                                                    onChange={(event) => setSymbol(event.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Description</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    as="textarea"
                                                                    style={{ height: "150px" }}
                                                                    name="description"
                                                                    placeholder="Something about collection ...."
                                                                    onChange={(event) => setDescription(event.target.value)}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>External Link</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="https://"
                                                                    name="external_link"
                                                                // onChange={(event) => setName(event.target.value)} 
                                                                />
                                                                <Form.Text className="text-muted">Crypto Kittens?</Form.Text>
                                                            </Form.Group>
                                                        </div>
                                                        <br /><br />
                                                        <a className="btn btn-outline-primary" onClick={() => { setActiveKey("3"); }}>Proceed to Payment Details</a>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header >
                                                        <CustomToggle eventKey="3">
                                                            <h6>
                                                                Payment Details
                                                                <div className="display-block text-small">How much royalty when your NFT you mint sells over time and where would like your crypto to go</div>
                                                            </h6>
                                                        </CustomToggle>
                                                        <span className="aicon"><Icon.Wallet /></span>

                                                    </Accordion.Header>
                                                    <Accordion.Body>

                                                        <div >
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Seller Fee Basis Point</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="100"
                                                                    name="seller_fee_basis_points"
                                                                // onChange={(event) => setDescription(event.target.value)} 
                                                                />
                                                                <Form.Text className="text-muted">A descrptive metadata for your NFT</Form.Text>
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                <Form.Label>Address where Fees needs to be send?</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="0x00000"
                                                                    name="fee_recipient"
                                                                    defaultValue={wallet.wallet.address}
                                                                // onChange={(event) => setDescription(event.target.value)} 
                                                                />
                                                                <Form.Text className="text-muted">Provide address where you would like the fees to be transferred when your precious NFT is purchased</Form.Text>
                                                            </Form.Group>
                                                            {/* onClick={onContractCreatePressed} */}
                                                            {/* <Button type="submit" variant="dark" className="mt-3 w-100" >Create Collection</Button>
                                                                <p className="text-muted mt-3">Creating a collection will create a new contract with basic ERC 721 mint functionality</p> */}
                                                        </div>

                                                        <Button type="submit" variant="primary" className="mt-3 large w-100" >Create Collection</Button>
                                                        {/* <a onClick={() => { setActiveKey("4"); }}>Provide Metadata</a> */}
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header >
                                                        <CustomToggle eventKey="4">
                                                            <h6>
                                                                Transaction
                                                                <div className="display-block text-small">Processing on chain</div>
                                                            </h6>
                                                        </CustomToggle>
                                                        <span className="aicon"><Icon.Check2All /></span>

                                                    </Accordion.Header>
                                                    <Accordion.Body>

                                                        {txnInProgress ?
                                                            <Spinner animation="border" className="" style={{ width: "24px", height: "24px" }} role="status"><span className="visually-hidden">Loading...</span></Spinner> : ""}

                                                        {contractAddress !== null ?
                                                            <div>
                                                                <b>Your collection is successfully created. Head on to <a onClick={() => navigate("/contract/" + contractAddress)} >Collection details page</a> or  <a onClick={() => navigate("/mint")} >mint</a> an NFT now on the collection</b>
                                                                <pre>{contractAddress}</pre>

                                                                <a href={`${wallet.wallet.explorer}/address/${contractAddress}`} rel="noreferrer" target="_blank"><img height="18px" className="invert justify-content-end me-2" src={etherscan} /> View on Explorer</a>


                                                            </div>
                                                            : ""
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Form>
                                    </div>
                                </div>
                            </Col>


                            {/* <Col xl={12} lg={12} md={12} sm={12} className="p-4">
                                <h5 className="mb-3">What is a Collection?</h5>
                                <div className="  m-1 mt-2 mb-3"><a className="bold text-white" href="#/learn" >Learn More about Collections in the learning section</a></div>

                                <div className=" p-1 m-1 mt-2 ">
                                    <ul>
                                        <li>An NFT collection is an assortment of digital assets released by you and contains a limited number of individual NFTs.</li>
                                        <li>A good example of this would be the BoredApeYachtClub, which is one of the most popular and valuable NFT collections in the world. This specific collection is made up of 10,000 unique non-fungible tokens on the Ethereum blockchain depicting simian avatars with various characteristics.</li>
                                        <li>Creating collection on-chain! This will deploy a new contract with you as the owner. It allows you to retain true ownership and maintain the highest level of provenance of your work. The power of owning your own contract allows you to launch customized web3 campaigns and build meaningful relationships with audiences.</li>
                                        <li>NFT Collection contract does not lazy mint your NFTs. Instead, NFTs are minted immediately when they are added to the collection.</li>
                                    </ul>
                                </div>


                            </Col> */}
                        </Row>
                    </Container>


                </>
                {/* ) : ""} */}
            </>
        </div >
    );
};


export default CreateCollection;
