import { Container, Row, Col, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getData } from "../utils/api"
import { web3 } from '../eth/eth';
import Moment from 'react-moment';
import { FormatAddress } from '../Utils';
import * as Icon from 'react-bootstrap-icons';
import Chart from './Chart';

const History = (props) => {
  const { listing_id, contract, token } = props;
  const [loading, setLoading] = useState(true)
  const [history, setHistory] = useState([])

  useEffect(() => {

    async function getHistory() {
      let response = await getData(`listing/${listing_id}`)
      setHistory(response.data.data)
      setLoading(false);
    }

    if(listing_id !== undefined) {
      getHistory()
    }

  }, [listing_id]);

  useEffect(() => {

    async function getHistory() {
      // http://localhost:8000/api/collections/0x8b37429F7A2d9722825f66519C7490BE2Ca7a5Ff/7/history
      let response = await getData(`collections/${contract}/${token}/history`)
      setHistory( [...response.data.data].reverse())
      setLoading(false);
    }

    if(contract !== undefined && token !== undefined) {
      getHistory()
    }

  }, [contract, token]);

  


  function getListingType(id) {
    if(id == "0") {
      return "FIXED"
    } 
    if(id == "1") {
      return "AUCTION"
    } 
    if(id == "2") {
      return "RESERVED"
    } 

    return "UNKONWN"
  }

  function getListingStatus(id) {
    if(id == "0") {
      return "NONE"
    } 
    if(id == "1") {
      return "IN_PROGRESS"
    } 
    if(id == "2") {
      return "FINISHED"
    } 
    if(id == "3") {
      return "CANCELED"
    }     

    return "UNKONWN"
  }

  return (
    <>
    <h4>Marketplace History</h4>
    { loading ? "Loading..." : "" }
    { !loading && history.length == 0 ? "No history to show" : "" }


    {/* <Chart />
    <div className="timeline-container">
    {history.map((event, idx) => (
          <div key={idx} className="timeline-item">
          <div className="timeline-item-content">
              <span className="tag" style={{ background: "#dddddd" }}>
                <Badge bg={ event.event == "SALE" ? "success": "primary" }>{ event.event }</Badge>
              </span>
              <time><Moment fromNow>{event.blockTimestamp * 1000}</Moment></time>
              <p>
              { getListingType(event.listingType) } ({getListingStatus(event.auctionStatus)}) <br/>
              <FormatAddress address={ event.owner } /></p>
              
              <span className="circle" />
          </div>
      </div>
      ))}
      </div> */}


    <Container>
      {history.map((event, idx) => (
          <Row key={idx} className="border-top pt-2">
            <Col xl={1} style={{ maxWidth: "30px" }}>
            {event.auctionStatus == "0" ? <span className=""></span> : ""}
            {event.auctionStatus == "1" ? <span className=""><Icon.Hourglass /></span> : ""}
            {event.auctionStatus == "2" ? <span className=""><Icon.CheckAll /></span> : ""}                            
            {event.auctionStatus == "3" ? <span className=""><Icon.XCircle /></span> : ""}                            
              {/* { getListingStatus(event.auctionStatus) } */}
            </Col>
            <Col xl={1} style={{ maxWidth: "100px" }}>
              <Badge bg={ event.event == "SALE" || event.event == "CLAIM" ? "success": "primary" }>{ event.event }</Badge>
            </Col>
            <Col xl={2}><Icon.Wallet /> <FormatAddress address={ event.owner } /></Col>
            <Col xl={3}>
            {event.listingType == "0" ? <span className=""><Icon.Cart /> Fixed Price Listing</span> : ""}
                          {event.listingType == "1" ? <span className=""><Icon.Clock /> Timed Auction (Expires: 
                            <Moment fromNow>{(event.blockTimestamp + event.expiryDays) * 1000}</Moment>)</span> : ""}
                          {event.listingType == "2" ? <span className=""><Icon.Lock /> Reserved Listing</span> : ""}              
              {/* { getListingType(event.listingType) } */}
            </Col>
            
            {/* <Col><Moment fromNow>{(event.blockTimestamp + event.expiryDays) * 1000}</Moment></Col> */}
            <Col xl={2}> {event.listingType == "1" ? <span><Icon.Wallet /> <FormatAddress address={ event.lastOfferFrom }  /></span> : "" }</Col>
            <Col xl={1}>{event.listingType == "1" ? <span>{ web3.utils.fromWei(String(event.lastOfferPrice), "ether") } ETH</span>: "" }</Col>
            <Col xl={2}><Moment fromNow>{event.blockTimestamp * 1000}</Moment></Col>

          </Row>
      ))}
      </Container>
        {/* <pre>{JSON.stringify(event, null, 2)}</pre> */}

    </>
  )
}

export default History;
