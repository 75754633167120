import React from 'react';
import { Toast } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useToastContext, REMOVE } from '../contexts/ToastContext';
import Moment from 'react-moment';

export default function RenderToast({ msg }) {
  const { toastDispatch } = useToastContext();
  
  function markRead(msg) {
    toastDispatch({
      type: REMOVE,
      payload: msg
    })
}

  return (
    <Toast bg={msg.content.variant.toLowerCase()} onClose={() => markRead(msg)} >
        <Toast.Header >
            <small className="me-auto text-white" >
            <strong className="me-auto inline-block pe-2">
              { msg.content.variant.toLowerCase() == "danger" ?  <Icon.ExclamationCircle /> : "" }
              { msg.content.variant.toLowerCase() == "warning" ?  <Icon.ExclamationTriangle /> : "" }
              { msg.content.variant.toLowerCase() == "info" ?  <Icon.InfoCircle /> : "" }
              { msg.content.variant.toLowerCase() == "success" ?  <Icon.Check2Circle /> : "" }
            </strong>
              <Moment fromNow>{msg.content.id}</Moment>
            </small>
        </Toast.Header>
        <Toast.Body >
          {/* <small className="text-white"  ><Moment fromNow>{msg.content.id}</Moment></small> */}
            <div className="text-start" dangerouslySetInnerHTML={{ __html: msg.content.message }}></div>
                {/* {msg.content.message} */}
                {/* <br /> */}
                {/* {msg.content.link ? <a href={msg.content.link} target="blank">{msg.content.link}</a> : ""} */}
            {/* </div> */}
        </Toast.Body>
    </Toast>
  );
}
