import React, { createContext, useReducer, useContext } from 'react';
import RenderToast from '../components/Toast';
import { ToastContainer } from 'react-bootstrap';


export const ToastContext = createContext();

const initialState = [];

export const GET = 'GET';
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';
export const REMOVE_ALL = 'REMOVE_ALL';

export const toastReducer = (state, action) => {
  switch (action.type) {
    case GET:
      console.log("GET", state)
      return state;
    case ADD:
      return [
        ...state,
        {
          id: +new Date(),
          content: action.payload.content,
          type: action.payload.type
        }
      ];
    case REMOVE:
      return state.filter(t => t.id !== action.payload.id);
    case REMOVE_ALL:
      return initialState;
    default:
      return state;
  }
};


export const ToastProvider = props => {
  const [toast, toastDispatch] = useReducer(toastReducer, initialState);
  const toastData = { toast, toastDispatch };

  return (
    <ToastContext.Provider value={toastData}>
      {props.children}
      {/* <ToastContainer position="top-end" containerPosition="fixed" className="p-3" >
        {
          toastData ?
            toastData.toast.map((msg, idx) => {
              return (
                <RenderToast key={idx} msg={msg} />
              )
            }) : ""
        }
      </ToastContainer> */}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
